import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Item,
  Segment,
  Statistic,
  Image,
} from "semantic-ui-react";

import customerService from "../../services/customer";
import homeService from "../../services/home";
import URLS, { URL_PREFIX } from "../../services/URLS";

import {
  convertBinaryToBase64,
  convertResponseError,
} from "../../helpers/util";
import Messages from "../../config/messages";

import ResendInvitationEmail from "./ResendInvitationEmailModal";

class CustomerInfo extends Component {
  state = {
    statistics: {},
    remoteError: {},
    customer: {},
    locations: [],

    customerLogo: "",

    isFetchingStats: false,
    isFetchingCustomer: false,

    isOpenInvaitationModal: false,
    selectedCustomer: {},
  };

  componentDidMount() {
    this.getStatistics();
    this.getCustomer();
    this.getLocation();
  }

  getLocation = () => {
    const {
      match: {
        params: { customerId },
      },
    } = this.props;

    this.setState({ isFetchingStats: true });

    customerService.getBusinessLocationById(customerId)
      .then(response => {
        this.setState(state => ({
          statistics: {
            ...state.statistics,
            locations: response.data
          },
          isFetchingStats: false,
        }))
      })
      .catch((error) => {
        this.setState({
          isFetchingStats: false,
          remoteError: convertResponseError(error),
        });
      });
  }

  /**
   * Get Statistics
   */
  getStatistics = () => {
    const {
      match: {
        params: { customerId },
      },
    } = this.props;

    this.setState({ isFetchingStats: true });

    homeService
      .getStatistics(customerId)
      .then((response) => {
        if (!response) {
          this.setState({
            isFetchingStats: false,
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
        this.setState(state => ({
          statistics: {
            ...state.statistics,
            ...response.data
          },
          isFetchingStats: false,
        }))

      })
      .catch((error) => {
        this.setState({
          isFetchingStats: false,
          remoteError: convertResponseError(error),
        });
      });
  };

  /**
   * Get Customer By Id
   */
  getCustomer = () => {
    const {
      match: {
        params: { customerId },
      },
    } = this.props;

    this.setState({ isFetchingCustomer: true });

    customerService
      .getCustomerById(customerId)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
            isFetchingCustomer: false,
          });
          return;
        }

        this.setState(
          { customer: response.data, isFetchingCustomer: false },
          this.getCustomerLogo(response.data)
        );
      })
      .catch((error) => {
        this.setState({
          isFetchingCustomer: false,
          remoteError: convertResponseError(error),
        });
      });
  };

  /**
   * Get Customer Logo
   *
   * @param {Object} customer
   */
  getCustomerLogo = (customer) => () => {
    customerService
      .getCustomerLogo(customer.id)
      .then((response) => {
        const customerLogo = convertBinaryToBase64(response.data);

        this.setState({ customerLogo });
      })
      .catch((error) => { });
  };

  /**
   * Update customer
   *
   * @param {Object} customer 
   */
  updateCustomer = (customer) => {
    this.setState({ customer });
  }

  /**
   * Handle Status Content
   *
   * @param {Object} customer
   * @return {String || HTML}
   */
  handleStatusContent = (customer) => {
    const { initStatus = "" } = customer;

    const {
      INIT_STATUS: {
        resend,
        complete,
        customer_added,
        user_added,
        customer_set,
        business_profile_set,
        user_set,
        contact_detail_set,
      },
    } = customerService;

    // Handle Link Status
    const handleButtonStatus = (status) => {
      if (!status || status === resend.key) {
        return (
          <Button className="resend-btn" size='mini' onClick={this.openInviationModal(customer)} >
            {customer.sendingText || resend.key}
          </Button>
        );
      }

      return <Button className="resend-btn" size='mini' disabled >
        {status}
      </Button>
    };

    const getLinkTag = () => (
      <Icon name="check circle" color="teal" size="large" />
    );

    switch (initStatus) {
      case complete.key:
        return complete.value;

      case customer_added.key:
      case user_added.key:
        return (
          <>Invitation Email Sent info {handleButtonStatus(customer.sendingText)}</>
        );

      case customer_set.key:
        return <>Customer {getLinkTag()}</>;

      case contact_detail_set.key:
        return <>Contact Details {getLinkTag()}</>;

      case user_set.key:
        return <>User {getLinkTag()}</>;

      case business_profile_set.key:
        return <>Business Profile {getLinkTag()}</>;

      default:
        return "-";
    }
  };

  /**
   * Open Invitation Modal
   *
   * @param {Object} selectedCustomer
   * @returns
   */
  openInviationModal = (selectedCustomer) => () => {
    this.setState({
      isOpenInvaitationModal: true,
      selectedCustomer,
    });
  };

  /**
   * Close Invitation Modal
   */
  closeInvitationModal = () => {
    this.setState({
      isOpenInvaitationModal: false,
      selectedCustomer: {},
    });
  };

  /**
   * Render View
   */
  render() {
    const {
      user: userData,
      match: {
        params: { customerId },
      },
    } = this.props;

    const {
      isFetchingCustomer,
      isFetchingStats,
      isOpenInvaitationModal,
      selectedCustomer,

      statistics: {
        clients,
        practitioners,
        sessions,
        tests,
        devices,
        installations,
        locations = [],
      },

      customer,

      customerLogo = "",
    } = this.state;

    const { id, user, licenceType, licences, businessProfile } = customer;

    const { email, contactDetail } = user || {};
    const {
      name: businessName = "",
      phone = "",
      address = "",
      email: businessEmail = "",
      websiteUrl = "",
    } = businessProfile || {};

    const { email: contactEmail, firstName, lastName, phone: contactPhone } =
      contactDetail || {};

    return (
      <>
        <Segment
          loading={isFetchingCustomer || isFetchingStats}
          className="fake-segment"
        >
          {id && businessName && (
            <Breadcrumb
              icon="right angle"
              sections={[
                {
                  key: "bread-customers",
                  content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}`}>Customers</Link>,
                },
                {
                  key: `bread-${id}`,
                  content: businessName,
                  active: true,
                },
              ]}
            />
          )}

          {/* Statistics */}

          <Grid columns={4} doubling stackable className="alignItemCenter">
              <Grid.Column textAlign="center" >
              <Statistic>
                  <Statistic.Value>
                  <Icon name="user md" /> {practitioners}
                  </Statistic.Value>
                  <Statistic.Label>Practitioners</Statistic.Label>
              </Statistic>
              </Grid.Column>
              <Grid.Column textAlign="center" >
              <Statistic>
                  <Statistic.Value>
                  <Icon name="user" /> {clients}
                  </Statistic.Value>
                  <Statistic.Label>Clients</Statistic.Label>
              </Statistic>
              </Grid.Column>
              <Grid.Column textAlign="center" >
              <Statistic>
                  <Statistic.Value>{sessions}</Statistic.Value>
                  <Statistic.Label>Sessions</Statistic.Label>
              </Statistic>
              </Grid.Column>
              <Grid.Column textAlign="center" >
              <Statistic>
                  <Statistic.Value>{tests}</Statistic.Value>
                  <Statistic.Label>Tests</Statistic.Label>
              </Statistic>
              </Grid.Column>
          </Grid>
 
          {/* Customer Information */}
          <Segment>
            <Grid>
              <Grid.Row columns={2} className="alignItemCenter">
                <Grid.Column>
                  <Header as="h3">Customer Info</Header>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button primary as={Link} to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${id}${URL_PREFIX}edit`}>
                    {" "}
                    Edit
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column width={2}>
                  <Image
                    src={customerLogo || "images/defaultLogo.png"}
                    size="small"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Item.Description>Bussiness Name:</Item.Description>
                      </Grid.Column>
                      <Grid.Column>
                        <Item.Description>
                          {businessName || "-"}
                        </Item.Description>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Item.Description>Email:</Item.Description>
                      </Grid.Column>
                      <Grid.Column>
                        <Item.Description>
                          {businessEmail || "-"}
                        </Item.Description>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Item.Description>Address:</Item.Description>
                      </Grid.Column>
                      <Grid.Column>
                        <Item.Description>{address || "-"}</Item.Description>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Item.Description>Website:</Item.Description>
                      </Grid.Column>
                      <Grid.Column>
                        <Item.Description>{websiteUrl || "-"}</Item.Description>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Item.Description>Phone:</Item.Description>
                      </Grid.Column>
                      <Grid.Column>
                        <Item.Description>{phone || "-"}</Item.Description>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <Grid columns={4}>
              <Grid.Row>
                <Grid.Column>
                  <Item.Description>Login Email:</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>{email || ""}</Item.Description>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <Grid columns={4}>
              <Grid.Row>
                <Grid.Column>
                  <Item.Description>Contact Person:</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>
                    {firstName || "-"} {lastName || ""}
                  </Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>Email:</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>{contactEmail || "-"}</Item.Description>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Item.Description>Phone:</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>{contactPhone || "-"}</Item.Description>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <Grid columns={4}>
              <Grid.Row>
                <Grid.Column>
                  <Item.Description>Licence Type:</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>{licenceType || "-"}</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>Number Of Licences</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>{licences || "-"}</Item.Description>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Item.Description>Status:</Item.Description>
                </Grid.Column>
                <Grid.Column>
                  <Item.Description>
                    {" "}
                    {this.handleStatusContent(customer)}
                  </Item.Description>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Grid columns={4} doubling stackable className="alignItemCenter"> 
              <Grid.Column>
                <Segment>
                  <Grid textAlign="center" >
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column>
                        <Statistic>
                          <Statistic.Value>
                            <Icon name="location arrow" /> {locations.length}
                          </Statistic.Value>
                          <Statistic.Label>Locations</Statistic.Label>
                          <Statistic.Label className="mt-10">
                            <Button
                              as={Link}
                              to={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}?customerId=${customerId}`}
                            >
                              Manage
                            </Button>
                          </Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Grid textAlign="center">
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column>
                        <Statistic>
                          <Statistic.Value>
                            <Icon name="user" /> {practitioners}
                          </Statistic.Value>
                          <Statistic.Label>Practitioners</Statistic.Label>
                          <Statistic.Label className="mt-10">
                            <Button
                              as={Link}
                              to={`/customers/${id}/practitioners`}
                            >
                              Manage
                            </Button>
                          </Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Grid textAlign="center">
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column>
                        <Statistic>
                          <Statistic.Value>
                            <Icon name="tablet" /> {installations}
                          </Statistic.Value>
                          <Statistic.Label>Tablets</Statistic.Label>
                          <Statistic.Label className="mt-10">
                            <Button as={Link} to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${id}${URL_PREFIX}${URLS.TABLETS}`}>
                              Manage
                            </Button>
                          </Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Grid textAlign="center">
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column>
                        <Statistic>
                          <Statistic.Value>
                            <Icon name="hdd" /> {devices}
                          </Statistic.Value>
                          <Statistic.Label>Devices</Statistic.Label>
                          <Statistic.Label className="mt-10">
                            <Button as={Link} to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${id}${URL_PREFIX}${URLS.DEVICES}`}>
                              Manage
                            </Button>
                          </Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid>
        </Segment>

        {isOpenInvaitationModal && (
          <ResendInvitationEmail
            isOpen={isOpenInvaitationModal}
            onCancel={this.closeInvitationModal}
            onInvitationSend={this.onInvitationSend}
            customer={selectedCustomer}
            user={userData}
            updateCustomer={this.updateCustomer}
          />
        )}
      </>
    );
  }
}

export default CustomerInfo;
