export const URL_PREFIX = '/';

/**
 * URLS
 */
const URLS = {
  LOGIN: 'login',
  UPGRADE_TO_CLOUD: 'upgrade-to-cloud',
  DEVICES: 'devices',
  PLAYLISTS: 'playlists',
  TESTS: 'tests',
  TABLETS: 'tablets',
  BUSINESS: 'business',
  CUSTOMERS: 'customers',
  CUSTOMER: 'customers/:customerId',
  CUSTOMER_SETUP: 'customers/:customerId/setup',
  CUSTOMER_MIGRATION: 'customers/:customerId/migrate',
  USERS: 'users',
  USER: 'users/:userId',
  PRACTITIONERS: 'practitioners',
  PRACTITIONERER: 'practitioners/:practitionerId',
  LOCATIONS: 'locations',
}

export default URLS;