import React, { useCallback, useEffect, useState } from "react";
import { shape, bool } from "prop-types";
import { useDropzone } from "react-dropzone";
import { Icon, Message, Segment } from "semantic-ui-react";

import customerService from "../../services/customer";
import Messages from "../../config/messages";
import {
  convertBinaryToBase64,
  convertResponseError,
  getFileFromBase64,
} from "../../helpers/util";

/**
 * Image preview
 * @param {Object} customer
 */
const Preview = props => {
  const [isLoading, setLoaded] = useState(false);
  const [files, setFiles] = useState([]);
  const [imgUrl, setImage] = useState();
  const [isValidImage, validateImage] = useState(true);
  const [remoteError, setRemoteError] = useState(null);

  /**
   * Upload Image
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",

    onDropRejected: () => validateImage(false),

    onDrop: (acceptedFiles = []) => {
      if (acceptedFiles.length === 0) return;

      if (imgUrl) return [imgUrl];

      var reader = new FileReader();

      let fileToSend = null;

      reader.onload = function () {
        fileToSend = getFileFromBase64(reader.result);
      };

      reader.readAsDataURL(acceptedFiles[0]);

      if (acceptedFiles[0].name) {
        setTimeout(() => {
          const formData = new FormData();

          formData.append("logo", fileToSend);

          setLoaded(true);

          customerService
            .uploadLogo(props.id, formData)
            .then((response) => {
              setLoaded(false);
              if (!response) {
                setFiles([]);
                setImage("");
                setRemoteError({
                  message: Messages["no-responce-from-server"],
                });
                return;
              }

              setFiles(
                acceptedFiles.map((file) => {
                  return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  });
                })
              );
            })
            .catch((error) => {
              setRemoteError(convertResponseError(error));
              setLoaded(false);
              setFiles([]);
              setImage("");
            });

          validateImage(true);
        }, 500);

        return;
      }

      validateImage(false);

      return [];
    },
  });

  /**
   * Get Customer Logo
   */
  const getCustomerLogo = () => {
    if (imgUrl || files[0]) return;

    setLoaded(true);

    customerService
      .getCustomerLogo(props.id)
      .then((response) => {
        const imgUrl = convertBinaryToBase64(response.data);
        setImage(imgUrl);
        setLoaded(false);
      })
      .catch(() => setLoaded(false));
  };

  /**
   * get Image Preview
   */
  const getImagePreview = () => {
    const { preview = null } = files[0] || {};

    if (!imgUrl && !preview) return;

    return (
      <div className="dropzone-Image">
        <div className="thumb-inner">
          <img src={imgUrl || preview} className="img-preview" alt="" />
        </div>
        <Icon name="close" disabled={isLoading} onClick={deleteLogo} />
      </div>
    );
  };

  /**
   * Delete Logo
   */
  const deleteLogo = () => {
    setLoaded(true);

    customerService
      .deleteLogo(props.id)
      .then((response) => {
        setLoaded(false);

        if (!response) {
          setRemoteError({
            message: Messages["no-responce-from-server"],
          });
          return;
        }

        setFiles([]);
        setImage("");
      })
      .catch((error) => {
        setRemoteError(convertResponseError(error));
        setLoaded(false);
      });
  };

  const instanceRender = useCallback(getCustomerLogo, []);

  useEffect(() => {
    instanceRender();
  }, [instanceRender]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  /**
   * Render View
   */
  return (
    <Segment loading={isLoading} className="fake-segment">
      <section className="container mt-10">
        {!imgUrl && (!files || files.length === 0) && (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {!props.isDefaultLogo && <p>+</p>}
            {props.isDefaultLogo && <img src="images/defaultLogo.png" className="default-img-preview" alt="" />}

          </div>
        )}

        <aside className="thumbs-container">{getImagePreview()}</aside>

        {remoteError && (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            <p>{remoteError.message}</p>
          </Message>
        )}

        {!isValidImage && (
          <Message error>
            <p>Please upload a valid image</p>
          </Message>
        )}
      </section>
    </Segment>
  );
}

Preview.propTypes = {
  customer: shape({}),
  isDefaultLogo: bool,
};

export default Preview;
