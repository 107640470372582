import React, { Component } from 'react';
import { Message, Modal, Button } from 'semantic-ui-react';
import {
  func,
  bool,
  string,
  shape
} from 'prop-types';

class ConfirmationDialogComponent extends Component {

  render() {
    const {
      isOpen,
      yesText,
      noText,
      msg,
      heading,
      remoteError,
      isLoading,
      onCancel,
      onConfirm,
      restore
    } = this.props;

    return (
      <Modal
        onClose={onCancel}
        open={isOpen}
        size="small"
      >
        <Modal.Header>{heading}</Modal.Header>

        <Modal.Content>
          {msg}
          {remoteError &&
            <Message error className="mt-10">
              <Message.Header>Error {remoteError.status}</Message.Header>
              <p>{remoteError.message}</p>
            </Message>
          }
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={onCancel}>{noText}</Button>
          <Button primary loading={isLoading} disabled={isLoading} negative={!restore} onClick={onConfirm}>{yesText}</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

ConfirmationDialogComponent.propTypes = {
  onCancel: func,
  onConfirm: func,
  isOpen: bool,
  isLoading: bool,
  msg: string,
  heading: string,
  remoteError: shape({}),
  restore: bool,
  yesText: string,
  noText: string,
}

ConfirmationDialogComponent.defaultProps = {
  isOpen: false,
  restore: false,
  isLoading: false,
  yesText: 'Yes',
  noText: 'No',
  msg: '',
  heading: 'Confirmation',
  remoteError: null,
  onConfirm: () => { },
  onCancel: () => { },
}

export default ConfirmationDialogComponent;