import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Message, Breadcrumb, Form } from 'semantic-ui-react';
import PractitionerEditor from './PractitionerEditor';
import practitionerService from '../../services/practitioner';
import URLS, { URL_PREFIX } from '../../services/URLS';
import { convertResponseError } from '../../helpers/util';
import Messages from '../../config/messages';


export default class Practitioner extends Component {

  state = {
    practitionerEditor: {
      loading: true,
      remoteError: null,
      remoteSuccess: false
    },
    loaded: false,
    practitioner: null,
    loadError: null,
    customer: null
  };

  componentDidMount() {
    this.reloadPractitioner();
    if (window.jsInterface) {
      window.jsInterface.onPageLevelChanged(2, window.location.href);
    }
  }

  reloadPractitioner() {
    this.setState({
      practitionerEditor: {
        ...this.state.practitionerEditor,
        loading: true
      },
      loaded: false,
    });

    practitionerService.getPractitionerById(this.props.match.params.practitionerId)
      .then(response => {
        if (response) {
          this.setState({
            practitionerEditor: {
              ...this.state.practitionerEditor,
              loading: false
            },
            loaded: true,
            practitioner: response.data,
          });
        } else {
          this.setState({
            remoteError: { message: 'No response from the server.' }
          });
        }
      })
      .catch(error => {
        this.setState({
          practitionerEditor: {
            ...this.state.practitionerEditor,
            loading: false
          },
          loadError: convertResponseError(error),
        });
      });
  }

  render() {
    const { practitioner, loaded, loadError } = this.state;
    const { user } = practitioner || {};
    const { contactDetail } = user || {};
    const { firstName = "-", } = contactDetail || {};
    const { location: { search } } = this.props;

    return (
      <div>
        {practitioner && this.props.user.role === Messages["role-admin"] &&
          <Breadcrumb icon='right angle' sections={[
            {
              key: 'bread-1',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${search}`}>
                Customers
              </Link>
            },
            {
              key: 'bread-2',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${practitioner.customer.id}${URL_PREFIX}${URLS.PRACTITIONERS}${search}`}>
                Practitioners
              </Link>
            },
            {
              key: 'bread-3',
              content: firstName,
              active: true
            }
          ]} />}
        {practitioner && this.props.user.role === Messages["role-customer"] &&
          <Breadcrumb icon='right angle' sections={[
            {
              key: 'bread-1',
              content: <Link id="app-interface-first-level-page" to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${URL_PREFIX}${URLS.PRACTITIONERS}${search}`}>
                Practitioners
              </Link>
            },
            {
              key: 'bread-2',
              content: firstName,
              active: true
            }
          ]} />}

        {loadError &&
          <Message error>
            <Message.Header>Error {loadError.status}</Message.Header>
            <p>{loadError.message}</p>
          </Message>
        }

        {practitioner && (
          <Form loading={this.state.practitionerEditor.loading}>
            <PractitionerEditor
              practitioner={practitioner}
              loading={this.state.practitionerEditor.loading}
              disabled={!loaded}
              remoteError={this.state.practitionerEditor.remoteError}
              remoteSuccess={this.state.practitionerEditor.remoteSuccess}
              isUpdate={true}
              onSubmit={practitioner => {
                this.setState({
                  practitionerEditor: {
                    ...this.state.practitionerEditor,
                    loading: true
                  },
                });
                const user = {
                  email: practitioner.email,
                  contactDetail: {
                    firstName: practitioner.firstName,
                    lastName: practitioner.lastName,
                    phone: practitioner.phone,
                    email: practitioner.contactEmail
                  },
                  role: practitioner.role
                };
                practitionerService.updatePractitioner(this.state.practitioner.id, user)
                  .then(response => {
                    if (response) {
                      const { customer: { id } } = response.data || {}
                      this.setState({
                        practitionerEditor: {
                          ...this.state.practitionerEditor,
                          loading: false,
                          remoteError: null,
                          remoteSuccess: true
                        },
                      });
                      this.props.history.push(`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${id}${URL_PREFIX}${URLS.PRACTITIONERS}`)
                    } else {
                      this.setState({
                        practitionerEditor: {
                          ...this.state.practitionerEditor,
                          remoteError: { message: Messages["no-responce-from-server"] },
                          remoteSuccess: false
                        }
                      });
                    }
                  })
                  .catch((error) => {
                    this.setState({
                      practitionerEditor: {
                        ...this.state.practitionerEditor,
                        remoteError: convertResponseError(error),
                        remoteSuccess: false,
                        loading: false
                      }
                    });
                  });
              }}
            />
          </Form>
        )}
      </div>
    );
  }
}
