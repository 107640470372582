import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Header,
  Form,
  Input,
  Segment,
  Breadcrumb,
  Message,
  TextArea,
  Label
} from 'semantic-ui-react';
import Messages from '../../config/messages';
import { convertResponseError, getSearchQueries } from '../../helpers/util';

import customerService from '../../services/customer';
import URLS, { URL_PREFIX } from '../../services/URLS';
import Preview from './Preview';

class AddEditLocation extends Component {
  state = {
    isLoading: false,
    name: '',
    phone: '',
    email: '',
    address: '',
    websiteUrl: '',
    customerId: '',
    locationId: '',
    addEdit: 'Add',
    validationErrors: {},
    remoteError: null
  };

  componentDidMount() {
    const {
      match: { params },
      user
    } = this.props;

    const quries = getSearchQueries();
    if (user && user.role === Messages["role-admin"] && params && params.locationId) {
      if (quries && quries.customerId) {
        this.setState({
          locationId: params.locationId,
          customerId: quries.customerId,
        },
          () => this.getLocationById(params)
        )
      }
      return;
    }

    if (user && user.role === Messages["role-admin"] && params && !params.locationId) {
      if (quries && quries.customerId) {
        this.getCustomer(quries.customerId)
      }
      return;
    }

    const { customer } = user || {};
    const { id = "" } = customer || {};

    if (user && user.role === Messages["role-customer"] && params && params.locationId) {

      this.setState({
        locationId: params.locationId,
        customerId: id,
      },
        () => this.getLocationById(params)
      );
      return;
    }
    this.getCustomer(id)
  };

  /**
   * Get customer
   * 
   * @param {String} customerId 
   */
  getCustomer = customerId => {
    this.setState({ isLoading: true })

    customerService.getCustomerById(customerId)
      .then(response => {
        this.setState({ isLoading: false })

        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        const { businessProfile } = response.data || {};
        const { websiteUrl } = businessProfile || {};
        this.setState({ websiteUrl, customerId });
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Get location by id
   * 
   * @param {Object} params 
   */
  getLocationById = params => {
    this.setState({ isLoading: true });

    const { customerId } = this.state

    customerService.getBusinessLocationById(customerId)
      .then(response => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
        const location = response.data.find(({ id }) => id === params.locationId);
        const { businessProfile } = location;
        this.setState({
          name: businessProfile.name,
          email: businessProfile.email,
          websiteUrl: businessProfile.websiteUrl,
          address: businessProfile.address,
          phone: businessProfile.phone,
          addEdit: businessProfile.name,
        })

        this.setState({ isLoading: false });

      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Handle > On change input value
   *
   * @param {Event} evt 
   * @param {Object}
   */
  onChangeInput = (evt, { name, value }) => {
    this.setState({ [name]: value });
  };

  /**
   * Handle > Submit Form
   *
   * @param {Event} evt
   */
  handleSubmit = async evt => {
    if (evt) evt.preventDefault();

    this.setState({
      validationErrors: {},
      remoteError: null
    })

    const {
      name,
      email,
      websiteUrl,
      phone,
      address,
      locationId
    } = this.state

    const fields = {
      name,
      email,
      websiteUrl,
      phone,
      address
    }
    const field = { name }

    const isValid = await customerService.businessSchema.isValid(field)

    if (!isValid) {
      this.validateAll(field);
      return;
    }

    this.setState({ isLoading: true });

    if (locationId) {

      this.editLocation(locationId, fields)
      return;
    }

    this.addLocation(fields)
  };

  /**
   * Edit Location
   * 
   * @param {String} locationId 
   * @param {Object} location 
   */
  editLocation = (locationId, location) => {
    customerService.editBusinessLocation(locationId, location)
      .then((response) => {
        this.setState({ isLoading: false });

        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        if (this.props.user && this.props.user.role === Messages["role-admin"]) {
          this.props.history.push(`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}?customerId=${this.state.customerId}`)
          return;
        }

        this.props.history.push(`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}`)
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }


  /**
   * Handle > Add Location
   * 
   * @param {Object} customer 
   */
  addLocation = location => {
    const { customerId } = this.state;

    customerService.addBusinessLocation(customerId, location)
      .then((response) => {
        this.setState({ isLoading: false });

        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
        if (this.props.user && this.props.user.role === Messages["role-admin"]) {
          this.props.history.push(`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}?customerId=${this.state.customerId}`)
          return;
        }
        this.props.history.push(`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}`)
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Validate All Fields
   *
   * @param {Object} fields 
   */
  validateAll(fields) {
    for (const field of Object.keys(fields)) {
      this.validateOne(field, fields);
    }
  }

  /**
   * Validate Single Field  
   *
   * @param {*} field 
   * @param {*} fields 
   */
  validateOne(field, fields) {
    customerService.businessSchema.validateAt(field, fields)
      .catch(error => {
        this.setState(state => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0]
          }
        }));
      });
  }


  /**
   * Render View
   */
  render() {
    const {
      name,
      email,
      phone,
      address,
      websiteUrl,
      isLoading,
      validationErrors,
      remoteError,
      customerId,
      addEdit,
    } = this.state;
    const { user } = this.props;

    return (
      <>
        {user && user.role === Messages["role-customer"] && (
          <Breadcrumb
            icon='right angle'
            sections={[
              {
                key: 'bread-1',
                content: <Link to={`${URL_PREFIX}${URLS.BUSINESS}`} >Business</Link>
              },
              {
                key: 'bread-2',
                content: <Link to={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}`} >Locations</Link>
              },
              {
                key: 'bread-3',
                content: addEdit,
                active: true
              }
            ]} />
        )}

        {user && user.role === Messages["role-admin"] && (
          <Breadcrumb
            icon='right angle'
            sections={[
              {
                key: 'bread-1',
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}`} >Customers</Link>
              },
              {
                key: 'bread-2',
                content: <Link to={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}?customerId=${customerId}`} >Locations</Link>
              },
              {
                key: 'bread-3',
                content: addEdit,
                active: true
              }
            ]} />
        )}

        <div>
          <Form
            onSubmit={event => this.handleSubmit(event)}
          >
            <Segment.Group>
              <Segment loading={isLoading}>
                <Header as='h3'>Location</Header>
                <Form.Field error={Boolean(validationErrors["name"])}>
                  <label>Business Name *</label>
                  <Input
                    type='text'
                    name="name"
                    placeholder='Business Name'
                    value={name}
                    onChange={this.onChangeInput}
                  />
                  {Boolean(validationErrors["name"]) && (
                    <Label basic color="red" pointing>
                      {validationErrors["name"]}
                    </Label>
                  )}
                </Form.Field>

                {customerId && (
                  < Preview id={customerId} isDefaultLogo />
                )}

                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Phone</label>
                    <Input
                      type='text'
                      name="phone"
                      placeholder='Phone Number'
                      value={phone}
                      onChange={this.onChangeInput}
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>Email</label>
                    <Input
                      placeholder='Email'
                      name='email'
                      value={email}
                      onChange={this.onChangeInput}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field >
                  <label>Address</label>
                  <TextArea
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={this.onChangeInput}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Website Url</label>
                  <Input
                    placeholder="Website Url"
                    type="text"
                    name="websiteUrl"
                    value={websiteUrl}
                    onChange={this.onChangeInput}
                  />
                </Form.Field>

                <Form.Field>
                  <label>*  This information will show to your clients </label>
                </Form.Field>

              </Segment>
              <Segment>
                <Button
                  primary
                  type="submit"
                  onClick={this.handleSubmit}
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </Segment>
            </Segment.Group>
          </Form>
          {remoteError && (
            <Message error>
              <Message.Header>Error {remoteError.status}</Message.Header>
              {remoteError.status === 400 && remoteError.validationErrors ? (
                <ul className="list">
                  {remoteError.validationErrors.map((item, key) => (
                    <li key={key} className="content">
                      {item.defaultMessage}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{remoteError.message}</p>
              )}
            </Message>
          )}
        </div>
      </>
    )
  }
}

export default AddEditLocation;