import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  Header,
  Form,
  Input,
  Segment,
  Dropdown,
  Breadcrumb,
  Label,
  Message
} from 'semantic-ui-react';
import Messages from '../../config/messages';
import { convertResponseError } from '../../helpers/util';

import customerService from '../../services/customer';
import URLS, { URL_PREFIX } from '../../services/URLS';

class AddCustomer extends Component {
  state = {
    isLoading: false,

    licenceType: '',
    licences: 0,
    email: '',

    validationErrors: {},
    remoteError: null,

    isSuccess: false,
  }

  /**
   * Handle > On change input value
   *
   * @param {Event} evt 
   * @param {Object}
   */
  onChangeInput = (evt, { name, value }) => {
    this.setState({ [name]: value });
  }

  /**
   * Handle > Submit Form
   *
   * @param {Event} evt
   */
  handleSubmit = async evt => {
    if (evt) evt.preventDefault();

    const { licenceType, licences, email } = this.state;

    const fields = { licenceType, licences, email };

    this.setState({ validationErrors: {}, remoteError: null });

    const isValid = await customerService.customerLicenceSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }

    this.addCustomer(fields);
  };


  /**
   * Handle > Add Customer
   * 
   * @param {Object} customer 
   */
  addCustomer = customer => {
    this.setState({ isLoading: true });
    const { licenceType, licences, email } = customer

    const params = {
      licenceType,
      licences,
      invitationLink: { email }
    }

    customerService.addCustomer(params)
      .then(response => {
        if (!response) {
          this.setState({
            isLoading: false,
            isSuccess: false,
            remoteError: { message: Messages['no-responce-from-server'] }
          });
          return;
        }

        this.setState({ isSuccess: true });
        setTimeout(() => {
          this.setState({ isLoading: false })
          this.props.history.push(`${URL_PREFIX}${URLS.CUSTOMERS}`);
        }, 2500);
      })
      .catch(error => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
          isSuccess: false
        });
      });
  }

  /**
   * Validate All Fields
   *
   * @param {Object} fields 
   */
  validateAll(fields) {
    for (const field of Object.keys(fields)) {
      this.validateOne(field, fields);
    }
  }

  /**
   * Validate Single Field  
   *
   * @param {*} field 
   * @param {*} fields 
   */
  validateOne(field, fields) {
    customerService.customerLicenceSchema.validateAt(field, fields)
      .catch(error => {
        this.setState(state => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0]
          }
        }));
      });
  }

  /**
   * Handle Error Message 
   *
   * @param {Object} remoteError 
   */
  handleErrorMessage = (remoteError) => {
    if (remoteError && remoteError.status !== 400) {
      return (
        <Message error>
          <p>{remoteError.message}</p>
        </Message>
      )
    }

    const { validationErrors } = this.state;

    if (remoteError.validationErrors) {
      remoteError.validationErrors.forEach(val => validationErrors[val.field] = val.defaultMessage)
    }
  }

  /**
   * Render View
   */
  render() {
    const {
      licenceType,
      licences,
      email,
      validationErrors,
      remoteError,
      isLoading,
      isSuccess,
    } = this.state;

    return (
      <>
        <Breadcrumb
          icon='right angle'
          sections={[
            {
              key: 'bread-1',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}`} >Customers</Link>
            },
            {
              key: 'bread-3',
              content: 'Add',
              active: true
            }
          ]} />

        <div>
          {remoteError && this.handleErrorMessage(remoteError)}

          {isSuccess && <Message
            success
            header={Messages['invitation-sent'].replace('#EMAIL#', email)}
          />}

          <Form
            onSubmit={event => this.handleSubmit(event)}
          >
            <Segment.Group>
              <Segment loading={isLoading}>
                <Header as='h3'>Licence Info</Header>
                <Form.Group widths='equal'>
                  <Form.Field error={Boolean(validationErrors.licenceType)}>
                    <label>Licence Type *</label>
                    <Dropdown
                      placeholder='Licence Type'
                      fluid
                      selection
                      options={customerService.LICENCE_TYPE_OPTIONS}
                      name="licenceType"
                      value={licenceType}
                      onChange={this.onChangeInput}
                    />
                    {Boolean(validationErrors.licenceType) &&
                      <Label basic color='red' pointing>{validationErrors.licenceType}</Label>
                    }
                  </Form.Field>

                  <Form.Field error={Boolean(validationErrors.licences)}>
                    <label>Number of Licences *</label>
                    <Input
                      type='number'
                      min={0}
                      name="licences"
                      placeholder='Number of Licences'
                      value={licences}
                      onChange={this.onChangeInput}
                    />
                    {Boolean(validationErrors.licences) &&
                      <Label basic color='red' pointing>{validationErrors.licences}</Label>
                    }
                  </Form.Field>
                </Form.Group>
                <Form.Field error={Boolean(validationErrors.email)}>
                  <label>Email Invitaton Link To *</label>
                  <Input
                    placeholder='Invitation Link'
                    name='email'
                    value={email || ''}
                    onChange={this.onChangeInput}
                  />
                  {Boolean(validationErrors.email) &&
                    <Label basic color='red' pointing>{validationErrors.email}</Label>
                  }
                </Form.Field>
              </Segment>
              <Segment>
                <Button
                  primary
                  type="submit"
                  onClick={this.handleSubmit}
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </Segment>
            </Segment.Group>
          </Form>
        </div>
      </>
    )
  }
}

export default AddCustomer;