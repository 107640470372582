import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import PractitionerEditor from './PractitionerEditor';
import customerService from '../../services/customer';
import practitionerService from '../../services/practitioner';
import URLS, { URL_PREFIX } from '../../services/URLS';
import messages from '../../config/messages';
import { convertResponseError } from '../../helpers/util';

export default class PractitionerAdd extends Component {

  state = {
    practitioner: null,
    loading: false,
    remoteError: null,
    customer: null
  };

  componentDidMount() {
    const {
      match: { params: { customerId } }
    } = this.props;

    customerService.getCustomerById(customerId)
      .then(response => {
        if (response) {
          this.setState({
            customer: response.data
          });
        } else {
          this.setState({
            error: { message: 'No response from the server.' }
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          loaded: false,
          error: convertResponseError(error)
        });
      });

    if (window.jsInterface) {
      window.jsInterface.onPageLevelChanged(2, window.location.href);
    }
  }

  render() {
    const { practitioner, loading, remoteError } = this.state;
    const {
      match: {
        params: { customerId }
      },
      location: { search }
    } = this.props

    return (
      <div>
        {this.props.user.role === messages["role-admin"] &&
          <Breadcrumb icon='right angle' sections={[
            {
              key: 'bread-1',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${search}`}>
                Customers
              </Link>
            },
            {
              key: 'bread-2',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customerId}${search}`}>
                {this.state.customer ? this.state.customer.name : ''}
              </Link>
            },
            {
              key: 'bread-3',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customerId}${URL_PREFIX}${URLS.PRACTITIONERS}${search}`}>
                Practitioners
              </Link>
            },
            {
              key: 'bread-4',
              content: 'Add',
              active: true
            }
          ]} />}
        {this.props.user.role === messages["role-customer"] &&
          <Breadcrumb icon='right angle' sections={[
            {
              key: 'bread-1',
              content: <Link id="app-interface-first-level-page" to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customerId}${URL_PREFIX}${URLS.PRACTITIONERS}${search}`}>
                Practitioners
              </Link>
            },
            {
              key: 'bread-2',
              content: 'Add',
              active: true
            }
          ]} />}

        <PractitionerEditor
          isAdding
          practitioner={practitioner}
          loading={loading}
          remoteError={remoteError}
          onSubmit={practitioner => {
            this.setState({
              practitioner,
              loading: true,
              remoteError: null
            });
            const addPractitioner = {
              user: {
                email: practitioner.email,
                role: practitioner.role,
                contactDetail: {
                  firstName: practitioner.firstName,
                  lastName: practitioner.lastName
                }
              }
            }
            practitionerService.addPractitioner(customerId, addPractitioner)
              .then(response => {
                if (response) {
                  this.props.history.push(`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customerId}${URL_PREFIX}${URLS.PRACTITIONERS}${search}`);
                } else {
                  this.setState({
                    remoteError: { message: 'No response from the server.' }
                  });
                }
              })
              .catch(error => {
                this.setState({
                  remoteError: convertResponseError(error),
                  loading: false
                });
              });
          }}
        />
      </div>
    );
  }
}
