import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  Breadcrumb,
  Button,
  List,
  Message,
  Modal,
  Segment,
  Table
} from 'semantic-ui-react';


import customerService from '../../services/customer';
import Messages from '../../config/messages';
import { convertResponseError } from '../../helpers/util';
import URLS, { URL_PREFIX } from '../../services/URLS';

class Tablets extends Component {
  state = {
    isFetchingCustomer: false,
    isDeleteModalOpen: false,

    customer: {},
    selectedTablet: {},
  };

  componentDidMount() {
    this.fetchCustomer();
  }

  /**
   * Fetch Customer By Id
   */
  fetchCustomer = () => {
    const {
      match: {
        params: { customerId }
      }
    } = this.props;

    this.setState({ isFetchingCustomer: true });

    customerService.getCustomerById(customerId)
      .then(response => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages['no-responce-from-server'] },
            isFetchingCustomer: false
          });
          return;
        }

        this.setState({ customer: response.data, isFetchingCustomer: false });
      })
      .catch(error => {
        this.setState({
          isFetchingCustomer: false,
          remoteError: convertResponseError(error)
        });
      });
  }

  /**
   * Open Tablet Delete Modal
   * 
   * @param {Object} selectedTablet 
   */
  openTabletDeleteModal = selectedTablet => () => {
    this.setState({ selectedTablet, isDeleteModalOpen: true });
  }

  /**
   * Close Tablet Delete Modal
   */
  closeTabletDeleteModal = () => {
    this.setState({ selectedTablet: {}, isDeleteModalOpen: false });
  }

  /**
   * Get Tablet Modal Message Content
   */
  getTabletModalMessageContent = () => {
    const {
      selectedTablet: { deleted, id = '' },
    } = this.state;

    const { list, header } = deleted
      ? customerService.DELETE_TABLET_MODAL_CONFIG
      : customerService.RESTORE_TABLET_MODAL_CONFIG;

    return (
      <>
        <p>{header.replace('#ID', id)}</p>

        <List.List as='ul'>
          {list.map(value => (
            <List.Item as='li' key={value}>{value}</List.Item>
          ))}
        </List.List>
      </>
    )
  }

  /**
   * Delete Or Restore Tablet
   */
  deleteOrRestoreTablet() {
    const {
      selectedTablet: { id, deleted },
      customer,
    } = this.state;

    customerService.patchTablet(id, { deleted: !deleted })
      .then(response => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages['no-responce-from-server'] },
          });

          return;
        }

        customer.tablets = customer.tablets.map(tablet => {
          if (tablet.id === response.data.id) return response.data;

          return tablet;
        });

        this.setState({ customer }, this.closeTabletDeleteModal);
      })
      .catch(error => {
        this.setState({ remoteError: convertResponseError(error) });
      });
  }

  /**
   * Render View
   */
  render() {
    const {
      isFetchingCustomer,
      customer: { id, name, tablets },
      isDeleteModalOpen,
      selectedTablet: { deleted },
      remoteError
    } = this.state;

    return (
      <>
        <Segment
          loading={isFetchingCustomer}
          className="fake-segment"
        >
          <Breadcrumb
            icon='right angle'
            sections={[
              {
                key: 'bread-1',
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}`} >Customers</Link>
              },
              {
                key: 'bread-2',
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${id}`}>{name || ''}</Link>
              },
              {
                key: 'bread-3',
                content: 'Tablets',
                active: true
              }
            ]} />

          <Table singleLine size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Installation ID</Table.HeaderCell>
                <Table.HeaderCell>Model</Table.HeaderCell>
                <Table.HeaderCell>Android</Table.HeaderCell>
                <Table.HeaderCell>App</Table.HeaderCell>
                <Table.HeaderCell>Last Active</Table.HeaderCell>
                <Table.HeaderCell>Installed</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {(!tablets || tablets.length === 0) && <Table.Row><Table.Cell textAlign='center' colSpan='6'>No Record Found</Table.Cell></Table.Row>}

              {tablets && tablets.map((tablet) => (
                <Table.Row key={tablet.id} style={{ color: tablet.deleted ? '#aaa' : 'unset' }}>
                  <Table.Cell>{tablet.id} {tablet.deleted ? <b style={{ color: 'red' }}>Deleted</b> : ''}</Table.Cell>
                  <Table.Cell>{tablet.manufacturer} {tablet.model}</Table.Cell>
                  <Table.Cell>{tablet.androidVersion} ({tablet.language})</Table.Cell>
                  <Table.Cell>{tablet.appVersion}</Table.Cell>
                  <Table.Cell>{moment(tablet.lastActive).fromNow()}</Table.Cell>
                  <Table.Cell>{moment(tablet.installedAt).fromNow()}</Table.Cell>
                  <Table.Cell>
                    <Button
                      as={Button}
                      circular
                      size='mini'
                      onClick={this.openTabletDeleteModal(tablet)}
                      icon={!tablet.deleted ? 'delete' : 'redo'} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>

        {/* Confirmation Modal */}
        <Modal
          size="mini"
          open={isDeleteModalOpen}
          onClose={this.closeTabletDeleteModal}>
          <Modal.Header>
            {deleted ? 'Delete' : 'Restore'} tablet?
          </Modal.Header>

          <Modal.Content>
            {this.getTabletModalMessageContent()}
            {remoteError &&
              <Message error>
                <Message.Header>Error {remoteError.status}</Message.Header>
                <p>{remoteError.message}</p>
              </Message>
            }
          </Modal.Content>

          <Modal.Actions>
            <Button
              onClick={this.closeTabletDeleteModal}>No</Button>
            <Button
              className={deleted ? 'negative' : 'primary'}
              content='Yes'
              onClick={() => this.deleteOrRestoreTablet()}
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default Tablets;