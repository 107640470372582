import React, { Component } from "react";
import { Form, Button, Icon, Input, Label, Message } from "semantic-ui-react";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";

import request from "../../helpers/request";

import customerService from "../../services/customer";

class LoginDetails extends Component {
  state = {
    type: "password",
    icon: "eye",

    loading: false,
    loginMessage: null,

    validationErrors: {},
    remoteError: null,
    isLoading: false,
  };

  /**
   * Save and Continue
   *
   * @param {Event} event
   */
  saveAndContinue = async (event) => {
    event.preventDefault();

    this.setState({
      validationErrors: {},
      remoteError: null,
    });

    const {
      values: {
        loginDetail: { email, password },
      },
    } = this.props;

    const fields = {
      email,
      password,
    };

    const isValid = await customerService.loginSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }
    this.setState({ isLoading: true });

    this.addUser(fields);
  };

  /**
   * Validate All
   *
   * @param {Object} fields
   * @param {Sting} parentPath
   * @param {Object} root
   */
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : "";

    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + ".", root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    customerService.loginSchema.validateAt(field, fields).catch((error) => {
      this.setState((state) => ({
        validationErrors: {
          ...state.validationErrors,
          [field]: error.errors[0],
        },
      }));
    });
  }

  /**
   * Add User
   *
   * @param {Object} newUser
   */
  addUser = (newUser) => {
    const { code, customerId, user } = this.props;

    this.setState({ isLoading: true });

    customerService
      .addUser(customerId, newUser, code)
      .then((response) => {
        this.setState({ isLoading: false });

        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        if (!user) {
          customerService.saveEmailTokenToLocalStorage(response.data);

          if( !request.defaults.headers.common['Email-Token']){

            request.defaults.headers.common['Email-Token'] = response.data.user.email;
          }
        }

        this.props.nextStep();
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  };

  /*
   * Hide and show Password
   */
  handleClick = () =>
    this.setState(({ type, icon }) => ({
      type: type === "password" ? "text" : "password",
      icon: icon === "eye" ? "eye slash" : "eye",
    }));

  /**
   * Render View
   */
  render() {
    const {
      values: {
        loginDetail: { email, password },
      },
      handleChange,
    } = this.props;
    const { validationErrors, remoteError, isLoading } = this.state;

    return (
      <>
        <Form className="mt-10 custom-form" loading={isLoading}>
          <label className="ui centered">
            1
            <Icon name="long arrow alternate right" />
            Please let us know the login details you prefer for AxIT app and the
            Dashboard
          </label>

          <Form.Field
            className="mt-10"
            error={Boolean(validationErrors["email"])}
          >
            <label>Email *</label>
            <Input
              placeholder="Email"
              name="email"
              value={email}
              autoComplete='off'
              onChange={handleChange("email", "loginDetail")}
            />
            {Boolean(validationErrors["email"]) && (
              <Label basic color="red" pointing>
                {validationErrors["email"]}
              </Label>
            )}
          </Form.Field>
          <Form.Field error={Boolean(validationErrors["password"])}>
            <label>Password *</label>
            <Input
              placeholder="Password"
              name="password"
              autoComplete='off'
              type={this.state.type}
              action={{ icon: this.state.icon, onClick: this.handleClick }}
              value={password}
              onChange={handleChange("password", "loginDetail")}
            />
            {Boolean(validationErrors["password"]) && (
              <Label basic color="red" pointing>
                {validationErrors["password"]}
              </Label>
            )}
          </Form.Field>
          <Button onClick={this.saveAndContinue}>Next</Button>
        </Form>
        {remoteError && (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        )}
      </>
    );
  }
}

export default LoginDetails;
