import React, { Component } from 'react';
import {
  Icon,
  Item,
  Statistic,
  Segment
} from 'semantic-ui-react'
import homeService from '../../services/home';
import messages from '../../config/messages';

export default class Dashboard extends Component {

  state = {
    statistics: {
      customers: null,
      installations: null,
      practitioners: null,
      clients: null,
      sessions: null,
      tests: null
    }
  }

  componentDidMount() {
    homeService.getStatistics(this.props.user.role === messages["role-customer"] ? this.props.user.customer.id : null)
      .then(response => {
        if (response) {
          this.setState({
            statistics: response.data
          });
        } else {
          this.setState({
            statistics: {
              customers: null,
              installations: null,
              practitioners: null,
              clients: null,
              sessions: null,
              tests: null
            }
          });
        }
      })
      .catch(error => {
        this.setState({
          statistics: {
            customers: null,
            installations: null,
            practitioners: null,
            clients: null,
            sessions: null,
            tests: null
          }
        });
      });
  }

  render() {
    const { statistics } = this.state;

    return (
      <div>
        <Segment style={{ padding: '20px' }}>
          {this.props.user.role === messages["role-customer"] ?
            <Statistic.Group widths='four'>
              <Statistic>
                <Statistic.Value>
                  <Icon name='user md' /> {statistics.practitioners !== null ? statistics.practitioners : '-'}
                </Statistic.Value>
                <Statistic.Label>Practitioners</Statistic.Label>
              </Statistic>

              <Statistic>
                <Statistic.Value>
                  <Icon name='user' /> {statistics.clients !== null ? statistics.clients : '-'}
                </Statistic.Value>
                <Statistic.Label>Clients</Statistic.Label>
              </Statistic>

              <Statistic>
                <Statistic.Value>
                  {statistics.sessions !== null ? statistics.sessions : '-'}
                </Statistic.Value>
                <Statistic.Label>Sessions</Statistic.Label>
              </Statistic>

              <Statistic>
                <Statistic.Value>
                  {statistics.tests !== null ? statistics.tests : '-'}
                </Statistic.Value>
                <Statistic.Label>Tests</Statistic.Label>
              </Statistic>
            </Statistic.Group> :
            <Item.Group divided>
              <Item>
                <Statistic.Group widths='four'>
                  <Statistic>
                    <Statistic.Value>
                      <Icon name='briefcase' /> {statistics.customers !== null ? statistics.customers : '-'}
                    </Statistic.Value>
                    <Statistic.Label>Customers</Statistic.Label>
                  </Statistic>

                  <Statistic>
                    <Statistic.Value>
                      <Icon name='tablet' /> {statistics.installations !== null ? statistics.installations : '-'}
                    </Statistic.Value>
                    <Statistic.Label>Installations</Statistic.Label>
                  </Statistic>

                  <Statistic>
                    <Statistic.Value>
                      <Icon name='user md' /> {statistics.practitioners !== null ? statistics.practitioners : '-'}
                    </Statistic.Value>
                    <Statistic.Label>Practitioners</Statistic.Label>
                  </Statistic>

                  <Statistic>
                    <Statistic.Value>
                      <Icon name='user' /> {statistics.clients !== null ? statistics.clients : '-'}
                    </Statistic.Value>
                    <Statistic.Label>Clients</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Item>
              <Item>
                <Statistic.Group widths='two'>
                  <Statistic>
                    <Statistic.Value>
                      {statistics.sessions !== null ? statistics.sessions : '-'}
                    </Statistic.Value>
                    <Statistic.Label>Sessions</Statistic.Label>
                  </Statistic>

                  <Statistic>
                    <Statistic.Value>
                      {statistics.tests !== null ? statistics.tests : '-'}
                    </Statistic.Value>
                    <Statistic.Label>Tests</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </Item>
            </Item.Group>
          }
        </Segment>
      </div>
    );
  }
}
