import uuid from 'uuid/v4';

const localStorageKeyPlaylists = 'axit_test_editor_playlists';

const localStorageKeyPlaylistsVersion = 'axit_test_editor_playlists_version';

export default {
  getVersion: function() {
    return localStorage.getItem(localStorageKeyPlaylistsVersion) ? localStorage.getItem(localStorageKeyPlaylistsVersion) : '1.0';
  },
  getPlaylists: function() {
    return [{
      nodeType: 'top',
      id: 'top',
      expanded: true,
      children: localStorage.getItem(localStorageKeyPlaylists) ? JSON.parse(localStorage.getItem(localStorageKeyPlaylists)) : []
    }];
  },
  updateVersion: function(version) {
    localStorage.setItem(localStorageKeyPlaylistsVersion, version);
  },
  updatePlaylists: function(playlists) {
    playlists = playlists[0].children;
    for (var i = 0; i < playlists.length; i++) {
      var category = playlists[i];
      category.sortOrder = i + 1;
      category.deleted = category.deleted ? true : false;
      if (category.children) {
        for (var j = 0; j < category.children.length; j++) {
          var playlist = category.children[j];
          playlist.sortOrder = j + 1;
          playlist.categoryId = category.id;
          playlist.deleted = playlist.deleted ? true : false;
          if (playlist.children) {
            for (var k = 0; k < playlist.children.length; k++) {
              var test = playlist.children[k];
              test.sortOrder = k + 1;
              playlist.children[k] = {
                id: test.testId ? test.id : uuid(),
                nodeType: 'test',
                playlistId: playlist.id,
                testId: test.testId ? test.testId : test.id,
                sortOrder: k + 1
              }
            }
          }
        }
      }
    }
    localStorage.setItem(localStorageKeyPlaylists, JSON.stringify(playlists));
  },
}