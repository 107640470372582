import React, { Component } from 'react';

import 'react-sortable-tree/style.css';

import uuid from 'uuid/v4';

import SortableTree, { changeNodeAtPath, toggleExpandedForAll, removeNodeAtPath, addNodeUnderParent } from 'react-sortable-tree'; 

import { Form, Header, Select, Input, Checkbox, Button, Radio, Icon, Modal, TextArea } from 'semantic-ui-react';

import testService from '../../services/test';


const deviceOptions = [
  {
    text: 'Push-IT',
    value: 'PUSH'
  },
  {
    text: 'Stomp-IT Left',
    value: 'PLATE_LEFT'
  },
  {
    text: 'Stomp-IT Right',
    value: 'PLATE_RIGHT'
  },
  {
    text: 'Pull-IT Left',
    value: 'PULL_LEFT'
  },
  {
    text: 'Pull-IT Right',
    value: 'PULL_RIGHT'
  },
  {
    text: '-',
    value: ''
  }
];

const defaultImportJson = `{
  "version": "1",
  "tests": [],
  "metricPlots": [],
  "plots": []
}`;

const distinct = (value, index, self) => self.indexOf(value) === index;

export default class TestList extends Component {
  state = {
    importJson: defaultImportJson,
    version: testService.getVersion(),
    importModalOpen: false,
    tests: testService.getTests(),
    metricPlots: testService.getMetricPlots(),
    plots: testService.getPlots(),
    algorithms: testService.getAlgorithms(),
    currentTestInfo: {
      node: {
        name: '',
        parameters: {},
        minVersion: '',
        maxVersion: null,
        showUpgradeMessageFromVersion: '0.0.0',
      }
    },
    loading: true,
    sortBy: null, 
    sortDirection: null,
    loaded: false,
    searchString: '',
    searchFocusIndex: 0,
    searchFoundCount: null
  };

  toggleTestExpansion(expanded) {
    this.setState(state => ({
      tests: toggleExpandedForAll({
        treeData: state.tests,
        expanded
      }),
    }));
  }

  updateTests() {
    testService.updateAlgorithms(this.state.algorithms);
    testService.updatePlots(this.state.plots);
    testService.updateMetricPlots(this.state.metricPlots);
    testService.updateTests(this.state.tests);
    testService.updateVersion(this.state.version);
  }

  selectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
          : searchFoundCount - 1,
    });
  };

  selectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount } = this.state;

    this.setState({
      searchFocusIndex:
        searchFocusIndex !== null
          ? (searchFocusIndex + 1) % searchFoundCount
          : 0,
    });
  };

  setTestCategoryStateWithValue(field, value) {
    this.setState(state => ({ 
      currentTestCategoryInfo: {
        ...state.currentTestCategoryInfo,
        node: {
          ...state.currentTestCategoryInfo.node,
          [field]: value
        }
      } 
    }), () => {
      this.setState({
        tests: changeNodeAtPath({
          treeData: this.state.tests, 
          path: this.state.currentTestCategoryInfo.path, 
          newNode: this.state.currentTestCategoryInfo.node,
          getNodeKey: ({ node }) => node.id
        })
      }, () => {
        this.updateTests();
      });
    });
  }

  setTestStateWithValue(field, value) {
    this.setState(state => ({ 
      currentTestInfo: {
        ...state.currentTestInfo,
        node: {
          ...state.currentTestInfo.node,
          [field]: value
        }
      } 
    }), () => {
      this.setState({
        tests: changeNodeAtPath({
          treeData: this.state.tests, 
          path: this.state.currentTestInfo.path, 
          newNode: this.state.currentTestInfo.node,
          getNodeKey: ({ node }) => node.id
        })
      }, () => {
        this.updateTests();
      });
    });
  }

  setTestParameterStateWithValue(key, value) {
    this.setState(state => ({ 
      currentTestInfo: {
        ...state.currentTestInfo,
        node: {
          ...state.currentTestInfo.node,
          parameters: {
            ...state.currentTestInfo.node.parameters,
            [key]: value
          }
        }
      }
    }), () => {
      this.setState({
        tests: changeNodeAtPath({
          treeData: this.state.tests, 
          path: this.state.currentTestInfo.path, 
          newNode: this.state.currentTestInfo.node,
          getNodeKey: ({ node }) => node.id
        })
      }, () => {
        this.updateTests();
      });
    });
  }

  setMetricPlotCategoryStateWithValue(field, value) {
    this.setState(state => ({ 
      currentTestMetricPlotCategoryInfo: {
        ...state.currentTestMetricPlotCategoryInfo,
        node: {
          ...state.currentTestMetricPlotCategoryInfo.node,
          [field]: value
        }
      } 
    }), () => {
      var metricPlots = this.state.currentTestInfo.node.metricPlots.map(metricPlotCategory => {
        if (metricPlotCategory.testMetricPlotId === this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId) {
          return this.state.currentTestMetricPlotCategoryInfo.node;
        } else {
          return metricPlotCategory;
        }
      });

      this.setTestStateWithValue('metricPlots', metricPlots);
    });
  }

  setMetricPlotCategoryParameterStateWithValue(key, value) {
    console.log("set", key, value)
    this.setState(state => ({ 
      currentTestMetricPlotCategoryInfo: {
        ...state.currentTestMetricPlotCategoryInfo,
        node: {
          ...state.currentTestMetricPlotCategoryInfo.node,
          parameters: {
            ...state.currentTestMetricPlotCategoryInfo.node.parameters,
            [key]: value
          }
        }
      }
    }), () => {
      var metricPlots = this.state.currentTestInfo.node.metricPlots.map(metricPlotCategory => {
        if (metricPlotCategory.testMetricPlotId === this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId) {
          return this.state.currentTestMetricPlotCategoryInfo.node;
        } else {
          return metricPlotCategory;
        }
      });

      this.setTestStateWithValue('metricPlots', metricPlots);
      console.log("result", metricPlots)
    });
  }

  deleteMetricPlotCategoryParameterState(key) {
    var currentTestMetricPlotCategoryInfo = JSON.parse(JSON.stringify(this.state.currentTestMetricPlotCategoryInfo));
    delete currentTestMetricPlotCategoryInfo.node.parameters[key];

    this.setState(state => ({ 
      currentTestMetricPlotCategoryInfo: currentTestMetricPlotCategoryInfo
    }), () => {
      var metricPlots = this.state.currentTestInfo.node.metricPlots.map(metricPlotCategory => {
        if (metricPlotCategory.testMetricPlotId === this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId) {
          return this.state.currentTestMetricPlotCategoryInfo.node;
        } else {
          return metricPlotCategory;
        }
      });

      this.setTestStateWithValue('metricPlots', metricPlots);
    });
  }

  getAlgorithmNameOfMetricPlot(metricPlot) {
    return metricPlot.parameters && metricPlot.parameters.algorithm ? 
      testService.getAlgorithmById(metricPlot.parameters.algorithm).name : 
      testService.getAlgorithmById(testService.getMetricPlotCategoryById(metricPlot.id).parametersDef
        .filter(def => def.name === 'algorithm')[0].default).name
  }

  componentDidMount() {
    for (var i = 0; i < this.state.tests.length; i++) {
      var device = this.state.tests[i];
      for (var j = 0; j < device.children.length; j++) {
        var category = device.children[j];
        if (category.children) {
          for (var k = 0; k < category.children.length; k++) {
            var test = category.children[k]
            if (test.metricPlots) {
              for (var l = 0; l < test.metricPlots.length; l++) {
                var _metricPlotCategory = test.metricPlots[l];
                var metricPlotCategory = testService.getMetricPlotCategoryById(_metricPlotCategory.id);
                metricPlotCategory.parameters = _metricPlotCategory.parameters;
                metricPlotCategory.testMetricPlotId = _metricPlotCategory.testMetricPlotId;
                metricPlotCategory.sortOrder = _metricPlotCategory.sortOrder;
                metricPlotCategory.displayName = _metricPlotCategory.displayName;
                test.metricPlots[l] = metricPlotCategory;
              }
            }
          }
        }
      }
    }

    this.setState({
      tests: this.state.tests
    });
  }

  render() {
    const {
      searchString,
      searchFocusIndex,
      searchFoundCount
    } = this.state;
    const test = this.state.currentTestInfo ? this.state.currentTestInfo.node : null;
    const testCategory = this.state.currentTestCategoryInfo ? this.state.currentTestCategoryInfo.node : null;

    return (
      <div style={{ height: 'calc(100% + 24px)', display: 'flex', flexDirection: 'row', margin: '-12px' }}>
        <div style={{ height: '100%', flex: '55%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: 0, paddingBottom: '5px', borderBottom: '1px solid #ddd' }}>
            <Button.Group>
              <Modal 
                open={this.state.importModalOpen}
                trigger={
                  <Button 
                    icon
                    onClick={() => this.setState({
                      importModalOpen: true
                    })}
                  >
                    <Icon name='folder open' />
                  </Button>
                }
                onClose={() => this.setState({
                  importModalOpen: false
                })}
              >
                <Modal.Header>Import</Modal.Header>
                <Modal.Content>
                  <TextArea 
                    style={{ width: '100%' }} 
                    rows={20} 
                    placeholder='JSON' 
                    value={this.state.importJson}
                    onChange={(e, { value }) => {
                      this.setState({
                        importJson: value
                      })
                    }} 
                  />
                  <div style={{ color: 'red' }}>{this.state.importError}</div>
                  <Modal.Actions>
                    <Button
                      onClick={() => {
                        try {
                          const json = JSON.parse(this.state.importJson);
                          this.setState({
                            importJson: defaultImportJson,
                            importError: null,
                            version: json.version,
                            tests: json.tests,
                            importModalOpen: false,
                            searchString: '',
                            plots: json.plots,
                            metricPlots: json.metricPlots,
                            currentTestInfo: null,
                            algorithms: json.algorithms
                          }, () => {
                            this.updateTests();
                          });
                        } catch (e) {
                          this.setState({
                            importError: e.message
                          });
                        }
                      }}
                    >Import</Button>
                  </Modal.Actions>
                </Modal.Content>
              </Modal>
              <Modal trigger={
                <Button icon>
                  <Icon name='save' />
                </Button>
              }>
                <Modal.Header>Export</Modal.Header>
                <Modal.Content>
                  <TextArea 
                    style={{ width: '100%' }} 
                    rows={20} 
                    placeholder='JSON' 
                    value={JSON.stringify({
                      version: testService.getVersion(),
                      tests: testService.getTestsForExport(),
                      metricPlots: testService.getMetricPlots(),
                      plots: testService.getPlots(),
                      algorithms: testService.getAlgorithms()
                    }, null, 2)}
                  />
                </Modal.Content>
              </Modal>
            </Button.Group>

            <Input 
              label='v'
              size='small'
              placeholder='Version' 
              value={this.state.version} 
              onChange={(e, { value }) => {
                this.setState({
                  version: value
                }, () => {
                  this.updateTests();
                });
              }}
              style={{ width: '80px', marginLeft: '5px', height: '36px', top: '-1px', marginRight: '34px' }}
            />

            <Button.Group>
              <Button 
                icon
                onClick={() => this.toggleTestExpansion(true)}
                >
                <Icon name='angle double down' />
              </Button>
              <Button 
                icon
                onClick={() => this.toggleTestExpansion(false)}
                >
                <Icon name='angle double up' />
              </Button>
            </Button.Group>

            <div style={{ float: 'right', marginRight: '10px' }}>
              <Input 
                size='small'
                placeholder='Search' 
                value={searchString} 
                onChange={(e, { value }) => this.setState({
                  searchString: value
                })}
                style={{ marginLeft: '5px', height: '36px' }}
              />
              <Button.Group>
                <Button 
                  icon
                  onClick={() => this.selectPrevMatch()}
                >
                  <Icon name='angle left' />
                </Button>
                <Button 
                  icon
                  onClick={() => this.selectNextMatch()}
                >
                  <Icon name='angle right' />
                </Button>
              </Button.Group>
              <span style={{ marginLeft: '10px' }}>
                {searchFoundCount !== 0 ? searchFocusIndex + 1 : 0} / {searchFoundCount}
              </span>
            </div>
          </div>
          <div style={{ display: 'flex', flexGrow: 0, marginTop: '5px', marginLeft: '186px' }}>
            <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>Device</div>
            <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>Side</div>
            <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>Alg.</div>
            <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>Adap. CP</div>
            <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>CP Win</div>
            <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>OL Warn</div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <SortableTree
              treeData={this.state.tests}
              onChange={treeData => {
                this.setState({ tests: treeData }, () => {
                  this.updateTests();
                });
              }}
              searchQuery={searchString}
              searchFocusOffset={searchFocusIndex}
              searchFinishCallback={matches =>
                this.setState({
                  searchFoundCount: matches.length,
                  searchFocusIndex:
                    matches.length > 0 ? searchFocusIndex % matches.length : 0,
                })
              }
              searchMethod={({ node, searchQuery }) => {
                if (searchQuery !== '') {
                  if (node.nodeType === 'test') {
                    return node.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
                      node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 || 
                      node.mode.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
                  } else {
                    return node.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 || 
                      node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
                  }
                }
                return false;
              }}
              canDrag={drag => {
                return drag.node.nodeType === 'category' || drag.node.nodeType === 'test'
              }}
              canDrop={drop => {
                if (drop.node.nodeType === 'category' && drop.nextParent && drop.nextParent.nodeType === 'device') {
                  return drop.prevParent.deviceType === drop.nextParent.deviceType;
                } else if (drop.node.nodeType === 'test' && drop.nextParent && drop.nextParent.nodeType === 'category') {
                  return drop.prevParent.deviceType === drop.nextParent.deviceType;
                }
                return false;
              }}
              generateNodeProps={info => {
                if (info.node.nodeType === 'test') {
                  return {
                    buttons: [
                      <button
                        className="btn btn-outline-success"
                        style={{
                          verticalAlign: 'middle',
                        }}
                        onClick={() => {
                          this.setState({ 
                            currentTestInfo: info,
                            currentTestCategoryInfo: null
                          });
                        }}
                      >
                        ℹ
                      </button>,
                      <Modal 
                        open={this.state.deleteTestModalOpen}
                        trigger={
                          <button
                            className="btn btn-outline-success"
                            style={{
                              verticalAlign: 'middle',
                            }}
                            onClick={() => {
                              this.setState({ 
                                currentTestInfo: info,
                                currentTestCategoryInfo: null,
                                deleteTestModalOpen: true
                              });
                            }}
                          >
                            x
                          </button>
                        }
                        onClose={() => this.setState({
                          deleteTestModalOpen: false
                        })}
                      >
                        <Modal.Header>
                          {this.state.currentTestInfo ? this.state.currentTestInfo.node.name : ''}
                        </Modal.Header>
                        <Modal.Content>
                          Do you want to delete {this.state.currentTestInfo ? this.state.currentTestInfo.node.name : ''}?
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            onClick={() => {
                              this.setState({ deleteTestModalOpen: false });
                              this.setTestStateWithValue('deleted', this.state.currentTestInfo ? !this.state.currentTestInfo.node.deleted : true)
                            }}
                          >Mark as {this.state.currentTestInfo ? (this.state.currentTestInfo.node.deleted ? 'Not ' : '') : ''}Deleted</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deleteTestModalOpen: false });
                              this.setState({
                                tests: removeNodeAtPath({
                                  treeData: this.state.tests,
                                  path: this.state.currentTestInfo.path,
                                  getNodeKey: ({ node }) => node.id
                                }),
                                currentTestInfo: null
                              }, () => {
                                this.updateTests();
                              });
                            }}
                          >Delete Permanently</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deleteTestModalOpen: false });
                            }}
                          >Cancel</Button>
                        </Modal.Actions>
                      </Modal>
                    ],
                    title: info.node.deleted ? 
                      (<span style={{textDecoration: 'line-through', color: 'red'}}>{info.node.name}</span>) : 
                      info.node.name,
                    subtitle: <div style={{ display: 'flex', marginTop: '5px' }}>
                      <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>
                        {info.node.mode === 'SINGLE_DEVICE_SINGLE_SIDE' || info.node.mode === 'SINGLE_DEVICE_BOTH_SIDE' ? 1 : 2}
                      </div>
                      <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>
                        {info.node.mode === 'SINGLE_DEVICE_SINGLE_SIDE' ? 'Single' : info.node.mode === 'SINGLE_DEVICE_BOTH_SIDE' || info.node.mode === 'DUAL_DEVICE_BOTH_SIDE' ? 'Both' : 'Simul.'}
                      </div>
                      <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>
                        {info.node.metricPlots && info.node.metricPlots.length > 0 ? 
                          info.node.metricPlots.map(metricPlot => this.getAlgorithmNameOfMetricPlot(metricPlot).replace(/\D/g,''))
                            .filter((value) => value !== '')
                            .filter(distinct).join(', ') : 
                          '-'}
                      </div>
                      <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>
                        {info.node.parameters['adaptive_contact_points'] ? '✔️' : '-'}
                      </div>
                      <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>
                        {info.node.parameters['contact_points_window'] ? info.node.parameters['contact_points_window'] : 40}
                      </div>
                      <div style={{ width: '50px', border: '1px solid #ddd', padding: '2px 5px' }}>
                        {info.node.parameters['overload_warning'] ? '✔️' : '-'}
                      </div>
                    </div>
                  }
                } else if (info.node.nodeType === 'category') {
                  return {
                    title: info.node.deleted ? 
                      (<span style={{textDecoration: 'line-through', color: 'red'}}>{info.node.name}</span>) : 
                      info.node.name,
                    subtitle: info.node.children ? `(${info.node.children.filter(test => !test.deleted).length})` : '(0)',
                    buttons: [
                      <button
                        className="btn btn-outline-success"
                        style={{
                          verticalAlign: 'middle',
                        }}
                        onClick={() => {
                          this.setState({ 
                            currentTestCategoryInfo: info,
                            currentTestInfo: null
                          });
                        }}
                      >
                        ℹ
                      </button>,
                      <button
                        className="btn btn-outline-success"
                        style={{
                          verticalAlign: 'middle',
                        }}
                        onClick={() => {
                          this.setState({
                            tests: addNodeUnderParent({
                              treeData: this.state.tests,
                              newNode: {
                                id: uuid(),
                                nodeType: 'test',
                                name: 'New Test',
                                description: '',
                                animation: null,
                                primaryDevice: info.node.deviceType,
                                secondaryDevice: null,
                                mode: 'SINGLE_DEVICE_BOTH_SIDE',
                                upperBody: false,
                                trunk: false,
                                lowerBody: false,
                                deleted: false,
                                parameters: {},
                                metricPlots: []
                              },
                              parentKey: info.node.id,
                              getNodeKey: ({ node }) => node.id,
                              expandParent: true
                            }).treeData
                          }, () => {
                            this.updateTests();
                          });
                        }}
                      >
                        +
                      </button>,
                      <Modal 
                        open={this.state.deleteTestCategoryModalOpen}
                        trigger={
                          <button
                            className="btn btn-outline-success"
                            style={{
                              verticalAlign: 'middle',
                            }}
                            onClick={() => {
                              this.setState({ 
                                currentTestCategoryInfo: info,
                                deleteTestCategoryModalOpen: true
                              });
                            }}
                          >
                            x
                          </button>
                        }
                        onClose={() => this.setState({
                          deleteTestCategoryModalOpen: false
                        })}
                      >
                        <Modal.Header>
                          {this.state.currentTestCategoryInfo ? this.state.currentTestCategoryInfo.node.name : ''}
                        </Modal.Header>
                        <Modal.Content>
                          Do you want to delete {this.state.currentTestCategoryInfo ? this.state.currentTestCategoryInfo.node.name : ''}?
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            onClick={() => {
                              this.setState({ deleteTestCategoryModalOpen: false });
                              this.setTestCategoryStateWithValue('deleted', this.state.currentTestCategoryInfo ? !this.state.currentTestCategoryInfo.node.deleted : true)
                            }}
                          >Mark as {this.state.currentTestCategoryInfo ? (this.state.currentTestCategoryInfo.node.deleted ? 'Not ' : '') : ''}Deleted</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deleteTestCategoryModalOpen: false });
                              this.setState({
                                tests: removeNodeAtPath({
                                  treeData: this.state.tests,
                                  path: this.state.currentTestCategoryInfo.path,
                                  getNodeKey: ({ node }) => node.id
                                }),
                                currentTestCategoryInfo: null
                              }, () => {
                                this.updateTests();
                              });
                            }}
                          >Delete Permanently</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deleteTestCategoryModalOpen: false });
                            }}
                          >Cancel</Button>
                        </Modal.Actions>
                      </Modal>
                    ]
                  }
                } else if (info.node.nodeType === 'device') {
                  return {
                    title: info.node.name,
                    subtitle: info.node.children ? `(${info.node.children
                      .filter(category => !category.deleted)
                      .reduce((sum, category) => sum + (category.children ? category.children.filter(test => !test.deleted).length : 0), 0)} in ${info.node.children.filter(category => !category.deleted).length} categories)` : '(0)',
                    buttons: [
                      <button
                        className="btn btn-outline-success"
                        style={{
                          verticalAlign: 'middle',
                        }}
                        onClick={() => {
                          this.setState({
                            tests: addNodeUnderParent({
                              treeData: this.state.tests,
                              newNode: {
                                id: uuid(),
                                nodeType: 'category',
                                name: 'New Category',
                                image: '',
                                deviceType: info.node.deviceType
                              },
                              parentKey: info.node.id,
                              getNodeKey: ({ node }) => node.id,
                              expandParent: true
                            }).treeData
                          }, () => {
                            this.updateTests();
                          });
                        }}
                      >
                        +
                      </button>
                    ]
                  };
                }
              }}
              getNodeKey={({ node }) => node.id}
            />
          </div>
        </div>
        <div style={{ height: '100%', padding: '20px', flex: '45%', overflowY: 'scroll', overflowX: 'hidden', borderLeft: '1px solid #ddd' }}>
          {testCategory ? 
            <div style={{ height: '100%' }}>
              <Header as='h3'>{testCategory.name}</Header>
              <Form style={{ height: '100%' }}>
                <Form.Field
                  control={Input}
                  label='Name'
                  placeholder='Name'
                  value={testCategory.name}
                  onChange={(e, { value }) => {
                    this.setTestCategoryStateWithValue('name', value);
                    this.setTestCategoryStateWithValue('title', value);
                  }} 
                />

                <Form.Field
                  control={Input}
                  label='Icon'
                  placeholder='icon.png'
                  value={testCategory.image}
                  onChange={(e, { value }) => {
                    this.setTestCategoryStateWithValue('image', value);
                  }} 
                />
              </Form>
            </div> :
            ''
          }
          {test && test.nodeType ? 
            <div style={{ height: '100%' }}>
              <Header as='h3'>
                {test.name}
                <div style={{ fontSize: '0.7em', color: '#888888' }}>ID: {test.id}</div>
              </Header>
              
              <Form style={{ height: '100%' }}>
                <Header as='h4' dividing>Test Info</Header>

                <Form.Field
                  control={Input}
                  label='Name'
                  placeholder='Name'
                  value={test.name}
                  onChange={(e, { value }) => {
                    this.setTestStateWithValue('name', value);
                    this.setTestStateWithValue('title', value);
                  }} 
                />

                <Form.Field
                  control={Input}
                  label='Min Version'
                  placeholder='0.0.0'
                  value={test.minVersion}
                  onChange={(e, { value }) => {
                    this.setTestStateWithValue('minVersion', value);
                  }} 
                />

                <Form.Field
                  control={Input}
                  label='Max Version'
                  placeholder=''
                  value={test.maxVersion}
                  onChange={(e, { value }) => {
                    this.setTestStateWithValue('maxVersion', value.trim() != '' ? value : null);
                  }} 
                />

                <Form.Field
                  control={Input}
                  label='Show Upgrade Message From'
                  placeholder='0.0.0'
                  value={test.showUpgradeMessageFromVersion}
                  onChange={(e, { value }) => {
                    this.setTestStateWithValue('showUpgradeMessageFromVersion', value.trim() != '' ? value : null);
                  }} 
                />

                <Form.Field
                  label='Mode'
                  placeholder='Mode'
                  control={Select}
                  options={[
                    {
                      text: 'Single Device Single Side',
                      value: 'SINGLE_DEVICE_SINGLE_SIDE'
                    },
                    {
                      text: 'Single Device Both Side',
                      value: 'SINGLE_DEVICE_BOTH_SIDE'
                    },
                    {
                      text: 'Dual Device Both Side',
                      value: 'DUAL_DEVICE_BOTH_SIDE'
                    },
                    {
                      text: 'Dual Device Simultaneous',
                      value: 'DUAL_DEVICE_SIMULTANEOUS'
                    }
                  ]}
                  value={test.mode}
                  onChange={(e, { value }) => this.setTestStateWithValue('mode', value)} 
                />

                <Form.Group widths='equal'>
                  <Form.Field
                    label='Primary Device'
                    placeholder='Primary Device'
                    control={Select}
                    options={deviceOptions}
                    value={test.primaryDevice}
                    onChange={(e, { value }) => this.setTestStateWithValue('primaryDevice', value)} 
                  />
                  <Form.Field
                    label='Secondary Device'
                    placeholder='Secondary Device'
                    control={Select}
                    options={deviceOptions}
                    value={test.secondaryDevice}
                    onChange={(e, { value }) => {
                      this.setTestStateWithValue('secondaryDevice', value !== '' ? value : null)} 
                    }
                    
                  />
                </Form.Group>

                <Header as='h4' dividing>Parameters</Header>

                {testService.getTestTypeParametersDef().map((parameterDef) => {
                  if (parameterDef.type === 'bool') {
                    return (<Form.Field
                      label={parameterDef.displayName}
                      control={Checkbox}
                      checked={test.parameters[parameterDef.name] === true}
                      onChange={(e, { value }) => {
                        this.setTestParameterStateWithValue(parameterDef.name, !test.parameters[parameterDef.name]);
                      }} 
                    />);
                  } else if (parameterDef.type === 'int') {
                    return (<Form.Field
                      control={Input}
                      label={parameterDef.displayName}
                      placeholder={parameterDef.default}
                      value={test.parameters[parameterDef.name]}
                      onChange={(e, { value }) => this.setTestParameterStateWithValue(parameterDef.name, !isNaN(parseInt(value)) ? parseInt(value) : parameterDef.default)}
                    />);
                  } else if (parameterDef.type === 'select') {
                    return (<Form.Field 
                      control={Select}
                      label={parameterDef.displayName}
                      placeholder={parameterDef.displayName} 
                      options={parameterDef.options} 
                      value={test.parameters[parameterDef.name]}
                      onChange={(e, { value }) => this.setTestParameterStateWithValue(parameterDef.name, value != null ? value : parameterDef.default)}
                    />);
                  }
                  return null;
                })}

                <Header as='h4' dividing>
                  Metric Plots
                  {/* <Modal 
                    open={this.state.editMetricPlotsModalOpen}
                    trigger={
                      <Button 
                        size='mini'
                        icon
                        onClick={() => this.setState({
                          editMetricPlotsModalOpen: true
                        })}
                        style={{ padding: '0 5px', float: 'right', marginRight: 0 }}
                      >
                        <Icon name='edit' style={{ fontSize: '12px' }} />
                      </Button>
                    }
                    onClose={() => this.setState({
                      editMetricPlotsModalOpen: false
                    })}
                  >
                    <Modal.Header>Metric Plots</Modal.Header>
                    <Modal.Content>
                      <div style={{ height: '400px' }}>
                        <SortableTree
                          treeData={this.state.metricPlots}
                          onChange={treeData => this.setState({ metricPlots: treeData })} 
                          canDrag={false}
                          generateNodeProps={info => {
                            if (info.node.nodeType === 'category') {
                              return {
                                buttons: [
                                  <button
                                    className="btn btn-outline-success"
                                    style={{
                                      verticalAlign: 'middle',
                                    }}
                                    onClick={() => this.setState({ 
                                      currentMetricPlotCategoryInfo: info
                                    })}
                                  >
                                    ℹ
                                  </button>,
                                ],
                                title: info.node.title
                              }
                            } else if (info.node.nodeType === 'metricPlot') {
                              return {
                                buttons: [
                                  <button
                                    className="btn btn-outline-success"
                                    style={{
                                      verticalAlign: 'middle',
                                    }}
                                    onClick={() => this.setState({ 
                                      currentMetricPlotInfo: info
                                    })}
                                  >
                                    ℹ
                                  </button>,
                                ],
                                title: info.node.name
                              }
                            }
                          }}
                          getNodeKey={({ node }) => node.id}
                        />
                      </div>
                      <Modal.Actions>
                        <Button
                          onClick={() => {
                            this.setState({ editMetricPlotsModalOpen: false });
                          }}
                        >OK</Button>
                      </Modal.Actions>
                    </Modal.Content>
                  </Modal> */}
                </Header>

                <div style={{ height: '400px' }}>
                  <SortableTree
                    treeData={test.metricPlots}
                    onChange={treeData => this.setTestStateWithValue('metricPlots', treeData)} 
                    canDrag={drag => {
                      return drag.node.nodeType === 'category'
                    }}
                    canDrop={drop => {
                      if (drop.node.nodeType === 'category' && !drop.nextParent) {
                        return true;
                      }
                      return false;
                    }}
                    generateNodeProps={info => {
                      if (info.node.nodeType === 'category') {
                        return {
                          buttons: [
                            <Modal 
                              open={this.state.editTestMetricPlotsModalOpen}
                              trigger={
                                <button
                                  className="btn btn-outline-success"
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                  onClick={() => this.setState({ 
                                    currentTestMetricPlotCategoryInfo: info,
                                    editTestMetricPlotsModalOpen: true
                                  })}
                                >
                                  ℹ
                                </button>
                              }
                              onClose={() => this.setState({
                                editTestMetricPlotsModalOpen: false
                              })}
                            >
                              <Modal.Header>
                                {this.state.currentTestMetricPlotCategoryInfo ? 
                                  this.state.currentTestMetricPlotCategoryInfo.node.name : ''}
                              </Modal.Header>
                              <Modal.Content>
                                {this.state.currentTestMetricPlotCategoryInfo ? 
                                  <Form.Field
                                    key={this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId + 'displayName'}
                                    label='Display Name'
                                    placeholder='Display Name'
                                    control={Input}
                                    value={this.state.currentTestMetricPlotCategoryInfo.node.displayName}
                                    onChange={(e, { value }) => {
                                      this.setMetricPlotCategoryStateWithValue('displayName', value);
                                    }} 
                                  />
                                  : ''}
                                {this.state.currentTestMetricPlotCategoryInfo ? 
                                  testService.getMetricPlotCategoryById(this.state.currentTestMetricPlotCategoryInfo.node.id)
                                  .parametersDef.map((parameterDef) => {
                                  if (parameterDef.type === 'algorithm') {
                                    return (<Form.Field
                                      key={this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId + parameterDef.name}
                                      label={parameterDef.name}
                                      placeholder={parameterDef.name}
                                      control={Select}
                                      options={testService.getAlgorithms().map(algorithm => ({
                                        text: `${algorithm.name} (${algorithm.description})`,
                                        value: algorithm.id
                                      }))}
                                      value={this.state.currentTestMetricPlotCategoryInfo.node.parameters ? this.state.currentTestMetricPlotCategoryInfo.node.parameters[parameterDef.name] : parameterDef.default}
                                      onChange={(e, { value }) => {
                                        this.setMetricPlotCategoryParameterStateWithValue(parameterDef.name, value);
                                      }} 
                                    />);
                                  } else if (parameterDef.type === 'int') {
                                    return (<Form.Field
                                      key={this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId + parameterDef.name}
                                      label={parameterDef.name}
                                      placeholder={parameterDef.name}
                                      control={Input}
                                      value={this.state.currentTestMetricPlotCategoryInfo.node.parameters && this.state.currentTestMetricPlotCategoryInfo.node.parameters.hasOwnProperty(parameterDef.name) ? this.state.currentTestMetricPlotCategoryInfo.node.parameters[parameterDef.name] : parameterDef.default}
                                      onChange={(e, { value }) => {
                                        this.setMetricPlotCategoryParameterStateWithValue(parameterDef.name, parseInt(value));
                                      }} 
                                    />);
                                  }
                                  return '';
                                }) : ''}
                                {this.state.currentTestMetricPlotCategoryInfo ? 
                                  testService.getAlgorithmById(this.state.currentTestMetricPlotCategoryInfo.node.parameters.algorithm)
                                  .parametersDef.map(parameterDef => {

                                    console.log("!!!", test.parameters)
                                    if (parameterDef.type === 'int') {
                                      return (<Form.Field
                                        key={this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId + parameterDef.name}
                                        label={parameterDef.name}
                                        placeholder={parameterDef.name}
                                        control={Input}
                                        value={this.state.currentTestMetricPlotCategoryInfo.node.parameters && this.state.currentTestMetricPlotCategoryInfo.node.parameters.hasOwnProperty(parameterDef.name) ? this.state.currentTestMetricPlotCategoryInfo.node.parameters[parameterDef.name] : parameterDef.default}
                                        onChange={(e, { value }) => {
                                          this.setMetricPlotCategoryParameterStateWithValue(parameterDef.name, parseInt(value));
                                        }} 
                                      />);
                                    } else if (parameterDef.type === 'bool') {
                                      return (<Form.Field
                                        toggle
                                        key={this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId + parameterDef.name}
                                        label={parameterDef.name}
                                        placeholder={parameterDef.name}
                                        control={Radio}
                                        checked={this.state.currentTestMetricPlotCategoryInfo.node.parameters && this.state.currentTestMetricPlotCategoryInfo.node.parameters.hasOwnProperty(parameterDef.name) ? this.state.currentTestMetricPlotCategoryInfo.node.parameters[parameterDef.name] : parameterDef.default}
                                        onChange={(e) => {
                                          this.setMetricPlotCategoryParameterStateWithValue(parameterDef.name, !this.state.currentTestMetricPlotCategoryInfo.node.parameters[parameterDef.name]);
                                        }} 
                                      />);
                                    } else if (parameterDef.type === 'select') {
                                      return (<Form.Field 
                                        control={Select}
                                        label={parameterDef.displayName}
                                        placeholder={parameterDef.displayName} 
                                        options={parameterDef.options} 
                                        value={this.state.currentTestMetricPlotCategoryInfo.node.parameters && this.state.currentTestMetricPlotCategoryInfo.node.parameters.hasOwnProperty(parameterDef.name) ? this.state.currentTestMetricPlotCategoryInfo.node.parameters[parameterDef.name] : parameterDef.default}
                                        onChange={(e, { value }) => this.setMetricPlotCategoryParameterStateWithValue(parameterDef.name, value != null ? value : parameterDef.default)}
                                      />);
                                    }
                                    return ''
                                  }) : ''}
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  onClick={() => {
                                    this.setState({ editTestMetricPlotsModalOpen: false });
                                  }}
                                >OK</Button>
                              </Modal.Actions>
                            </Modal>,
                            <Modal 
                              open={this.state.deleteTestMetricPlotsModalOpen}
                              trigger={
                                <button
                                  className="btn btn-outline-success"
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                  onClick={() => this.setState({ 
                                    currentTestMetricPlotCategoryInfo: info,
                                    deleteTestMetricPlotsModalOpen: true
                                  })}
                                >
                                  x
                                </button>
                              }
                              onClose={() => this.setState({
                                deleteTestMetricPlotsModalOpen: false
                              })}
                            >
                              <Modal.Header>
                                {this.state.currentTestMetricPlotCategoryInfo ? this.state.currentTestMetricPlotCategoryInfo.node.name : ''}
                              </Modal.Header>
                              <Modal.Content>
                                Do you want to delete {this.state.currentTestMetricPlotCategoryInfo ? this.state.currentTestMetricPlotCategoryInfo.node.name : ''}?
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  onClick={() => {
                                    this.setState({ deleteTestMetricPlotsModalOpen: false });
                                    this.setTestStateWithValue('metricPlots', this.state.currentTestInfo.node.metricPlots.filter(metricPlot => metricPlot.testMetricPlotId !== this.state.currentTestMetricPlotCategoryInfo.node.testMetricPlotId));
                                  }}
                                >Delete</Button>
                                <Button
                                  onClick={() => {
                                    this.setState({ deleteTestMetricPlotsModalOpen: false });
                                  }}
                                >Cancel</Button>
                              </Modal.Actions>
                            </Modal>
                          ],
                          title: info.node.displayName ? info.node.displayName : info.node.name,
                          subtitle: info.node.title + ', ' + 
                              this.getAlgorithmNameOfMetricPlot(info.node) + ' ' +
                              '(v' + info.node.minVersion + (info.node.maxVersion ? ('-v' + info.node.maxVersion) : '+') + 
                              (info.node.showUpgradeMessageFromVersion ? (', upgrd msg from v' + info.node.showUpgradeMessageFromVersion) : '') + ')'
                        }
                      } else if (info.node.nodeType === 'metricPlot') {
                        return {
                          title: info.node.name
                        }
                      }
                    }}
                  />
                </div>

                <Form.Field
                  placeholder='Add Metric Plots Group'
                  control={Select}
                  value={this.state.currentAddingMetricPlotGroup}
                  options={this.state.metricPlots.map(metricPlotCategory => {
                    return {
                      text: metricPlotCategory.title,
                      value: JSON.stringify(metricPlotCategory)
                    };
                  })}
                  onChange={(e, { value }) => this.setState({
                    currentAddingMetricPlotGroup: value
                  })} 
                />
                <Button
                  onClick={() => {
                    if (this.state.currentAddingMetricPlotGroup == null) return;
                    var metricPlotGroup = JSON.parse(this.state.currentAddingMetricPlotGroup);
                    metricPlotGroup.testMetricPlotId = uuid();
                    metricPlotGroup.parameters = {};
                    metricPlotGroup.parametersDef.forEach(def => {
                      metricPlotGroup.parameters[def.name] = def.default;
                    });
                    metricPlotGroup.expanded = false;

                    this.setTestStateWithValue('metricPlots', [
                      ...test.metricPlots,
                      metricPlotGroup
                    ]);
                    this.setState({
                      currentAddingMetricPlotGroup: null
                    });
                  }}
                >Add</Button>
              </Form>
            </div> : ''}
            {!test && !testCategory ? 'Please select a test / category on the left hand side.' : ''}
        </div>
      </div>
    );
  }
}
