const Messages = {
  'no-responce-from-server': 'No response from the server.',
  'delete-device': 'Are you sure you want to delete this device?',
  'delete-location': 'Are you sure you want to delete this location?',
  'delete-practitioner': 'Do you want to delete this practitioner #FIRSTNAME#?',
  'restore-practitioner': 'Do you want to restore this practitioner #FIRSTNAME#?',
  'invitation-sent': 'Invitation email has been sent to #EMAIL#',
  'role-practitioner': "ROLE_PRACTITIONER",
  'role-admin-practitioner': "ROLE_ADMIN_PRACTITIONER",
  'role-admin': "ROLE_ADMIN",
  'role-customer': "ROLE_CUSTOMER",
  'email-sent': 'Email has been sent to #EMAIL#',
  'password-change': 'Your password has successfully changed',
};

export default Messages;
