import uuid from "uuid";

// import uuid from 'uuid/v4';

const localStorageKeyTests = 'axit_test_editor_tests';

const localStorageKeyTestsVersion = 'axit_test_editor_tests_version';

const localStorageKeyMetricPlots = 'axit_test_editor_metric_plots';

const localStorageKeyPlots = 'axit_test_editor_plots';

const localStorageKeyAlgorithms = 'axit_test_editor_algorithms';

function getMetricPlots() {
  return localStorage.getItem(localStorageKeyMetricPlots) ? JSON.parse(localStorage.getItem(localStorageKeyMetricPlots)) : [];
}

function getAlgorithms() {
  return localStorage.getItem(localStorageKeyAlgorithms) ? JSON.parse(localStorage.getItem(localStorageKeyAlgorithms)) : [];
}

function getMetricPlotCategoryById(id) {
  return getMetricPlots().filter((metricPlotCategory) => {
    return metricPlotCategory.id === id;
  })[0];
}

function getTests() {
  return localStorage.getItem(localStorageKeyTests) ? JSON.parse(localStorage.getItem(localStorageKeyTests)) : [];
}


function getTestTypeParametersDef() {
  return [{
      type: 'bool',
      name: 'adaptive_contact_points',
      displayName: 'Adaptive Contact Points (deprecated 2.7.0)',
      default: false
    }, 
    {
      type: 'select',
      name: 'contact_point_extractor',
      displayName: 'Contact Point Extractor',
      default: 'NaiveContactPointsExtractorImpl',
      options: [
        { key: 'NaiveContactPointsExtractorImpl', value: 'NaiveContactPointsExtractorImpl', text: 'NaiveContactPointsExtractorImpl' },
        { key: 'AdaptiveContactPointExtractorImpl', value: 'AdaptiveContactPointExtractorImpl', text: 'AdaptiveContactPointExtractorImpl' },
        { key: 'SumThenIndividualContactPointExtractorImpl', value: 'SumThenIndividualContactPointExtractorImpl', text: 'SumThenIndividualContactPointExtractorImpl' }
      ]
    },
    {
      type: 'int',
      name: 'contact_points_window',
      displayName: 'Contact Points Window',
      default: 40
    }, {
      type: 'bool',
      name: 'overload_warning',
      displayName: 'Overload Warning',
      default: false
    }];
} 

function getAlgorithmById(id) {
  return getAlgorithms().filter(algorithm => algorithm.id === id)[0];
}

export default {
  getVersion: function() {
    return localStorage.getItem(localStorageKeyTestsVersion) ? localStorage.getItem(localStorageKeyTestsVersion) : '1';
  },
  getTests: getTests,
  getTestTypeById: function(id) {
    var tests = getTests();
    for (var i = 0; i < tests.length; i++) {
      var device = tests[i];
      for (var j = 0; j < device.children.length; j++) {
        var category = device.children[j];
        for (var k = 0; k < category.children.length; k++) {
          var test = category.children[k];
          if (test.id === id) return test;
        }
      }
    }
    return null;
  },
  getTestTypeParametersDef: getTestTypeParametersDef,
  getTestsForExport: function() {
    return getTests();
  },
  getMetricPlotCategoryById: getMetricPlotCategoryById,
  getMetricPlots: getMetricPlots,
  getPlots: function() {
    return localStorage.getItem(localStorageKeyPlots) ? JSON.parse(localStorage.getItem(localStorageKeyPlots)) : [];
  },
  getAlgorithms: getAlgorithms,
  getAlgorithmById: getAlgorithmById,
  updateVersion: function(version) {
    localStorage.setItem(localStorageKeyTestsVersion, version);
  },
  updateTests: function(tests) {
    var testTypeParametersDef = getTestTypeParametersDef();

    var newTests = JSON.parse(JSON.stringify(tests));
    for (var i = 0; i < newTests.length; i++) {
      var device = newTests[i];
      for (var j = 0; j < device.children.length; j++) {
        var category = device.children[j];
        category.sortOrder = j + 1;
        category.deleted = category.deleted ? true : false;
        if (category.children) {
          for (var k = 0; k < category.children.length; k++) {
            var test = category.children[k];
            test.sortOrder = k + 1;
            test.deleted = test.deleted ? true : false;
            if (!test.parameters) {
              test.parameters = {};
            }
            if (!test.minVersion) {
              test.minVersion = '0.0.0';
            }
            testTypeParametersDef.map((testTypeParameterDef) => {
              if (!test.parameters.hasOwnProperty(testTypeParameterDef.name)) {
                test.parameters[testTypeParameterDef.name] = testTypeParameterDef.default;
              }
              return testTypeParameterDef;
            });

            if (test.metricPlots) {
              for (var l = 0; l < test.metricPlots.length; l++) {
                var metricPlot = test.metricPlots[l];
                var metricPlotCategory = {
                  id: metricPlot.id,
                  testMetricPlotId: metricPlot.testMetricPlotId ? metricPlot.testMetricPlotId : uuid(),
                  parameters: metricPlot.parameters,
                  sortOrder: l + 1,
                  displayName: metricPlot.displayName ? metricPlot.displayName : null
                };

                if (!metricPlotCategory.parameters) {
                  metricPlotCategory.parameters = {};
                }

                getMetricPlotCategoryById(metricPlotCategory.id).parametersDef.map(parameterDef => {
                  if (!metricPlotCategory.parameters.hasOwnProperty(parameterDef.name)) {
                    metricPlotCategory.parameters[parameterDef.name] = parameterDef.default;
                  }
                  return parameterDef;
                })
                getAlgorithmById(metricPlotCategory.parameters.algorithm).parametersDef.map(parameterDef => {
                  if (!metricPlotCategory.parameters.hasOwnProperty(parameterDef.name)) {
                    metricPlotCategory.parameters[parameterDef.name] = parameterDef.default;
                  }
                  return parameterDef;
                })
                for (var name in metricPlotCategory.parameters) {
                  if (metricPlotCategory.parameters.hasOwnProperty(name)) {
                    if (getMetricPlotCategoryById(metricPlotCategory.id).parametersDef
                      .filter(parameterDef => parameterDef.name === name).length === 0 &&
                      getAlgorithmById(metricPlotCategory.parameters.algorithm).parametersDef
                      .filter(parameterDef => parameterDef.name === name).length === 0) {
    
                      delete metricPlotCategory.parameters[name];
                    }
                  }
                }

                test.metricPlots[l] = metricPlotCategory;
              }
            }
          }
        }
      }
    }
    localStorage.setItem(localStorageKeyTests, JSON.stringify(newTests));
  },
  updateMetricPlots: function(metricPlots) {
    localStorage.setItem(localStorageKeyMetricPlots, JSON.stringify(metricPlots));
  },
  updatePlots: function(plots) {
    localStorage.setItem(localStorageKeyPlots, JSON.stringify(plots));
  },
  updateAlgorithms: function(algorithms) {
    localStorage.setItem(localStorageKeyAlgorithms, JSON.stringify(algorithms));
  }
}