import React, { Component } from 'react';
import { Link, Prompt } from 'react-router-dom';

import {
  Breadcrumb,
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  Grid,
  Header,
  Input,
  Message,
  Segment,
  Table,
  Image,
} from 'semantic-ui-react';

import customerService from '../../services/customer';
import deviceService from '../../services/device';

import Messages from '../../config/messages';
import { convertResponseError } from '../../helpers/util';
import URLS, { URL_PREFIX } from '../../services/URLS';

class CustomerDevices extends Component {
  state = {
    isLoading: false,

    isSbnCustomer: false,
    isPairedRow: false,

    isOpenResetConfirmation: false,
    isOpenLocalStorageConfirmation: false,
    shouldBlockNavigation: false,

    type: null,
    deviceMAC: null,

    devices: [],
    customer: {},
    selectedDeviceData: null,
    device: { ...customerService.DEFAULT_ROW },
    searchDevices: [], // Devices Dropdown to assign Device
    usedMacDevices: [], // Used Devices

    validationErrors: {},
    remoteError: null,

    customerId: '',
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.hamdleWindowUnload, false);

    const {
      match: {
        params: { customerId }
      }
    } = this.props;

    this.setState({ customerId }, this.getCustomer);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.hamdleWindowUnload);
  }

  /**
   * Handle Close Window Event or Change Route Event
   *
   * @param {Event} evt 
   */
  hamdleWindowUnload = evt => {
    const { shouldBlockNavigation } = this.state;

    if (shouldBlockNavigation) {
      evt.preventDefault();
      evt.returnValue = '';
    }
  }

  /**
   * Get Customer > By Id
   */
  getCustomer = () => {
    const { customerId } = this.state;

    this.setState({ isLoading: true });

    customerService.getCustomerById(customerId)
      .then(response => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages['no-responce-from-server'] },
            isLoading: false,
          });
          return;
        }

        const { user: { email } = {} } = response.data;
        const isSbnCustomer = customerService.isSbnCustomer(email);

        this.setState({
          customer: response.data,
          isSbnCustomer,
          isLoading: false,
        }, this.checkDataFromLocalStorage);
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isLoading: false,
          remoteError: convertResponseError(error)
        });
      });
  }

  /**
   * Add last blank row of every Plate Device
   * 
   * @param {Array} devices 
   * 
   * @return {Array} 
   */
  addLastBlankRowAtEndOfEveryPlateDevice = devices => {
    const groupDevices = devices.filter(({ devices }) => devices);

    groupDevices.forEach(({ devices }) => {
      const index = devices.findIndex(({ isForLastRow }) => isForLastRow);

      if (index === -1) {
        devices.push({ ...customerService.DEFAULT_CHILD_ROW });
      }
    })

    return devices;
  }

  /**
   * Fetch Customer Devices by Customer Id 
   *
   * @param {Boolean} isLoading 
   */
  fetchCustomerDevices = (isLoading = false) => {
    const { customerId } = this.state;

    this.setState({ isLoading });

    customerService.getCustomerDevicesById(customerId)
      .then(response => {

        if (!response) {
          this.setState({
            remoteError: { message: Messages['no-responce-from-server'] },
            isLoading: false
          });
          return;
        }

        let devices = this.sortDevices(response.data);

        devices = this.addLastBlankRowAtEndOfEveryPlateDevice(devices);
        devices.push({ ...customerService.DEFAULT_ROW });

        this.setState({ devices, isLoading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isLoading: false,
          remoteError: convertResponseError(error)
        });
      });
  }

  /**
   * Get devices list to show in dropdown to assign
   */
  getDeviceOptions = ({ index, pairIndex = -1 }) => {
    const { devices } = this.state;

    const searchDevices =
      pairIndex > -1 ?
        devices[index].devices[pairIndex].searchDevices || [] :
        devices[index].searchDevices || [];

    const options = searchDevices.map(device => {
      return {
        text: device.macAddress || '',
        value: device.macAddress || '',
      };
    });

    return options;
  }

  /**
   * On Device Selection
   *   
   * @param {Object} selectedDevice 
   */
  onDeviceSelection = ({ device: selectedDevice, index, pairIndex = -1 }) => (evt, { value }) => {
    const { customerId, validationErrors, devices } = this.state;
    const { searchDevices = [] } = selectedDevice;

    const selectedDeviceObj = searchDevices.find(({ macAddress }) => macAddress === value) || {};

    const {
      comment = '',
      id,
      loan,
      macAddress,
      type,
      usages,
    } = selectedDeviceObj;

    const device = {
      id,
      type,
      macAddress,
      loan: Boolean(loan),
      comment,
    }

    delete selectedDevice.error;

    if (selectedDevice.key === customerService.DEVICE_KEYS.plate) {
      const getPlateType = () => {
        if (selectedDevice.name.includes('Left')) {
          return customerService.DEVICE_KEYS.left;
        }

        return customerService.DEVICE_KEYS.right;
      }

      selectedDevice.key = `${selectedDevice.key}_${getPlateType()}`;
    }

    const { inUse = true, name = '' } = (usages.length > 0 && usages[0]) || [];

    const { push, pull, plate } = customerService.DEVICE_KEYS;

    const getDeviveType = () => {
      if ([push, pull, plate].indexOf(type) > -1 || selectedDevice.isChildPairedRow) {
        return customerService.DEVICE_KEYS.device;
      }

      return customerService.DEVICE_KEYS.deviceGroup;
    }

    const updatedSelectedDevice = {
      ...selectedDevice,
      '@type': getDeviveType(),
      'customer': { id: customerId },
      'name': selectedDevice.name || name,
      inUse,
      device,
      tablets: [],
      assignType: selectedDevice.key || '',
    }

    if (pairIndex > -1) {
      devices[index].devices[pairIndex] = { ...updatedSelectedDevice };
    } else {
      devices[index] = { ...updatedSelectedDevice };
    }

    delete validationErrors.deviceMAC;

    this.setState({
      deviceMAC: macAddress,
      validationErrors,
      devices,
    }, this.saveDataToLocalStorage);
  }

  /**
   * Append New Row
   * 
   * @param {Object} device 
   * @param {Number} i 
   * @param {Number} j 
   */
  appendNewRow = ({ index, pairIndex = -1, device }) => async () => {
    const { devices } = this.state;

    const devicesCopy = [...devices];

    const fields = {
      type: device.key,
      deviceName: device.name,
      deviceMAC: !device.isPairedRow ? this.state.deviceMAC || (device.device && device.device.macAddress) : 'true'
    };

    const isValidDevice = await customerService.customerDeviceSchema.isValid(fields);

    if (!isValidDevice) {
      this.validateAll(fields);

      this.setState({ validationErrors: { index, pairIndex } })
      return;
    }

    this.setState({ type: '', deviceName: '', deviceMAC: '' });

    const handleAdditionalRow = () => {
      device.isForLastRow = false;

      if (device.type === customerService.DEVICE_KEYS.platePair && devicesCopy[index].devices) {
        devicesCopy[index].devices.push({ ...customerService.DEFAULT_CHILD_ROW });

        return devicesCopy;
      }

      if (pairIndex > -1) {
        devicesCopy[index].devices[pairIndex] = device;
        devicesCopy[index].devices[pairIndex + 1] = { ...customerService.DEFAULT_CHILD_ROW };

        this.handlePairInUse({ index });

        return devicesCopy;
      }

      if (pairIndex === -1) {
        devicesCopy[index] = device;
        return devicesCopy;
      }
    }

    const sortedDevices = this.sortDevices(handleAdditionalRow());

    sortedDevices.push({ ...customerService.DEFAULT_ROW });

    this.resetFieldDataAndSaveToLocalStorage(sortedDevices, false);
  }

  /**
  * Validate All Fields
  *
  * @param {Object} fields 
  */
  validateAll(fields) {
    for (const field of Object.keys(fields)) {
      this.validateOne(field, fields);
    }
  }

  /**
   * Validate Single Field  
   *
   * @param {*} field 
   * @param {*} fields 
   */
  validateOne(field, fields) {
    customerService.customerDeviceSchema.validateAt(field, fields)
      .catch(error => {
        console.error(error);
        this.setState(state => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0]
          }
        }));
      });
  }

  /**
   * Reset FieldData
   * 
   * @param {Array} devices 
   * @param {Object} device 
   */
  resetFieldDataAndSaveToLocalStorage = devices => {
    this.setState({
      devices,
      searchDevices: [],
      type: '',
      deviceName: '',
      deviceMAC: ''
    }, this.saveDataToLocalStorage);
  }


  /**
   * Reset data and fetch devices
   */
  resetData = () => {
    const { customerId } = this.state;

    this.setState({
      searchDevices: [],
      selectedDeviceData: null,
      validationErrors: {},
      remoteError: null,
      shouldBlockNavigation: false,
      deviceMAC: null,
      type: null,
      devices: [],
      isPairedRow: false,
    },
      () => {
        this.closeResetConfirmationModal();
        this.fetchCustomerDevices(true);
        customerService.clearDataFromLocalStorage(customerId);
      })
  }

  /**
   * Close reset confimation modal
   */
  closeResetConfirmationModal = () => {
    this.setState({ isOpenResetConfirmation: false })
  }

  /**
   * Open reset confimation modal
   */
  openResetConfirmationModal = () => {
    this.setState({ isOpenResetConfirmation: true })
  }

  /**
   * Save Data to Local Storage
   */
  saveDataToLocalStorage = () => {
    const { devices, customerId, shouldBlockNavigation } = this.state;

    if (!shouldBlockNavigation) {
      this.setState({ shouldBlockNavigation: true })
    }

    customerService.saveDataToLocalStorage(devices, customerId);
  }

  /**
   * Check Data From LocalStorage
   */
  checkDataFromLocalStorage = () => {
    const { customerId } = this.state;

    const devices = customerService.getDataFromLocalStorage(customerId) || [];

    if (devices.length > 0) {
      this.setState({ isOpenLocalStorageConfirmation: true });

      return;
    }

    this.fetchCustomerDevices(true);
  }

  /**
   * Restore data from Local Storage
   */
  restoreData = () => {
    const { customerId } = this.state;

    const devices = customerService.getDataFromLocalStorage(customerId) || [];

    this.setState({ devices: devices, isOpenLocalStorageConfirmation: false });
  }

  /**
   * Close Confimation modal
   */
  closeLocalStorageConfimation = () => {
    const { customerId } = this.state;

    this.setState(
      {
        isOpenLocalStorageConfirmation: false,
        devices: []
      },
      () => {
        customerService.clearDataFromLocalStorage(customerId);
        this.fetchCustomerDevices(true);
      }
    );
  }

  /**
  * Get Mac Address 
  *
  * @param {Object} device 
  */
  getMacAddress = device => {
    if (!device || !device.macAddress) return '';

    return (
      <Link
        target='_blank'
        to={`/devices?macAddress=${device.macAddress}`}
      >
        {device.macAddress}
      </Link>
    );
  }

  /**
   * Handle Pair `InUse`
   * 
   * @param {Object} 
   */
  handlePairInUse = ({ index }) => {
    const { devices } = this.state;

    let isAnyDeviceInUse = false;

    devices[index].devices.forEach(device => {
      if (device.inUse) isAnyDeviceInUse = true;
    });

    devices[index].inUse = isAnyDeviceInUse;

    this.setState({ devices });
  }

  /**
   * Handle `In Use` Checkbox
   * 
   * @param {Object} param 
   */
  handleInUseCheckbox = ({ index, pairIndex = -1 }) => () => {
    try {
      const { devices } = this.state;

      if (pairIndex > -1) {

        devices[index].devices[pairIndex].inUse = !devices[index].devices[pairIndex].inUse;

        this.handlePairInUse({ index });

      } else {
        devices[index].inUse = !devices[index].inUse;
      }

      this.setState({ devices }, this.saveDataToLocalStorage);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Delete Device
   * 
   * @param {Object} 
   */
  deleteDevice = ({ index, pairIndex = -1 }) => () => {
    const { devices, usedMacDevices } = this.state;

    const defaultRow = { ...customerService.DEFAULT_ROW };

    /**
     * Delete Device From Used Devices
     */
    const deleteDeviceFromUsedDevices = () => {
      let usedDeviceIndex = -1;

      if (devices[index] && devices[index].device && devices[index].device.macAddress) {
        usedDeviceIndex =
          usedMacDevices.findIndex(
            macAddress =>
              macAddress === devices[index].device.macAddress
          );
      }

      if (pairIndex > -1 && devices[index].devices[pairIndex].device.macAddress) {
        usedDeviceIndex =
          usedMacDevices.findIndex(
            macAddress =>
              macAddress === devices[index].devices[pairIndex].device.macAddress
          );
      }

      if (usedDeviceIndex > -1) usedMacDevices.splice(usedDeviceIndex, 1);
    }

    /**
     * Handle Deleted Row
     */
    const handleDeletedRow = () => {
      deleteDeviceFromUsedDevices();

      if (pairIndex > -1) {
        devices[index].devices.splice(pairIndex, 1);

        if (devices[index].devices.length === 0) {
          defaultRow.isChildPairedRow = true;
          devices[index].devices[0] = defaultRow;
        }

        this.handlePairInUse({ index });

        return devices;
      }

      devices.splice(index, 1);

      if (
        devices.length === 0 ||
        devices[devices.length - 1].type === customerService.DEVICE_KEYS.platePair
      ) {

        devices.push({ ...customerService.DEFAULT_ROW });
      }

      return devices;
    }

    this.setState({ devices: handleDeletedRow() }, this.saveDataToLocalStorage);
  }

  /**
   * Handle > Change Device Name
   * 
   * @param {Object} selectedDevice 
   */
  handleDeviceNameChange = selectedDevice => (e, { value }) => {
    const { devices, validationErrors } = this.state;

    selectedDevice.name = value;

    delete selectedDevice.error;

    if (selectedDevice.name) delete validationErrors.deviceName;

    this.setState({ devices, validationErrors });
  }

  /**
   * Trim Name
   * 
   * @param {Onject} val 
   */
  trimName = ({ index, pairIndex = -1 }) => () => {
    try {
      const { devices } = this.state;

      if (pairIndex > -1) {
        devices[index].devices[pairIndex].name = devices[index].devices[pairIndex].name.trim();
      } else {
        devices[index].name = devices[index].name.trim();
      }

      this.setState({ devices }, this.saveDataToLocalStorage);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Handle default name
   *
   * @param {String} typeName 
   * @param {Number} n [nth count after name]
   * 
   * @return  {String} 
   */
  handleDefaultName = (typeName, n = 1) => {
    const { devices: allDevices } = this.state;

    const index = allDevices.findIndex(({ name, devices }) => {
      if (name === `${typeName} ${n}`) return true;

      if (devices && devices.length > 0) {
        return devices.some(val => val.name === `${typeName} ${n}`);
      }

      return false;
    });

    if (index === -1) return `${typeName} ${n}`;

    return this.handleDefaultName(typeName, ++n);
  }

  /**
   * Sort Devices
   * 
   * @param {Array} devices 
   * 
   * @return {Array} 
   */
  sortDevices = devices => {
    const { usedMacDevices = [] } = this.state;
    const { DEVICE_KEYS } = customerService;

    const handleUsedMacDevices = (usedDevices) => {
      usedDevices.forEach(({ device = {} }) => {
        if (device.macAddress) usedMacDevices.push(device.macAddress);
      });
    }

    const sortPlateDevices = () => {
      const sortedPlatesDevices = devices.filter(({ type }) => type === DEVICE_KEYS.platePair);

      sortedPlatesDevices.map(({ devices = [] }, i) => {

        handleUsedMacDevices(sortedPlatesDevices[i].devices);

        return devices.sort((a, b) => a.assignType && b.assignType && a.assignType < b.assignType ? -1 : 0);
      })

      return sortedPlatesDevices;
    }

    if (devices.length === 1) {
      const sortedDevices = [
        ...devices.filter(({ assignType }) => assignType === DEVICE_KEYS.push),
        ...sortPlateDevices(),
        ...devices.filter(({ assignType }) => assignType === DEVICE_KEYS.pullLeft),
        ...devices.filter(({ assignType }) => assignType === DEVICE_KEYS.pullRight)
      ]

      handleUsedMacDevices(sortedDevices);

      return sortedDevices;
    }

    if (devices.length > 1) {
      const sortedDevices = [
        ...devices.filter(({ assignType, isForLastRow }) => assignType === DEVICE_KEYS.push && !isForLastRow),
        ...sortPlateDevices(),
        ...devices.filter(({ assignType, isForLastRow }) => assignType === DEVICE_KEYS.pullLeft && !isForLastRow),
        ...devices.filter(({ assignType, isForLastRow }) => assignType === DEVICE_KEYS.pullRight && !isForLastRow)
      ];

      handleUsedMacDevices(sortedDevices);

      return sortedDevices;
    }

    return [];
  }

  /**
   * Handle > On change input value
   *
   * @param {Event} evt 
   * @param {Object}
   */
  onChangeInput = ({ index, device, pairIndex = -1 }) => (e, { name, value }) => {
    const { devices, validationErrors, customerId } = this.state;

    let deviceName = e.target.textContent;

    this.setState({
      [name]: value || index,
      deviceName: value || index,
      searchDevices: [],
      deviceMAC: null
    });

    delete validationErrors.type;
    delete validationErrors.deviceName;
    delete validationErrors.deviceMAC;
    delete device.searchDevices;

    /**
     * Handle empty type seelection
     */
    const handleEmptyTypeSelection = () => {
      const deviceFields = {
        'name': '',
        'asType': '',
        'isFetchingAssignDevices': false,
        'error': {},
        'key': '',
      }

      if (deviceName !== '-') {
        deviceFields.name = this.handleDefaultName(deviceName);
        deviceFields.key = pairIndex > -1 ? customerService.DEVICE_KEYS.plate : value;
        deviceFields.asType = device['@type'];
        deviceFields.isFetchingAssignDevices = true;
        deviceFields.error = {};
      }

      return deviceFields;
    }

    const {
      key,
      name: devName,
      error,
      isFetchingAssignDevices,
      asType
    } = handleEmptyTypeSelection();

    device.isPairedRow = false;
    device['@type'] = '';
    device.customer = {};
    device.type = '';

    delete device.devices;
    delete device.assignType;
    delete device.device;

    if (value === customerService.DEVICE_KEYS.pair) {
      device.isPairedRow = true;
      device['@type'] = customerService.DEVICE_KEYS.deviceGroup;
      device.customer = { id: customerId };
      device.type = customerService.DEVICE_KEYS.platePair;

      device.key = key;
      device.name = devName;

      device.isFetchingAssignDevices = false;
      device.error = error;

      device.devices = [];
    }

    if (pairIndex === -1 && value !== customerService.DEVICE_KEYS.pair) {
      device.key = key;
      device.name = devName;
      device['@type'] = asType;
      device.isFetchingAssignDevices = isFetchingAssignDevices;
      device.error = error;

      this.fetchDevicesToAssign({ index, device, pairIndex });
    }

    if (pairIndex > -1) {
      device.key = key;
      device.subKey = value;
      device.assignType = value ? `${device.key}_${value}` : '';
      device.name = devName;
      device['@type'] = asType;
      device.isFetchingAssignDevices = isFetchingAssignDevices;;
      device.error = error;

      this.fetchDevicesToAssign({ index, device, pairIndex });
    }

    this.setState({ devices, validationErrors }, this.saveDataToLocalStorage);
  }

  /**
   * Fetch Device to Assign 
   *
   * @param {Object} device 
   */
  fetchDevicesToAssign = ({ index, device, pairIndex = -1 }) => {
    if (device.key === customerService.DEVICE_KEYS.pair || !device.key) return;

    const { isSbnCustomer, devices, usedMacDevices } = this.state;

    const getDeviceType = () => {
      if (device.key.includes('_')) {
        return device.key.split('_')[0];
      }

      return device.key;
    }

    const params = {
      q: '',
      types: getDeviceType(),
      size: 100
    }

    deviceService.getDevices('/search/devices', params)
      .then(response => {

        delete device.isFetchingAssignDevices;

        if (!response || !response.data) return;

        let searchDevices = response.data.content.filter(
          assignDevice =>
            !usedMacDevices.includes(assignDevice.macAddress)
        );

        if (!isSbnCustomer) {
          searchDevices = searchDevices.filter(({ status }) => status !== "ASSIGNED");
        }

        if (pairIndex > -1) {
          devices[index].devices[pairIndex].searchDevices = searchDevices;
        } else {
          devices[index].searchDevices = searchDevices;
        }

        this.setState({ devices }, this.saveDataToLocalStorage);
      })
      .catch(error => {
        console.error(error);
        delete device.isFetchingAssignDevices;
      });
  }

  /**
   * Remove Extra Row Before Submit
   *
   * @return {Array}
   */
  removeExtraRowBeforeSubmit = () => {
    const { devices } = this.state;

    const mappedDevices = devices
      .map(device => {
        if (
          (device['@type'] && device.assignType) ||
          device.key === customerService.DEVICE_KEYS.pair ||
          device.type === customerService.DEVICE_KEYS.platePair
        ) {

          if (
            (device['@type'] === customerService.DEVICE_KEYS.deviceGroup) &&
            device.devices.length > 0
          ) {
            const pairedDevice = device.devices.filter(item => {
              return item['@type'] && item.assignType;
            });

            device.devices = pairedDevice;
          }

          return device;
        };

        return null;
      })
      .filter(Boolean);

    if (mappedDevices.length === 0) return [];

    return mappedDevices;
  }

  /**
   * Validate All Fields
   */
  validateAllFields = async () => {
    const { devices } = this.state;

    const mappedDevices = devices
      .map(device => {
        if (device.devices && device.devices.length > 0) {
          device.devices.forEach(item => {
            if (
              !item.name &&
              !item.key &&
              (
                !item.device ||
                !item.device.macAddress
              )
            ) return;

            item.error = {
              name: !Boolean(item.name),
              key: !Boolean(item.key),
              macAddress: item.device ? !Boolean(item.device.macAddress) : true,
            };
          })

          device.error = {
            name: !Boolean(device.name),
          };

        } else {
          if (
            !device.name &&
            !device.key &&
            (
              !device.device ||
              !device.device.macAddress
            )
          ) return device;

          device.error = {
            name: !Boolean(device.name),
            key: !Boolean(device.key),
            macAddress: device.device ? !Boolean(device.device.macAddress) : true,
          };
        }

        return device;
      })

    this.setState({ devices: mappedDevices })
  }

  /**
   * Hanlde Submit 
   */
  handleSubmit = () => {
    const { customerId } = this.state;

    this.setState({ remoteError: null, validationErrors: {} }, () => {

      this.validateAllFields();

      setTimeout(() => {
        const isErrorsOccurs = document.querySelectorAll('.error');

        if (isErrorsOccurs.length > 0) return;

        const data = this.removeExtraRowBeforeSubmit();

        this.setState({ isLoading: true });

        customerService.addCustomerDevice(customerId, data)
          .then(response => {
            if (!response) {
              this.setState({
                remoteError: { message: Messages['no-responce-from-server'] },
                isLoading: false
              });
              return;
            }

            this.setState({
              device: customerService.DEFAULT_ROW,
              isPairedRow: false,
              shouldBlockNavigation: false,
              deviceMAC: null,
              type: null,
              devices: [],
              remoteError: null
            },
              () => {
                customerService.clearDataFromLocalStorage(customerId);
                this.fetchCustomerDevices(true);
              });
          })
          .catch(error => {
            console.error(error);
            this.setState({
              remoteError: convertResponseError(error),
              isLoading: false
            });
          });
      }, 300);
    });
  }

  /**
   * Get additional row
   *
   * @param {Object}
   * 
   * @return {HTML}
   */
  getAdditionalRow = ({ index, device, pairIndex = -1 }) => {
    const { key = '', name, isChildPairedRow, isPairedRow, subKey } = device;
    const { validationErrors } = this.state;

    // Handle valid device > Index wise
    const isValid = () => {
      if (
        pairIndex > -1 &&
        validationErrors.pairIndex === pairIndex &&
        validationErrors.index === index
      ) {
        return true;
      }

      if (validationErrors.index === index) return true;

      return false;
    }

    let indexDeviceType = -1;

    if (pairIndex > -1) {
      indexDeviceType = customerService.PAIRED_DEVICE_OPTION.findIndex(({ value }) => value === subKey);
    } else {
      indexDeviceType = customerService.DEVICE_TYPES.findIndex(({ value }) => value === key);
    }

    const defaultOptions = isChildPairedRow
      ? customerService.PAIRED_DEVICE_OPTION
      : customerService.DEVICE_TYPES;

    const getDefaultDeviceType = () => {
      if (
        indexDeviceType > -1 &&
        defaultOptions[indexDeviceType] &&
        defaultOptions[indexDeviceType].value
      ) {
        return defaultOptions[indexDeviceType].value
      }

      return '';
    }

    return (
      <>
        <Table.Cell width={1}>
          {pairIndex >= 0 && <Image src="images/hierarchy-end.svg" className="device-hierarchy-iocn" />}
          <Dropdown
            placeholder='Device Types'
            selection
            name="type"
            onChange={this.onChangeInput({ index, device, pairIndex })}
            options={defaultOptions}
            error={(Boolean(validationErrors.type) && isValid()) || (device.error && device.error.key)}
            selectOnBlur={false}
            defaultValue={getDefaultDeviceType()}
          />
        </Table.Cell>

        <Table.Cell>
          <Input
            fluid
            placeholder='Device Name'
            name='deviceName'
            value={name}
            disabled={!Boolean(key)}
            onChange={this.handleDeviceNameChange(device)}
            onBlur={this.trimName({ index, pairIndex })}
            autoComplete='off'
            error={(Boolean(validationErrors.deviceName) && isValid()) || (device.error && device.error.name)}
          />
        </Table.Cell>

        <Table.Cell>
          {!isPairedRow && (
            <Dropdown
              placeholder='Device'
              search
              selection
              name="deviceMAC"
              disabled={!key}
              onChange={this.onDeviceSelection({ index, pairIndex, device })}
              options={this.getDeviceOptions({ index, device, pairIndex })}
              error={!isPairedRow ? (Boolean(validationErrors.deviceMAC) && isValid()) || (device.error && device.error.macAddress) : false}
              selectOnBlur={false}
              defaultValue={(device.device && device.device.macAddress) || ''}
              loading={device.isFetchingAssignDevices}
            />
          )}
        </Table.Cell>

        <Table.Cell>{''}</Table.Cell>

        <Table.Cell>
          <Button
            onClick={this.appendNewRow({ index, pairIndex, device })}
            circular
            size='mini'
            icon='add'
          />
        </Table.Cell>
      </>
    )
  }

  /**
   * Render View
   */
  render() {
    const {
      isLoading,
      isOpenResetConfirmation,
      isOpenLocalStorageConfirmation,
      customer: { id, name },
      devices,
      remoteError,
    } = this.state;

    return (
      <>
        <Segment
          loading={isLoading}
          className='fake-segment'
        >
          <Breadcrumb
            icon='right angle'
            sections={[
              {
                key: 'bread-1',
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}`} >Customers</Link>
              },
              {
                key: 'bread-2',
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${id}`}>{name || ''}</Link>
              },
              {
                key: 'bread-3',
                content: 'Devices',
                active: true
              }
            ]} />

          <Grid>
            <Grid.Row columns={1} className='alignItemCenter'>
              <Grid.Column><Header as='h3'>Devices</Header></Grid.Column>
            </Grid.Row>
          </Grid>

          <Table singleLine size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Device (Pair) Name</Table.HeaderCell>
                <Table.HeaderCell>MAC Address</Table.HeaderCell>
                <Table.HeaderCell>Comment</Table.HeaderCell>
                <Table.HeaderCell>Operations</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {devices && devices.length > 0 && devices.map((assignedDevice, i) => {
                return (
                  <React.Fragment key={`main_${i}`}>
                    <Table.Row>
                      {!assignedDevice.isForLastRow && (
                        <>
                          <Table.Cell>{(customerService.DEVICE_TYPES_WITH_KEYS[assignedDevice.assignType] || { displaykey: 'Plate' }).displaykey}</Table.Cell>
                          <Table.Cell>
                            <Input
                              fluid
                              placeholder='Device Name'
                              name='deviceName'
                              value={assignedDevice.name}
                              onChange={this.handleDeviceNameChange(assignedDevice)}
                              onBlur={this.trimName({ index: i })}
                              autoComplete='off'
                              error={assignedDevice.error && assignedDevice.error.name}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {this.getMacAddress(assignedDevice.device)}{assignedDevice.device && assignedDevice.device.loan ? ` (Loan)` : ''}
                          </Table.Cell>
                          <Table.Cell>{(assignedDevice.device && assignedDevice.device.comment)}</Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              label='In Use'
                              checked={Boolean(assignedDevice.inUse)}
                              className='mr-5'
                              onChange={
                                this.handleInUseCheckbox({ index: i })
                              }
                              disabled={Boolean(assignedDevice.type && assignedDevice.type === 'PLATE_PAIR')}
                            />
                            {
                              (
                                !assignedDevice.devices ||
                                assignedDevice.devices.length === 0 ||
                                assignedDevice.devices[0].isForLastRow
                              ) && (
                                <Button
                                  onClick={this.deleteDevice({ index: i })}
                                  circular
                                  size='mini'
                                  icon='trash alternate outline alternate'
                                />
                              )}
                          </Table.Cell>
                        </>
                      )}

                      {assignedDevice.isForLastRow && this.getAdditionalRow({ index: i, device: assignedDevice })}
                    </Table.Row>

                    {assignedDevice.devices && assignedDevice.devices.length > 0 && assignedDevice.devices.map((pairDevice, idx) => (
                      <Table.Row key={`pair_${pairDevice.id}${idx}`} >
                        {!pairDevice.isForLastRow && (
                          <>
                            <Table.Cell>
                              <Image src="images/hierarchy-link.svg" className="device-hierarchy-iocn" />
                              {(customerService.DEVICE_TYPES_CHILD_WITH_KEYS[pairDevice.assignType.split('_')[1]]).displaykey || '-'}
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                fluid
                                placeholder='Device Name'
                                name='deviceName'
                                value={pairDevice.name}
                                onChange={this.handleDeviceNameChange(pairDevice)}
                                onBlur={this.trimName({ index: i, pairIndex: idx })}
                                autoComplete='off'
                                error={pairDevice.error && pairDevice.error.name}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {this.getMacAddress(pairDevice.device)}{pairDevice.device && pairDevice.device.loan ? ` (Loan)` : ''}
                            </Table.Cell>
                            <Table.Cell>{(pairDevice.device && pairDevice.device.comment) || ''}</Table.Cell>
                            <Table.Cell>
                              <Checkbox
                                label='In Use'
                                checked={Boolean(pairDevice.inUse)}
                                className='mr-5'
                                onChange={
                                  this.handleInUseCheckbox({ index: i, pairIndex: idx })
                                }
                              />
                              <Button
                                onClick={this.deleteDevice({ index: i, pairIndex: idx })}
                                circular
                                size='mini'
                                icon='trash alternate outline alternate'
                              />
                            </Table.Cell>
                          </>
                        )}

                        {pairDevice.isForLastRow && this.getAdditionalRow({ index: i, device: pairDevice, pairIndex: idx })}
                      </Table.Row>
                    ))}
                  </React.Fragment>
                )
              }
              )}
            </Table.Body>
          </Table>

          {remoteError &&
            <Message error>
              <Message.Header>Error {remoteError.status}</Message.Header>
              {remoteError.status === 400 && remoteError.validationErrors ?
                <ul className="list">
                  {remoteError.validationErrors.map((item, key) =>
                    <li key={key} className="content">{item.defaultMessage}</li>
                  )}
                </ul> :
                <p>{remoteError.message}</p>
              }
            </Message>
          }

          <Grid>
            <Grid.Row columns={2} className='alignItemCenter'>
              <Grid.Column>
                <Button
                  primary
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Button onClick={this.openResetConfirmationModal}>Reset</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Prompt
          when={this.state.shouldBlockNavigation}
          message='Changes you made may not be saved.'
        />

        {/* Reset Confirmation Modal */}
        <Confirm
          open={isOpenResetConfirmation}
          content='Are you sure you want to reset data?'
          header='Reset Data'
          onCancel={this.closeResetConfirmationModal}
          onConfirm={this.resetData}
          cancelButton='Cancel'
          confirmButton='Reset'
        />

        {/* Reset Confirmation Modal */}
        <Confirm
          open={isOpenLocalStorageConfirmation}
          content='You have some draft changes. Do you want to restore those changes?'
          header='Confirmation'
          onCancel={this.closeLocalStorageConfimation}
          onConfirm={this.restoreData}
          cancelButton='Discard'
          confirmButton='Restore'
          closeOnDimmerClick={false}
        />
      </>
    );
  }
}

export default CustomerDevices;