import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Message,
} from "semantic-ui-react";
import Messages from "../../config/messages";
import { convertResponseError, validateEmail } from "../../helpers/util";
import customerService from "../../services/customer"


class PractitionerDetails extends Component {
  state = {
    validationErrors: {},
    emailError: '',
    remoteError: null,
    isLoading: false,
    fields: []
  };

  componentDidMount() {
    this.handleAddMorePractitioner();
  }

  /**
   * Add more practitioner
   */
  handleAddMorePractitioner = () => {
    const { customer, userData } = this.props
    const values = [...this.state.fields];
    const userDetails = customer && customer.user ? customer.user : userData;

    if (values.length === 0) {
      const field = {
        email: userDetails.email,
        isAdmin: true
      };

      values.push(field);
      this.setState({ fields: values });
      this.props.updatePracititioner(values)
      return;
    }

    const field = {
      email: '',
      isAdmin: false
    }

    values.push(field);

    this.setState({ fields: values });
    this.props.updatePracititioner(values)
  };

  /**
   * Remove practitioner
   * @param {index} i 
   * @returns 
   */
  handleRemovePractitioner = (i) => () => {

    const values = [...this.state.fields];

    values.splice(i, 1);

    this.setState({ fields: values });
    this.props.updatePracititioner(values);
  };

  /**
   * 
   * @param {Previous step} event 
   */
  back = (event) => {
    event.preventDefault();
    this.props.prevStep();
  };

  /**
   * Save and continue
   * 
   * @param {Next step} event 
   */
  saveAndContinue = async (event) => {
    event.preventDefault();

    const {
      values,
    } = this.props;

    this.setState({
      validationErrors: {},
      remoteError: null,
    });

    await values.map((location, index) => {
      let email = validateEmail(location.email)
      if (email !== true) {
        this.setState({
          validationErrors: {
            index,
          },
          emailError: email,
        });
      }
      return values
    });

    if (this.state.validationErrors && this.state.validationErrors.index)

      return;

    this.savePractitioner(0);

  };

  /**
   * Save practitioner
   * 
   * @param {Index} i 
   */
  savePractitioner = async i => {
    const {
      values,
      customerId,
    } = this.props;

    if (!values || values.length === 0) return;

    if (values.length === i) {
      this.props.nextStep();
      return;
    }

    const { validationErrors } = this.state;

    if (validationErrors && validationErrors.index) return;

    this.setState({ isLoading: true });

    const role = values[i].isAdmin ? Messages["role-admin-practitioner"] : Messages["role-practitioner"];

    if (i === 0) {

      this.addDefaultPractitioner(customerId);
    } else {

      const practitioners = {
        email: values[i].email,
        role
      };

      await this.addPractitioner(customerId, practitioners);

    }

    this.savePractitioner(++i);
  }

  /**
   * 
   * @param {String} customerId 
   * @param {Object} practitioner 
   */
  addPractitioner = (customerId, practitioner) => {
    customerService.addPractitioners(customerId, practitioner)
      .then((response) => {
        this.setState({ isLoading: false });
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Add default practitioner
   * 
   * @param {String} customerId 
   */
  addDefaultPractitioner = customerId => {
    customerService.addDefaultPractitioners(customerId)
      .then((response) => {
        this.setState({ isLoading: false });
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Practitioner fields
   * @param {index} index 
   * @returns 
   */
  practitionerFields = (index, field) => {
    const { fields, validationErrors, emailError } = this.state;
    const { inputChangePractitioner } = this.props;

    return (
      <div className="location-fields">
        <Header as="h3">Practitioner {index + 1}</Header>
        <Form.Field widths="10" error={validationErrors.index === index}>
          <label>Email *</label>
          <Input
            name="email"
            disabled={index === 0}
            value={field.email}
            onChange={inputChangePractitioner("email", index, field)}
          />
          {validationErrors.index === index && (
            <Label basic color="red" pointing>
              {emailError}
            </Label>
          )}
        </Form.Field>

        <Form.Field>
          <Checkbox
            defaultChecked={field.isAdmin}
            disabled={index === 0}
            name="isAdmin"
            onChange={inputChangePractitioner("isAdmin", index, field)}
            label="Make this practitioner admin" />
        </Form.Field>

        {fields.length > 1 && index > 0 && (
          <button
            className="ui circular icon button location-close-btn"
            onClick={this.handleRemovePractitioner(index)}
          >
            <i className="close icon"></i>
          </button>
        )}
      </div>
    );
  };

  /**
   * 
   * @returns Buttons and practitioner fields
   */
  render() {
    const { fields, remoteError, isLoading } = this.state;
    const { customer } = this.props;

    return (
      <>
        <Form color="blue" className="mt-10 custom-form" loading={isLoading}>
          <label className="ui centered">
            {customer && customer.user ? 4 : 5}
            <Icon name="long arrow alternate right mb-15" />
         Add some practitioners
        </label>
          {fields.map((field, index) => (
            <React.Fragment key={index}>
              {this.practitionerFields(index, field)}
            </React.Fragment>
          ))}

          < Button
            fluid
            onClick={this.handleAddMorePractitioner}
            className="mb-10 mt-10"
          >
            ADD MORE PRACTITIONERS
        </Button>
          <Button
            onClick={this.back}
          >Back</Button>
          <Button
            onClick={this.saveAndContinue}
          >Finish</Button>
        </Form>
        {remoteError && (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        )}
      </>
    )
  }

}
export default PractitionerDetails;
