import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Header,
  Label,
  Grid,
  Message
} from "semantic-ui-react";
import messages from "../config/messages";
import customerService from "../services/customer";

const ForgetPassword = class extends Component {
  state = {
    email: "",
    errorEmail: "",
    buttonText: "Reset Password",
    validationErrors: {},
    counter: 60,
    isLoading: false,
    remoteSuccess: false,
    remoteError: null,
  }

  /**
* Validate All
*
* @param {Object} fields
* @param {Sting} parentPath
* @param {Object} root
*/
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : "";

    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + ".", root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    customerService.invitationLinkSchema.validateAt(field, fields).catch((error) => {
      this.setState((state) => ({
        validationErrors: {
          ...state.validationErrors,
          [field]: error.errors[0],
        },
      }));
    });
  }

  /**
   * On change input
   * 
   * @param {Event} event 
   * @param {String, String} param1 
   */
  onChangeInput = (event, { name, value }) => {
    this.setState({ [name]: value })
  }

  /**
   * Reset password
   * 
   * @param {Event} event 
   * @returns 
   */
  resetPassword = async (event) => {
    event.preventDefault();

    const { email } = this.state;
    const fields = { email }
    this.setState({ validationErrors: {} })
    const isValid = await customerService.invitationLinkSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }

    this.resetLoginPassword(email)
  }

  /**
   * Reset login password
   * 
   * @param {String} email 
   */
  resetLoginPassword = email => {

    this.setState({
      isLoading: true,
      buttonText: "Sending",
      remoteError: null,
      remoteSuccess: false,
    });

    customerService.resetLoginPassword(email)
      .then(response => {
        this.timeCounter();
        setTimeout(() => {
          this.setState({
            isLoading: false,
            buttonText: "Sent",
            remoteSuccess: true
          })
        }, 1000);

      })
      .catch(error => {
        this.setState({
          isLoading: false,
          buttonText: "Reset Password",
          remoteError: error.message,
        })

      })
  }

  /**
   * Time counter
   */
  timeCounter = () => {
    const count = setInterval(() => {
      const { counter } = this.state;

      if (counter === 0) {
        this.setState({
          counter: 60,
          buttonText: "Reset Password"
        })

        clearInterval(count);
        return;
      }
      this.setState({ counter: counter - 1 });
    }, 1000);
  }

  /**
   * Render View
   */
  render() {
    const {
      email,
      validationErrors,
      counter,
      isLoading,
      buttonText,
      remoteError,
      remoteSuccess
    } = this.state;

    return (
      <Grid centered>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Form
            loading={isLoading}
          > 
            <Header as='h3'>Reset Password</Header>
            <p className="mt-10">Forget your password? No worries. Just enter your email and we'll send you a link to reset it. </p>
            <Form.Field
              className="mt-10"
              error={Boolean(validationErrors["email"])}
            >
              <label>Email *</label>
              <Input
                type="text"
                placeholder="Email"
                name="email"
                value={email}
                onChange={this.onChangeInput}
              />
              {Boolean(validationErrors["email"]) && (
                <Label basic color="red" pointing>
                  {validationErrors["email"]}
                </Label>
              )}
            </Form.Field>

            {remoteError && (
              <Message
                error
                visible
                header="Error"
                content={remoteError}
              />
            )}

            {remoteSuccess && (
              <Message
                positive
                visible
                content={messages["email-sent"].replace("#EMAIL#", email)}
              />
            )}

            <Button
              primary
              disabled={counter !== 60 || isLoading}
              onClick={this.resetPassword}
            >
              {buttonText} {counter !== 60 && counter}
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
};

export default ForgetPassword;
