import React, { Component } from 'react';
import { Breadcrumb, Button, Form, Message } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import BusinessContactPerson from './BusinessContactPerson';
import BusinessDetails from './BusinessDetails';
import customerService from "../../services/customer"
import URLS, { URL_PREFIX } from '../../services/URLS';
import { convertResponseError } from '../../helpers/util';
import Messages from '../../config/messages';

class Business extends Component {
  /**
  * Refs
  */
  contactPersonRef = React.createRef();
  businessRef = React.createRef();

  /**
   * Initial State
   */
  state = {
    businessProfile: {
      name: "",
      websiteUrl: "",
    },
    businessContact: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    isLoading: false,
    remoteError: null,
    isValidate: false,
    isValidateContact: false,
  };

  componentDidMount() {
    const {
      user: { customer }
    } = this.props;
    if (customer) this.getCustomer(customer)
  }

  /**
   * Get customer 
   *
   * @param {Object} customer 
   */
  getCustomer = (customer) => {
    this.setState({ isLoading: true })
    customerService
      .getCustomerById(customer.id)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });

          return;
        }
        const businessProfile = response.data.businessProfile || {};
        const businessContact = response.data.user.contactDetail || {}
        this.setState({ businessProfile, businessContact, isLoading: false });

      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Validate Business Profile
   */
  validateBusinessProfile = async () => {
    this.setState({ isValidate: false });
    const { businessProfile } = this.state;
    const { name } = businessProfile;
    const fieldName = {
      name,
    };
    this.businessRef.current.removeValidation();
    const isValidName = await customerService.businessSchema.isValid(fieldName);

    if (!isValidName) {
      this.validate(fieldName, "businessProfile");

      return;
    }
    this.setState({ isValidate: true });
  };

  /**
   * 
   * Validate Business Contact
   */
  validateBusinessContact = async () => {
    this.setState({ isValidateContact: false });
    const { businessContact } = this.state;
    const { firstName, lastName, phone, email } = businessContact;

    const fields = {
      firstName,
      lastName,
      phone,
      email,
    };

    this.contactPersonRef.current.removeContactValidation();

    const isValid = await customerService.businessContactSchema.isValid(fields);

    if (!isValid) {
      this.validate(fields, "businessContact");
      return;
    }
    this.setState({ isValidateContact: true });
  };

  /**
   * 
   * Handle Save 
   */
  handleSave = async (evt) => {
    evt.preventDefault();

    await (this.validateBusinessContact(), this.validateBusinessProfile())

    const { isValidate, isValidateContact } = this.state;

    if (isValidate && isValidateContact) {
      this.setState({ isLoading: true })
      this.addBusinessProfileDetails();
    }
  };

  /**
   * Validate inputs
   * 
   * @param {Object} fields 
   * @param {String} validateType 
   * @returns 
   */
  validate = (fields, validateType) => {
    if (validateType === "businessProfile") {
      this.businessRef.current.validateAll(fields);
      return;
    }

    this.contactPersonRef.current.validateAll(fields);
  };

  /**
   * Add contact details for business location
   */
  addBusinessContactPerson = () => {
    this.setState({ remoteError: null })
    const { businessContact } = this.state
    const { firstName, lastName, phone, email } = businessContact;
    const fields = {
      firstName,
      lastName,
      phone,
      email,
    }

    let userId = this.props.user.id;

    customerService.addContactDetail(userId, fields)
      .then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        this.props.history.push(URL_PREFIX)
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false
        });
      });
  }

  /**
   * Add business profile details
   */
  addBusinessProfileDetails = () => {
    this.setState({ remoteError: null });

    const {
      businessProfile,
    } = this.state;

    const { customer: { id } } = this.props.user;

    customerService
      .addBusinessProfile(id, businessProfile)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
        this.addBusinessContactPerson();
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
        });
        this.setState({ isLoading: false })
      });
  };

  /**
   * Handle Input Change
   *
   * @param {Field} input
   */
  handleInputChange = (input, fields) => (event) => {
    let field = {
      ...this.state[fields],
      [input]: event.target.value || "",
    };
    this.setState({ [fields]: field });
  };

  /**
   * Render View
   */
  render() {
    const { customer } = this.props.user;
    const customerId = customer.id;
    const { businessProfile, businessContact, isLoading, remoteError } = this.state
    const businessDetails = { businessProfile };
    const bContact = { businessContact };

    return (
      <Form loading={isLoading} onSubmit={event => this.handleSave(event)}>
        <Breadcrumb
          icon="right angle"
          sections={[
            {
              key: "bread-1",
              content: <Link to={`${URL_PREFIX}${URLS.BUSINESS}`}>Business</Link>
              ,
            },
            {
              key: "bread-2",
              content: 'Business Profile',
              active: true,
            },
          ]}
        />
        <BusinessDetails
          ref={this.businessRef}
          handleChange={this.handleInputChange}
          values={businessDetails}
          isWizard={false}
          customerId={customerId}
          isDefaultLogo
        />
        <BusinessContactPerson
          isWizard={false}
          ref={this.contactPersonRef}
          handleChange={this.handleInputChange}
          values={bContact}
          customerId={customerId}
        />
        <Button
          type="submit"
          onClick={this.handleSave}
        >Save</Button>
        {remoteError && (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        )}
      </Form>
    );
  }
}

export default Business;