import React, { Component } from "react";
import { Button, Grid, Checkbox, Message } from "semantic-ui-react";
import customerService from "../../services/customer";
import request from "../../helpers/request"
import { Redirect } from "react-router";
import URLS, { URL_PREFIX } from "../../services/URLS";
import { logout } from "../../helpers/auth";
import Messages from "../../config/messages";
import { convertResponseError, getSearchQueries } from "../../helpers/util";
class UpgradeToCloud extends Component {
  state = {
    email: "",
    user: {},
    loginSuccess: true,
    isLoading: false,
    isSbnCustomer: false,
    isChecked: false,
    remoteError: null,
  };

  componentDidMount() {
    const { user } = this.props
    if (user && user.email && customerService.isSbnCustomer(user.email)) {
      this.setState({ isSbnCustomer: true })
    }

    if (user) {
      if (user.customer && user.customer.initStatus) {
        const { props } = this.props;
        props.history.push(`customers/${user.customer.id}/migrate`);
      }

      this.setState({ user })
      return;
    }
    const quries = getSearchQueries()
    if (quries.email && quries.token) {

      this.loginWithToken(quries.email, quries.token);
      return;
    }
    this.loginWithToken();

  }


  /**
   * Auto Login using email and token
   * 
   * @param {String} email 
   * @param {String} idToken 
   * @returns 
   */
  loginWithToken = (email = '', idToken = '') => {
    const { user: localUser } = this.state;
    const { user } = this.props;
    if ((user && localUser) || request.defaults.headers.common['Email-Token']) return;

    this.setState({ isLoading: true })

    request.defaults.headers.common['Email-Token'] = idToken;

    request.get('/users/email/' + email)
      .then(response => {
        if (!response) {
          this.setState({ loginSuccess: false, isLoading: false })
          logout();
        }
        if (response) {
          const user = response.data;
          this.setState({ user: user })
          if (user.role === Messages["role-customer"]) {
            request.get('/users/' + user.id + '/customer')
              .then(response => {
                if (!response) {
                  this.setState({ loginSuccess: false, isLoading: false });
                  logout();
                }
                if (response) {
                  user.customer = response.data;
                  this.setState({ user, isLoading: false });
                  this.props.loginWithEmailToken(true, user);
                }
              })
              .catch(error => {
                this.setState({ loginSuccess: false, isLoading: false });
                logout();
              })
            return;
          }
          this.props.loginWithEmailToken(true, user);
        }
      })
      .catch(error => {
        this.setState({ loginSuccess: false, isLoading: false });
        logout();
      })
  }

  /**
  * Upgrade customer
  */
  upgradeUser = () => {
    this.setState({
      isLoading: true,
      remoteError: null
    });
    customerService
      .migrateCustomer()
      .then((response) => {
        this.setState({ isLoading: false, });
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
        const { props } = this.props;
        props.history.push(`customers/${response.data.id}/migrate`);

      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      })
  };

  /**
   * Render View
   */
  render() {
    const {
      isChecked,
      isLoading,
      loginSuccess,
      user,
      isSbnCustomer,
      remoteError
    } = this.state;

    const { user: loginUser, authed } = this.props;

    if (!loginSuccess || !user)
      return <Redirect to={`${URL_PREFIX}${URLS.LOGIN}?from=${URLS.UPGRADE_TO_CLOUD}`} />
    return (
      <>
        {loginUser && authed && (
          <Grid
            loading={isLoading}
            textAlign="center"
            verticalAlign="middle"
            className="full-height-container"
          >
            <Grid.Column>
              <Grid celled textAlign="center">
                <Grid>
                  <Grid.Row />
                  {!isSbnCustomer && (
                    <>
                      <Grid.Row>
                        <Grid.Column>
                          You are eligible to upgrade your account to use AxIT Cloud
                  </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>{this.state.email || user.email}</Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        label="I have read and accepted the Terms of Use and Privacy Policy"
                        checked={isChecked}
                        onChange={() => {
                          this.setState({ isChecked: !isChecked });
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        loading={isLoading}
                        content="Upgrade Now"
                        disabled={!isChecked}
                        onClick={this.upgradeUser}
                      />
                      {remoteError && (
                        <Message error>
                          <Message.Header>Error {remoteError.status}</Message.Header>
                          {remoteError.status === 400 && remoteError.validationErrors ? (
                            <ul className="list">
                              {remoteError.validationErrors.map((item, key) => (
                                <li key={key} className="content">
                                  {item.defaultMessage}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p>{remoteError.message}</p>
                          )}
                        </Message>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row />
                </Grid>
              </Grid>
            </Grid.Column>
          </Grid>
        )}
      </>
    );
  }
}
export default UpgradeToCloud;
