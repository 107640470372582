import * as Yup from 'yup';

import request from '../helpers/request';


export default {
  
  getStatistics: function(customerId) {
    return customerId ? 
        request.get('/customers/' + customerId + '/statistics') : 
        request.get('/statistics');
  }

}