import React, { Component } from "react";
import {
  Form,
  Button,
  Icon,
  Header,
  Input,
  Label,
  Message,
} from "semantic-ui-react";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";
import customerService from "../../services/customer";
import Preview from "./Preview";

class BusinessDetails extends Component {
  state = {
    validationErrors: {},
    remoteError: null,
    isLoading: false,
  };

  /**
   * Save and Continue
   *
   * @param {Event} event
   */
  saveAndContinue = async (event) => {
    event.preventDefault();

    this.setState({
      validationErrors: {},
      remoteError: null,
    });

    const {
      values: { businessProfile },
      customerId,
    } = this.props;
    const { name } = businessProfile || {};

    const fields = {
      name,
    };

    const isValid = await customerService.businessSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }

    this.setState({ isLoading: true });

    customerService
      .addBusinessProfile(customerId, businessProfile)
      .then((response) => {
        this.setState({ isLoading: false });

        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        this.props.setUserData(response.data.user);
        this.props.nextStep();
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  };

  /**
   * Remove validation
   */
  removeValidation() {
    this.setState({ validationErrors: {} })
  }

  /**
   * Validate All
   *
   * @param {Object} fields
   * @param {Sting} parentPath
   * @param {Object} root
   */
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : "";

    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + ".", root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    customerService.businessSchema.validateAt(field, fields).catch((error) => {
      this.setState((state) => ({
        validationErrors: {
          ...state.validationErrors,
          [field]: error.errors[0],
        },
      }));
    });
  }

  /**
   * Render View
   */
  render() {
    const {
      values: {
        businessProfile: { name = "", websiteUrl = "" },
      },
      handleChange,
      customerId,
      isWizard,
      isDefaultLogo,
      customer
    } = this.props;

    const { validationErrors, remoteError = {}, isLoading } = this.state;
    return (
      <>
        <Form loading={isLoading} color="blue" className="mt-10 custom-form">
          {isWizard && (
            <label className="ui centered">
              {customer && customer.user ? 1 : 2}
              <Icon name="long arrow alternate right" />
              Please tell me a bit about your business
            </label>
          )}
          <Header as="h3">Business Info</Header>
          <Form.Field widths="10" error={Boolean(validationErrors["name"])}>
            <label>Business Name *</label>
            <Input
              placeholder="Business Name"
              name="name"
              value={name}
              onChange={handleChange("name", "businessProfile")}
            />
            {Boolean(validationErrors["name"]) && (
              <Label basic color="red" pointing>
                {validationErrors["name"]}
              </Label>
            )}
          </Form.Field>
          <Preview id={customerId} isDefaultLogo={isDefaultLogo} />

          <Form.Field>
            <label>Website URL</label>
            <Input
              placeholder="Website URL"
              value={websiteUrl}
              type="text"
              onChange={handleChange("websiteUrl", "businessProfile")}
            />
          </Form.Field>

          {isWizard && <Button onClick={this.saveAndContinue}>Next </Button>}
        </Form>

        {remoteError && (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        )}
      </>
    );
  }
}

BusinessDetails.defaultProps = {
  values: {
    businessProfile: {
      name: "",
      websiteUrl: "",
    },
  },
  handleChange: () => { },
  isWizard: false,
  isDefaultLogo: false,
};

export default BusinessDetails;