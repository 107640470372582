import React, { Component } from 'react';

import 'react-sortable-tree/style.css';

import uuid from 'uuid/v4';

import SortableTree, { changeNodeAtPath, toggleExpandedForAll, removeNodeAtPath, addNodeUnderParent } from 'react-sortable-tree'; 

import { Form, Input, Button, Icon, Modal, TextArea } from 'semantic-ui-react';

import testService from '../../services/test';
import playlistService from '../../services/playlist';

const defaultImportJson = `{
  "version": "1.0",
  "playlists": []
}`;

export default class TestList extends Component {
  state = {
    importJson: defaultImportJson,
    version: playlistService.getVersion(),
    importModalOpen: false,
    tests: testService.getTests(),
    playlists: playlistService.getPlaylists(),
    loading: true,
    sortBy: null, 
    sortDirection: null,
    loaded: false,
    searchStringTests: '',
    searchFocusIndexTests: 0,
    searchFoundCountTests: null,
    searchStringPlaylists: '',
    searchFocusIndexPlaylists: 0,
    searchFoundCountPlaylists: null
  };

  toggleTestExpansion(expanded) {
    this.setState(state => ({
      tests: toggleExpandedForAll({
        treeData: state.tests,
        expanded
      })
    }));
  }

  togglePlaylistExpansion(expanded) {
    this.setState(state => ({
      playlists: toggleExpandedForAll({
        treeData: state.playlists,
        expanded
      })
    }), () => {
      this.updatePlaylists();
    });
  }

  setPlaylistCategoryStateWithValue(field, value) {
    this.setState(state => ({ 
      currentPlaylistCategoryInfo: {
        ...state.currentPlaylistCategoryInfo,
        node: {
          ...state.currentPlaylistCategoryInfo.node,
          [field]: value
        }
      } 
    }), () => {
      this.setState({
        playlists: changeNodeAtPath({
          treeData: this.state.playlists, 
          path: this.state.currentPlaylistCategoryInfo.path, 
          newNode: this.state.currentPlaylistCategoryInfo.node,
          getNodeKey: ({ node }) => node.id
        })
      }, () => {
        this.updatePlaylists();
      });
    });
  }

  setPlaylistStateWithValue(field, value) {
    this.setState(state => ({ 
      currentPlaylistInfo: {
        ...state.currentPlaylistInfo,
        node: {
          ...state.currentPlaylistInfo.node,
          [field]: value
        }
      } 
    }), () => {
      this.setState({
        playlists: changeNodeAtPath({
          treeData: this.state.playlists, 
          path: this.state.currentPlaylistInfo.path, 
          newNode: this.state.currentPlaylistInfo.node,
          getNodeKey: ({ node }) => node.id
        })
      }, () => {
        this.updatePlaylists();
      });
    });
  }

  updatePlaylists() {
    playlistService.updateVersion(this.state.version);
    playlistService.updatePlaylists(this.state.playlists);
  }

  selectPrevMatchTests = () => {
    const { searchFocusIndexTests, searchFoundCountTests } = this.state;

    this.setState({
      searchFocusIndexTests:
        searchFocusIndexTests !== null
          ? (searchFoundCountTests + searchFocusIndexTests - 1) % searchFoundCountTests
          : searchFoundCountTests - 1,
    });
  };

  selectNextMatchTests = () => {
    const { searchFocusIndexTests, searchFoundCountTests } = this.state;

    this.setState({
      searchFocusIndexTests:
        searchFocusIndexTests !== null
          ? (searchFocusIndexTests + 1) % searchFoundCountTests
          : 0,
    });
  };

  selectPrevMatchPlaylists = () => {
    const { searchFocusIndexPlaylists, searchFoundCountPlaylists } = this.state;

    this.setState({
      searchFocusIndexPlaylists:
        searchFocusIndexPlaylists !== null
          ? (searchFoundCountPlaylists + searchFocusIndexPlaylists - 1) % searchFoundCountPlaylists
          : searchFoundCountPlaylists - 1,
    });
  };

  selectNextMatchPlaylists = () => {
    const { searchFocusIndexPlaylists, searchFoundCountPlaylists } = this.state;

    this.setState({
      searchFocusIndexPlaylists:
        searchFocusIndexPlaylists !== null
          ? (searchFocusIndexPlaylists + 1) % searchFoundCountPlaylists
          : 0,
    });
  };

  componentDidMount() {
    this.setState({
      tests: this.state.tests
    });
  }

  render() {
    const {
      searchStringTests,
      searchFocusIndexTests,
      searchFoundCountTests,
      searchStringPlaylists,
      searchFocusIndexPlaylists,
      searchFoundCountPlaylists
    } = this.state;

    const externalNodeType = 'test-to-playlist';

    return (
      <div style={{ height: 'calc(100% + 24px)', display: 'flex', flexDirection: 'row', margin: '-12px' }}>
        <div style={{ height: '100%', flex: '55%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: 0, paddingBottom: '5px', borderBottom: '1px solid #ddd' }}>
            <Button.Group>
              <Modal 
                open={this.state.importModalOpen}
                trigger={
                  <Button 
                    icon
                    onClick={() => this.setState({
                      importModalOpen: true
                    })}
                  >
                    <Icon name='folder open' />
                  </Button>
                }
                onClose={() => this.setState({
                  importModalOpen: false
                })}
              >
                <Modal.Header>Import</Modal.Header>
                <Modal.Content>
                  <TextArea 
                    style={{ width: '100%' }} 
                    rows={20} 
                    placeholder='JSON' 
                    value={this.state.importJson}
                    onChange={(e, { value }) => {
                      this.setState({
                        importJson: value
                      })
                    }} 
                  />
                  <div style={{ color: 'red' }}>{this.state.importError}</div>
                  <Modal.Actions>
                    <Button
                      onClick={() => {
                        try {
                          const json = JSON.parse(this.state.importJson);
                          this.setState({
                            importJson: defaultImportJson,
                            importError: null,
                            version: json.version,
                            playlists: [{
                              nodeType: 'top',
                              id: 'top',
                              expanded: true,
                              children: json.playlists
                            }],
                            importModalOpen: false,
                            searchStringTests: ''
                          }, () => {
                            this.updatePlaylists();
                          });
                        } catch (e) {
                          this.setState({
                            importError: e.message
                          });
                        }
                      }}
                    >Import</Button>
                  </Modal.Actions>
                </Modal.Content>
              </Modal>
              <Modal trigger={
                <Button icon>
                  <Icon name='save' />
                </Button>
              }>
                <Modal.Header>Export</Modal.Header>
                <Modal.Content>
                  <TextArea 
                    style={{ width: '100%' }} 
                    rows={20} 
                    placeholder='JSON' 
                    value={JSON.stringify({
                      version: playlistService.getVersion(),
                      playlists: playlistService.getPlaylists()[0].children
                    }, null, 2)}
                  />
                </Modal.Content>
              </Modal>
            </Button.Group>

            <Input 
              label='v'
              size='small'
              placeholder='Version' 
              value={this.state.version} 
              onChange={(e, { value }) => {
                this.setState({
                  version: value
                }, () => {
                  this.updatePlaylists();
                });
              }}
              style={{ width: '80px', marginLeft: '5px', height: '36px', top: '-1px', marginRight: '34px' }}
            />

            <Button.Group>
              <Button 
                icon
                onClick={() => this.togglePlaylistExpansion(true)}
                >
                <Icon name='angle double down' />
              </Button>
              <Button 
                icon
                onClick={() => this.togglePlaylistExpansion(false)}
                >
                <Icon name='angle double up' />
              </Button>
            </Button.Group>

            <div style={{ float: 'right', marginRight: '10px' }}>
              <Input 
                size='small'
                placeholder='Search' 
                value={searchStringPlaylists} 
                onChange={(e, { value }) => this.setState({
                  searchStringPlaylists: value
                })}
                style={{ marginLeft: '5px', height: '36px' }}
              />
              <Button.Group>
                <Button 
                  icon
                  onClick={() => this.selectPrevMatchPlaylists()}
                >
                  <Icon name='angle left' />
                </Button>
                <Button 
                  icon
                  onClick={() => this.selectNextMatchPlaylists()}
                >
                  <Icon name='angle right' />
                </Button>
              </Button.Group>
              <span style={{ marginLeft: '10px' }}>
                {searchFoundCountPlaylists !== 0 ? searchFocusIndexPlaylists + 1 : 0} / {searchFoundCountPlaylists}
              </span>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <SortableTree
              treeData={this.state.playlists}
              onChange={treeData => {
                this.setState({ playlists: treeData }, () => {
                  this.updatePlaylists();
                });
              }}
              dndType={externalNodeType}
              searchQuery={searchStringPlaylists}
              searchFocusOffset={searchFocusIndexPlaylists}
              searchFinishCallback={matches =>
                this.setState({
                  searchFoundCountPlaylists: matches.length,
                  searchFocusIndexPlaylists:
                    matches.length > 0 ? searchFocusIndexPlaylists % matches.length : 0,
                })
              }
              searchMethod={({ node, searchQuery }) => {
                if (searchQuery !== '') {
                  if (node.nodeType === 'category' || node.nodeType === 'playlist') {
                    return node.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
                      node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
                  }
                }
                return false;
              }}
              generateNodeProps={info => {
                if (info.node.nodeType === 'top') {
                  return {
                    title: 'Playlists',
                    subtitle: info.node.children ? `(${info.node.children
                      .filter(category => !category.deleted)
                      .reduce((sum, category) => sum + (category.children ? category.children.filter(test => !test.deleted).length : 0), 0)} in ${info.node.children.filter(category => !category.deleted).length} categories)` : '(0)',
                    buttons: [
                      <button
                        className="btn btn-outline-success"
                        style={{
                          verticalAlign: 'middle',
                        }}
                        onClick={() => {
                          this.setState({
                            playlists: addNodeUnderParent({
                              treeData: this.state.playlists,
                              newNode: {
                                id: uuid(),
                                nodeType: 'category',
                                name: 'New Category'
                              },
                              parentKey: info.node.id,
                              getNodeKey: ({ node }) => node.id,
                              expandParent: true
                            }).treeData
                          }, () => {
                            this.updatePlaylists();
                          });
                        }}
                      >
                        +
                      </button>
                    ]
                  }
                } else if (info.node.nodeType === 'category') {
                  return {
                    title: info.node.deleted ? 
                      (<span style={{textDecoration: 'line-through', color: 'red'}}>{info.node.name}</span>) : 
                      info.node.name,
                    subtitle: info.node.children ? `(${info.node.children.filter(category => !category.deleted).length})` : '(0)',
                    buttons: [
                      <Modal 
                        open={this.state.editPlaylistCategoryModalOpen}
                        trigger={
                          <button
                            className="btn btn-outline-success"
                            style={{
                              verticalAlign: 'middle',
                            }}
                            onClick={() => this.setState({ 
                              currentPlaylistCategoryInfo: info,
                              editPlaylistCategoryModalOpen: true
                            })}
                          >
                            ℹ
                          </button>
                        }
                        onClose={() => this.setState({
                          editPlaylistCategoryModalOpen: false
                        })}
                      >
                        <Modal.Header>
                          {this.state.currentPlaylistCategoryInfo ? this.state.currentPlaylistCategoryInfo.node.name : ''}
                        </Modal.Header>
                        <Modal.Content>
                          {this.state.currentPlaylistCategoryInfo ? (
                            <div>
                              <Form.Field
                                key={this.state.currentPlaylistCategoryInfo.node.id + 'name'}
                                label='Category Name'
                                placeholder='Category Name'
                                control={Input}
                                value={this.state.currentPlaylistCategoryInfo.node.name}
                                onChange={(e, { value }) => {
                                  this.setPlaylistCategoryStateWithValue('name', value);
                                }} 
                              />
                              <Form.Field
                                key={this.state.currentPlaylistCategoryInfo.node.id + 'image'}
                                label='Image'
                                placeholder='Image'
                                control={Input}
                                value={this.state.currentPlaylistCategoryInfo.node.image}
                                onChange={(e, { value }) => {
                                  this.setPlaylistCategoryStateWithValue('image', value);
                                }} 
                              />
                            </div>) : ''}
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            onClick={() => {
                              this.setState({ editPlaylistCategoryModalOpen: false });
                            }}
                          >OK</Button>
                        </Modal.Actions>
                      </Modal>,
                      <button
                        className="btn btn-outline-success"
                        style={{
                          verticalAlign: 'middle',
                        }}
                        onClick={() => {
                          this.setState({
                            playlists: addNodeUnderParent({
                              treeData: this.state.playlists,
                              newNode: {
                                id: uuid(),
                                nodeType: 'playlist',
                                name: 'New Playlist'
                              },
                              parentKey: info.node.id,
                              getNodeKey: ({ node }) => node.id,
                              expandParent: true
                            }).treeData
                          }, () => {
                            this.updatePlaylists();
                          });
                        }}
                      >
                        +
                      </button>,
                      <Modal 
                        open={this.state.deletePlaylistCategoryModalOpen}
                        trigger={
                          <button
                            className="btn btn-outline-success"
                            style={{
                              verticalAlign: 'middle',
                            }}
                            onClick={() => {
                              this.setState({ 
                                currentPlaylistCategoryInfo: info,
                                deletePlaylistCategoryModalOpen: true
                              });
                            }}
                          >
                            x
                          </button>
                        }
                        onClose={() => this.setState({
                          deletePlaylistCategoryModalOpen: false
                        })}
                      >
                        <Modal.Header>
                          {this.state.currentPlaylistCategoryInfo ? this.state.currentPlaylistCategoryInfo.node.name : ''}
                        </Modal.Header>
                        <Modal.Content>
                          Do you want to delete {this.state.currentPlaylistCategoryInfo ? this.state.currentPlaylistCategoryInfo.node.name : ''}?
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistCategoryModalOpen: false });
                              this.setPlaylistCategoryStateWithValue('deleted', this.state.currentPlaylistCategoryInfo ? !this.state.currentPlaylistCategoryInfo.node.deleted : true)
                            }}
                          >Mark as {this.state.currentPlaylistCategoryInfo ? (this.state.currentPlaylistCategoryInfo.node.deleted ? 'Not ' : '') : ''}Deleted</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistCategoryModalOpen: false });
                              this.setState({
                                playlists: removeNodeAtPath({
                                  treeData: this.state.playlists,
                                  path: this.state.currentPlaylistCategoryInfo.path,
                                  getNodeKey: ({ node }) => node.id
                                }),
                                currentPlaylistCategoryInfo: null
                              }, () => {
                                this.updatePlaylists();
                              });
                            }}
                          >Delete Permanently</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistCategoryModalOpen: false });
                            }}
                          >Cancel</Button>
                        </Modal.Actions>
                      </Modal>
                    ]
                  }
                } else if (info.node.nodeType === 'playlist') {
                  return {
                    title: info.node.deleted ? 
                      (<span style={{textDecoration: 'line-through', color: 'red'}}>{info.node.name}</span>) : 
                      info.node.name,
                    subtitle: info.node.children ? `(${info.node.children.length})` : '(0)',
                    buttons: [
                      <Modal 
                        open={this.state.editPlaylistModalOpen}
                        trigger={
                          <button
                            className="btn btn-outline-success"
                            style={{
                              verticalAlign: 'middle',
                            }}
                            onClick={() => this.setState({ 
                              currentPlaylistInfo: info,
                              editPlaylistModalOpen: true
                            })}
                          >
                            ℹ
                          </button>
                        }
                        onClose={() => this.setState({
                          editPlaylistModalOpen: false
                        })}
                      >
                        <Modal.Header>
                          {this.state.currentPlaylistInfo ? this.state.currentPlaylistInfo.node.name : ''}
                        </Modal.Header>
                        <Modal.Content>
                          {this.state.currentPlaylistInfo ? (
                            <Form.Field
                              key={this.state.currentPlaylistInfo.node.id}
                              label='Playlist Name'
                              placeholder='Playlist Name'
                              control={Input}
                              value={this.state.currentPlaylistInfo.node.name}
                              onChange={(e, { value }) => {
                                this.setPlaylistStateWithValue('name', value);
                              }} 
                            />) : ''}
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            onClick={() => {
                              this.setState({ editPlaylistModalOpen: false });
                            }}
                          >OK</Button>
                        </Modal.Actions>
                      </Modal>,
                      <button
                        className="btn btn-outline-success"
                        style={{
                          verticalAlign: 'middle',
                        }}
                        onClick={() => {
                          this.setState({
                            playlists: addNodeUnderParent({
                              treeData: this.state.playlists,
                              newNode: {
                                id: uuid(),
                                nodeType: 'playlist',
                                name: info.node.name + ' (copy)',
                                children: JSON.parse(JSON.stringify(info.node.children)).map((test) => {
                                  test.id = uuid();
                                  return test;
                                })
                              },
                              parentKey: info.parentNode.id,
                              getNodeKey: ({ node }) => node.id,
                              expandParent: true
                            }).treeData
                          }, () => {
                            this.updatePlaylists();
                          });
                        }}
                      >
                        ❐
                      </button>,
                      <Modal 
                        open={this.state.deletePlaylistModalOpen}
                        trigger={
                          <button
                            className="btn btn-outline-success"
                            style={{
                              verticalAlign: 'middle',
                            }}
                            onClick={() => {
                              this.setState({ 
                                currentPlaylistInfo: info,
                                deletePlaylistModalOpen: true
                              });
                            }}
                          >
                            x
                          </button>
                        }
                        onClose={() => this.setState({
                          deletePlaylistModalOpen: false
                        })}
                      >
                        <Modal.Header>
                          {this.state.currentPlaylistInfo ? this.state.currentPlaylistInfo.node.name : ''}
                        </Modal.Header>
                        <Modal.Content>
                          Do you want to delete {this.state.currentPlaylistInfo ? this.state.currentPlaylistInfo.node.name : ''}?
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistModalOpen: false });
                              this.setPlaylistStateWithValue('deleted', this.state.currentPlaylistInfo ? !this.state.currentPlaylistInfo.node.deleted : true)
                            }}
                          >Mark as {this.state.currentPlaylistInfo ? (this.state.currentPlaylistInfo.node.deleted ? 'Not ' : '') : ''}Deleted</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistModalOpen: false });
                              this.setState({
                                playlists: removeNodeAtPath({
                                  treeData: this.state.playlists,
                                  path: this.state.currentPlaylistInfo.path,
                                  getNodeKey: ({ node }) => node.id
                                }),
                                currentPlaylistInfo: null
                              }, () => {
                                this.updatePlaylists();
                              });
                            }}
                          >Delete Permanently</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistModalOpen: false });
                            }}
                          >Cancel</Button>
                        </Modal.Actions>
                      </Modal>
                    ]
                  }
                } else if (info.node.nodeType === 'test') {
                  var test = testService.getTestTypeById(info.node.testId ? info.node.testId : info.node.id);
                  return {
                    title: test.name,
                    subtitle: test.primaryDevice.split('_')[0],
                    buttons: [
                      <Modal 
                        open={this.state.deletePlaylistTestModalOpen}
                        trigger={
                          <button
                            className="btn btn-outline-success"
                            style={{
                              verticalAlign: 'middle',
                            }}
                            onClick={() => {
                              this.setState({ 
                                currentPlaylistTestInfo: info,
                                deletePlaylistTestModalOpen: true
                              });
                            }}
                          >
                            x
                          </button>
                        }
                        onClose={() => this.setState({
                          deletePlaylistTestModalOpen: false
                        })}
                      >
                        <Modal.Header>
                          {this.state.currentPlaylistTestInfo ? testService.getTestTypeById(this.state.currentPlaylistTestInfo.node.testId).name : ''}
                        </Modal.Header>
                        <Modal.Content>
                          Do you want to delete {this.state.currentPlaylistTestInfo ? testService.getTestTypeById(this.state.currentPlaylistTestInfo.node.testId).name : ''}?
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistTestModalOpen: false });
                              this.setState({
                                playlists: removeNodeAtPath({
                                  treeData: this.state.playlists,
                                  path: this.state.currentPlaylistTestInfo.path,
                                  getNodeKey: ({ node }) => node.id
                                }),
                                currentPlaylistTestInfo: null
                              }, () => {
                                this.updatePlaylists();
                              });
                            }}
                          >Delete</Button>
                          <Button
                            onClick={() => {
                              this.setState({ deletePlaylistTestModalOpen: false });
                            }}
                          >Cancel</Button>
                        </Modal.Actions>
                      </Modal>
                    ]
                  }
                }
              }}
              canDrag={drag => drag.node.nodeType === 'category' || drag.node.nodeType === 'playlist' || drag.node.nodeType === 'test'}
              canDrop={drop => {
                if (drop.node.nodeType === 'category' && drop.nextParent && drop.nextParent.nodeType === 'top') {
                  return true;
                } else if (drop.node.nodeType === 'playlist' && drop.nextParent && drop.nextParent.nodeType === 'category') {
                  return true;
                } else if (drop.node.nodeType === 'test' && drop.nextParent && drop.nextParent.nodeType === 'playlist') {
                  return true;
                }
                return false;
              }}
              getNodeKey={({ node }) => node.id}
            />
          </div>
        </div>
        <div style={{ height: '100%', flex: '45%', overflowY: 'hidden', overflowX: 'hidden', borderLeft: '1px solid #ddd', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: 0, paddingLeft: '5px', paddingBottom: '6px', borderBottom: '1px solid #ddd' }}>
            <Button.Group>
              <Button 
                icon
                onClick={() => this.toggleTestExpansion(true)}
                >
                <Icon name='angle double down' />
              </Button>
              <Button 
                icon
                onClick={() => this.toggleTestExpansion(false)}
                >
                <Icon name='angle double up' />
              </Button>
            </Button.Group>

            <div style={{ float: 'right', marginRight: '10px' }}>
              <Input 
                size='small'
                placeholder='Search' 
                value={searchStringTests} 
                onChange={(e, { value }) => this.setState({
                  searchStringTests: value
                })}
                style={{ marginLeft: '5px', height: '36px' }}
              />
              <Button.Group>
                <Button 
                  icon
                  onClick={() => this.selectPrevMatchTests()}
                >
                  <Icon name='angle left' />
                </Button>
                <Button 
                  icon
                  onClick={() => this.selectNextMatchTests()}
                >
                  <Icon name='angle right' />
                </Button>
              </Button.Group>
              <span style={{ marginLeft: '10px' }}>
                {searchFoundCountTests !== 0 ? searchFocusIndexTests + 1 : 0} / {searchFoundCountTests}
              </span>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <SortableTree
              treeData={this.state.tests}
              onChange={treeData => {
                this.setState({ tests: treeData });
              }}
              dndType={externalNodeType}
              shouldCopyOnOutsideDrop={true}
              searchQuery={searchStringTests}
              searchFocusOffset={searchFocusIndexTests}
              searchFinishCallback={matches =>
                this.setState({
                  searchFoundCountTests: matches.length,
                  searchFocusIndexTests:
                    matches.length > 0 ? searchFocusIndexTests % matches.length : 0,
                })
              }
              searchMethod={({ node, searchQuery }) => {
                if (searchQuery !== '') {
                  if (node.nodeType === 'test') {
                    return node.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
                      node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 || 
                      node.mode.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
                  } else {
                    return node.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 || 
                      node.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
                  }
                }
                return false;
              }}
              canDrag={drag => drag.node.nodeType === 'test'}
              canDrop={drop => false}
              generateNodeProps={info => {
                if (info.node.nodeType === 'test') {
                  return {
                    title: info.node.name,
                    subtitle: info.node.primaryDevice.split('_')[0]
                  }
                } else if (info.node.nodeType === 'category') {
                  return {
                    title: info.node.name,
                    subtitle: info.node.children ? `(${info.node.children.length})` : '(0)'
                  }
                } else if (info.node.nodeType === 'device') {
                  return {
                    title: info.node.name,
                    subtitle: info.node.children ? `(${info.node.children
                      .reduce((sum, category) => sum + (category.children ? category.children.length : 0), 0)} in ${info.node.children.length} categories)` : '(0)'
                  };
                }
              }}
              getNodeKey={({ node }) => node.id}
            />
          </div>
        </div>
      </div>
    );
  }
}
