import React, { Component } from "react";
import { Header, Grid, Message } from "semantic-ui-react";

import LoginDetails from "./LoginDetails";
import BusinessDetails from "./BusinessDetails";
import BusinessContactPerson from "./BusinessContactPerson";
import Success from "./Success";
import BusinessLocation from "./BusinessLocation";
import customerService from "../../services/customer";
import PractitionerDetails from "./PractitionerDetails";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";
import request from "../../helpers/request"

class CustomerSetupWizard extends Component {
  state = {
    step: 1,
    currentCustomer: {},

    loginDetail: {
      email: "",
      password: "",
    },

    businessProfile: {
      name: "",
      websiteUrl: "",
    },

    businessContact: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },

    businessLocation: [
      {
        name: "",
        phone: "",
        email: "",
        address: "",
      },
    ],

    user: [
      {
        email: "",
        isAdmin: true
      }
    ],

    remoteError: null,
  };

  componentDidMount() {
    this.getCustomer();
  }
  componentWillUnmount() {
    customerService.clearEmailTokenFromLocalStorage()
  }

  /**
   * Get Customer
   */
  getCustomer() {
    const customerData = customerService.getEmailTokenToLocalStorage();
    const { user } = this.props;

    if (!user && customerData && customerData.user && customerData.user.email) {

      request.defaults.headers.common['Email-Token'] = customerData.user.email;
      this.getCustomerbyCustomerId(customerData.id);

      return;
    }

    if (user) {
      const { props } = this.props;

      const {
        match: {
          params: { customerId },
        },
      } = props;

      this.getCustomerbyCustomerId(customerId);
    }
  }

  /**
   * Get customer by customer ID
   */
  getCustomerbyCustomerId = (customerId) => {
    this.setState({ isLoading: true });

    const {
      INIT_STATUS: {
        user_set,
        business_profile_set,
        contact_detail_set,
        location_set,
        complete
      },
    } = customerService;
    customerService
      .getCustomerById(customerId)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });

          return;
        }

        const currentCustomer = response.data || {};
        const businessProfile = response.data.businessProfile || {};
        const businessContact = response.data.user.contactDetail || {};

        this.setState({
          currentCustomer,
          businessProfile,
          businessContact,
          isLoading: false
        });

        switch (currentCustomer.initStatus) {
          case user_set.key:
            return this.setState({ step: 2 });

          case business_profile_set.key:
            return this.setState({ step: 3 });

          case contact_detail_set.key:
            return this.setState({ step: 4 });

          case location_set.key:
            return this.setState({ step: 5 });

          case complete.key:
            const { props, user } = this.props;
            if (user && user.customer && user.eligibleToMigrate) {
              this.props.eligibleToMigrate();
            }
            props.history.push(``);
            return;

          default:
            return this.setState({ step: 1 });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          return this.setState({ step: 2 });
        } else {
          this.setState({
            remoteError: convertResponseError(error),
            isLoading: false,
          });
        }
      });
  }

  /**
   * Update practitioner
   * 
   * @param {Object} user 
   */
  updatePracititioner = user => {
    this.setState({ user })
  }

  /**
   * Next Step
   */
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  /**
   * Previous Step
   */
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  /**
   * Manage locations
   */
  moreLocations = (locations) => {
    let { businessLocation } = this.state;
    businessLocation = locations;
    this.setState({ businessLocation });
  };

  /**
   * Handle Input Change
   *
   * @param {Field} input
   */
  handleInputChange = (input, page) => (event) => {
    let field = {
      ...this.state[page],
      [input]: event.target.value || "",
    };
    this.setState({ [page]: field });
  };

  /**
   * Set user data
   * @param {UserData} fields
   */
  setUserData = (userData) => {
    this.setState({ userData });
  };

  /**
   * Input Change Business Location
   *
   * @param {String} field
   * @param {Number} index
   * @param {Object} locationData
   * @returns
   */
  inputChangeBusinessLocation = (field, index, locationData) => (event) => {
    const { businessLocation } = this.state;

    locationData[field] = event.target.value;
    businessLocation[index] = locationData;

    this.setState({ businessLocation });
  };

  /**
   * Input change practitioner 
   *
   * @param {String} field 
   * @param {Integer} index 
   * @param {Object} practitioner 
   * @returns 
   */
  inputChangePractitioner = (field, index, practitioner) => (event, { value }) => {
    const { user } = this.state;

    practitioner[field] = field === "isAdmin" ?
      !practitioner.isAdmin :
      value;

    user[index] = practitioner;

    this.setState({ user })
  }

  /**
   * Handle Wizard Pages
   */
  handleWizardPages = () => {
    const { props: internalProps, user } = this.props;

    const {
      match: {
        params: { customerId },
      },
    } = internalProps;

    const {
      loginDetail,
      businessProfile,
      businessContact,
      businessLocation,
      step,
      currentCustomer,
      userData,
      user: currentUser
    } = this.state;

    const values = { loginDetail };
    const businessDetails = { businessProfile };
    const bContact = { businessContact };
    const bLocation = { businessLocation };
    const {
      location: { search }, }
      = internalProps;


    switch (step) {
      case 1:
        return (
          <LoginDetails
            nextStep={this.nextStep}
            handleChange={this.handleInputChange}
            values={values}
            customer={currentCustomer}
            code={search}
            customerId={customerId}
            user={user}
          />
        );
      case 2:
        return (
          <BusinessDetails
            nextStep={this.nextStep}
            handleChange={this.handleInputChange}
            values={businessDetails}
            customerId={customerId}
            customer={currentCustomer}
            setUserData={this.setUserData}
            isWizard
          />
        );
      case 3:
        return (
          <>
            <BusinessContactPerson
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleInputChange}
              values={bContact}
              customerId={customerId}
              customer={currentCustomer}
              user={currentCustomer.user}
              userData={userData}
              isWizard
            />
          </>
        );
      case 4:
        return (
          <BusinessLocation
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            inputChangeBusinessLocation={this.inputChangeBusinessLocation}
            values={bLocation}
            customer={currentCustomer}
            businessDetails={businessDetails}
            customerId={customerId}
            moreLocations={this.moreLocations}
          />
        );
      case 5:
        return (
          <PractitionerDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={currentUser}
            customerId={customerId}
            customer={currentCustomer}
            updatePracititioner={this.updatePracititioner}
            inputChangePractitioner={this.inputChangePractitioner}
            userData={userData}
          />
        );

      case 6:
        return <Success />;

      default:
        return null;
    }
  };

  /**
   * Render View
   */
  render() {
    const { remoteError, step } = this.state;
    return (
      <>
        <div className="full-height-container flex-column justify-content-center">
          <Grid celled textAlign="center">
            <Grid className="custom-grid-width">
              <Grid.Row>
              </Grid.Row>
              <Grid.Row>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Header as="h3">Welcome to AxIT Cloud!</Header>
                  {step !== 6 && (
                    <label className="mb-15">
                      We need some basic information from you to set you up.
                    </label>
                  )}
                  {this.handleWizardPages()}
                  {remoteError && (
                    <Message error>
                      <Message.Header>Error {remoteError.status}</Message.Header>
                      {remoteError.status === 400 && remoteError.validationErrors ? (
                        <ul className="list">
                          {remoteError.validationErrors.map((item, key) => (
                            <li key={key} className="content">
                              {item.defaultMessage}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>{remoteError.message}</p>
                      )}
                    </Message>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
              </Grid.Row>
              <Grid.Row>
              </Grid.Row>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default CustomerSetupWizard;
