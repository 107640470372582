import React, { Component } from "react";
import {
  Button,
  Segment,
  Table,
  Grid,
  Header,
  Divider,
  Breadcrumb,
  Message,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ConfirmationDialogComponent from "./ConfirmationDialogComponent";
import customerService from "../../services/customer";
import URLS, { URL_PREFIX } from "../../services/URLS";
import { convertBinaryToBase64, convertResponseError, getSearchQueries } from "../../helpers/util";
import Messages from "../../config/messages";

class Locations extends Component {
  /**
   * state
   */
  state = {
    locations: [],
    selectedLocation: {},

    customer: {},
    imgUrl: '',

    remoteError: null,
    isLoading: false,
    isDeleteConfOpen: false,

    customerIdFromUrl: '',
  };

  componentDidMount() {
    const { user } = this.props;

    if (user && user.role === Messages["role-admin"]) {
      const getCustomerId = getSearchQueries()

      const customerIdFromUrl = (getCustomerId && getCustomerId.customerId) || '';

      if (customerIdFromUrl) {
        this.setState({ customerIdFromUrl })
        this.getLocationsListing(customerIdFromUrl);
        this.getCustomer(customerIdFromUrl)
      } return;
    }

    const { customer } = user;
    this.getLocationsListing(customer.id);
  }

  /**
   * Get customer
   * 
   * @param {String} customerId 
   */
  getCustomer = customerId => {
    this.setState({ isLoading: true })

    customerService.getCustomerById(customerId)
      .then(response => {
        this.setState({
          customer: response.data,
          isLoading: false
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          remoteError: convertResponseError(error),
        });
      });
  }

  /**
   * Get Listing of Locations 
   */
  getLocationsListing = (customerId) => {
    this.setState({ isLoading: true })

    customerService
      .getBusinessLocationById(customerId)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
        const locations = response.data || {}
        this.setState({ locations, isLoading: false });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          remoteError: convertResponseError(error),
        });
      });

  };

  /**
   * Get Customer Logo
   *
   * @param {Object} customer
   */
  getCustomerLogo = (customerId) => async () => {
    await customerService
      .getCustomerLogo(customerId)
      .then((response) => {
        const imgUrl = convertBinaryToBase64(response.data);
        this.setState({ imgUrl });
      })
      .catch(() => {
        const imgUrl = "images/defaultLogo.png";
        this.setState({ imgUrl });
      });
  };

  /**
   * On confirm
   */
  onConfirm = () => {
    const { selectedLocation, locations } = this.state;

    this.setState({ isLoading: true, remoteError: null })

    customerService
      .deleteLocationById(selectedLocation.id)
      .then((response) => {
        let index = locations.findIndex((val => val.id === selectedLocation.id));
        locations.splice(index, 1);

        this.setState({
          locations,
          isDeleteConfOpen: false,
          selectedLocation: {},
          isLoading: false
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          remoteError: convertResponseError(error),
        });
      });
  }

  /**
   * Handle delete modal opening
   * 
   * @param {Object} selectedLocation 
   * @returns 
   */
  handleDeleteConf = (selectedLocation) => () => {
    this.setState({ isDeleteConfOpen: true, selectedLocation });
  }

  /**
   * Handle delete modal closing
   */
  onCancel = () => {
    this.setState({
      isDeleteConfOpen: false,
      selectedLocation: {},
      remoteError: null
    });
  }

  /**
   * Render view
   */
  render() {
    const {
      locations,
      isLoading,
      imgUrl,
      remoteError,
      isDeleteConfOpen,
      customer: currentCustomer,
      customerIdFromUrl,
    } = this.state;

    const { user: currentUser } = currentCustomer || {};
    const { contactDetail } = currentUser || {};
    const { firstName = "-" } = contactDetail || {};
    const { user } = this.props;
    const { customer } = user || {};

    return (
      <div>
        {user && user.role === Messages["role-customer"] && (
          <Breadcrumb
            icon="right angle"
            sections={[
              {
                key: "bread-1",
                content: <Link to={`${URL_PREFIX}${URLS.BUSINESS}`}>Business</Link>,
              },
              {
                key: "bread-2",
                content: 'Locations',
                active: true
              },
            ]}
          />
        )}

        {user && user.role === Messages["role-admin"] && (
          <Breadcrumb
            icon='right angle'
            sections={[
              {
                key: 'bread-1',
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}`} >Customers</Link>
              },
              {
                key: 'bread-2',
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customerIdFromUrl}`}>
                  {firstName}
                </Link>
              },
              {
                key: 'bread-3',
                content: 'Location',
                active: true
              }
            ]} />
        )}

        <Grid>
          <Grid.Row columns={2} className="alignItemCenter">
            <Grid.Column>
              <Header as="h3">Locations</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button as={Link} to={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}${URL_PREFIX}add${customerIdFromUrl ? '?customerId=' + customerIdFromUrl : ''}`} primary>
                Add Location
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Segment loading={isLoading} className="fake-segment">
          <Table fixed size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Logo</Table.HeaderCell>
                <Table.HeaderCell>Business Name</Table.HeaderCell>

                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Email/Phone</Table.HeaderCell>
                <Table.HeaderCell>Operations</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {locations && (<Table.Body>
              {locations.map((location, index) => {
                const {
                  name,
                  address,
                  email,
                  phone
                } = location.businessProfile
                return (
                  <>
                    <Table.Row>
                      <Table.Cell>
                        <img
                          src={imgUrl}
                          alt=""
                          onError={this.getCustomerLogo(customerIdFromUrl || customer.id)}
                          className="ui mini middle aligned image mr-5"
                        />
                      </Table.Cell>
                      <Table.Cell>{name}</Table.Cell>
                      <Table.Cell>{address}</Table.Cell>
                      <Table.Cell>
                        <p>{phone}</p>
                        <p>{email}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          circular
                          size="mini"
                          icon="pencil alternate"
                          as={Link}
                          to={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}${URL_PREFIX}${location.id}${URL_PREFIX}edit${customerIdFromUrl ? '?customerId=' + customerIdFromUrl : ''}`}
                        />
                        <Button
                          circular
                          size="mini"
                          icon="trash alternate outline alternate"
                          onClick={this.handleDeleteConf(location)}
                        />
                      </Table.Cell>
                    </Table.Row>
                    {isDeleteConfOpen && (
                      <ConfirmationDialogComponent
                        isOpen={isDeleteConfOpen}
                        msg={Messages["delete-location"]}
                        heading="Delete Location?"
                        onConfirm={this.onConfirm}
                        onCancel={this.onCancel}
                        isLoading={isLoading}
                        remoteError={remoteError}
                      />
                    )}
                  </>
                );
              })}
            </Table.Body>)}
          </Table>
          {remoteError && !isDeleteConfOpen && (
            <Message error>
              <Message.Header>Error {remoteError.status}</Message.Header>
              {remoteError.status === 400 && remoteError.validationErrors ? (
                <ul className="list">
                  {remoteError.validationErrors.map((item, key) => (
                    <li key={key} className="content">
                      {item.defaultMessage}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{remoteError.message}</p>
              )}
            </Message>
          )}
        </Segment>
      </div >
    );
  }
}

export default Locations;