import axios from 'axios';
import firebase from '../helpers/firebase';
import config from '../config/constants';

import { logout } from './auth';

const instance = axios.create({
  baseURL: config.api.baseURL,
  timeout: 30000,
  headers: {
    'Accept': 'application/json; charset=utf-8'
  }
});

instance.interceptors.response.use(undefined, error => {
  if (error.response && error.response.status === 401) {
    if (error.config.url.replace(config.api.baseURL, '').startsWith('/users/email/')) {
      // is login
      return Promise.reject(error);
    } else if (error.config && !error.config.__isRetryRequest) {
      if (firebase.auth().currentUser) {
        firebase.auth().currentUser.getIdToken()
          .then(idToken => {
            if (idToken !== instance.defaults.headers.common['Email-Token']) {
              instance.defaults.headers.common['Email-Token'] = idToken;

              error.config.__isRetryRequest = true;
              error.config.headers['Email-Token'] = idToken;
              return instance(error.config);
            }
          });
      } else {
        logout();
        return Promise.reject(error);
      }
    } else {
      logout();
      return Promise.reject(error);
    }
  } else {
    if (error.message === 'Network Error') {
      error.message = 'Could not connect to the server.';
    }
    return Promise.reject(error);
  }
});

export default instance;