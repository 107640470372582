import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Message, Breadcrumb } from "semantic-ui-react";

import CustomerEditor from "./CustomerEditor";

import customerService from "../../services/customer";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";
import URLS, { URL_PREFIX } from "../../services/URLS";

export default class Customer extends Component {
  state = {
    customerEditor: {
      loading: true,
      remoteError: null,
    },
    loaded: false,
    customer: null,
    loadError: null,
  };

  componentDidMount() {
    this.reloadCustomer();
  }

  /**
   * Reload Customer
   */
  reloadCustomer() {
    const { customerEditor } = this.state;

    this.setState({
      customerEditor: {
        ...customerEditor,
        loading: true,
      },

      loaded: false,
    });

    const {
      match: {
        params: { customerId },
      },
    } = this.props;

    customerService
      .getCustomerById(customerId)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: {
              message: Messages["no-responce-from-server"],
            },
          });

          return;
        }

        this.setState({
          customerEditor: {
            ...customerEditor,
            loading: false,
          },
          loaded: true,
          customer: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          customerEditor: {
            ...customerEditor,
            loading: false,
          },
          loadError: convertResponseError(error),
        });
      });
  }

  /**
   * On Submit
   *
   * @param {Object} customer
   */
  onSubmit = (customer) => {
    const {
      customerEditor,
      customer: { id },
    } = this.state;

    this.setState({
      customerEditor: {
        ...customerEditor,
        loading: true,
      },
    });

    customerService
      .updateCustomer(id, customer)
      .then((response) => {
        if (response) {
          this.setState({
            customer: response.data,
            customerEditor: {
              ...customerEditor,
              loading: false,
              remoteError: null,
              remoteSuccess: true,
            },
          });
        } else {
          this.setState({
            customerEditor: {
              ...customerEditor,
              loading: false,
              remoteError: { message: "No response from the server." },
              remoteSuccess: false,
            },
          });
        }
      })
      .catch((error) => {
        this.setState({
          customerEditor: {
            ...customerEditor,
            remoteError: convertResponseError(error),
            remoteSuccess: false,
            loading: false,
          },
        });
      });
  };

  /**
   * Render View
   */
  render() {
    const {
      customer,
      loaded,
      loadError,

      customerEditor: { loading, remoteError, remoteSuccess },
    } = this.state;
    const { user: userData } = this.props;

    const {
      invitationLink,
      user
    } = customer || {};
    const { email: userEmail } = user || {};
    const { email } = invitationLink || {};

    return (
      <div>
        {customer && (
          <Breadcrumb
            icon="right angle"
            sections={[
              {
                key: "bread-1",
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}`}>Customers</Link>,
              },
              {
                key: "bread-2",
                content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customer.id}`}>{email || userEmail || "-"}</Link>,
              },
              {
                key: "bread-3",
                content: "Edit",
                active: true,
              },
            ]}
          />
        )}

        {loadError && (
          <Message error>
            <Message.Header>Error {loadError.status}</Message.Header>
            <p>{loadError.message}</p>
          </Message>
        )}

        {/* Edit Form */}
        <CustomerEditor
          customer={customer}
          loading={loading}
          disabled={!loaded}
          remoteError={remoteError}
          remoteSuccess={remoteSuccess}
          isUpdate={true}
          onSubmit={this.onSubmit}
          user={userData}
        />
      </div>
    );
  }
}
