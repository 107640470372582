import queryString from "query-string";

export function convertResponseError(error) {
  return error.response
    ? error.response.data
      ? error.response.data
      : { message: "Error " + error.response.status }
    : error;
}

/**
 * Get File from Base64
 *
 *  @param {String} Base64
 */
export const getFileFromBase64 = (base_64) => {
  const name = "images.png";
  const arr = base_64.split(",");
  const mime = "image/png";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);

  return new File([u8arr], name, { type: mime });
};

/**
 * Convert Binary to Base64
 *
 * @param {Binary} binaryData
 */
export function convertBinaryToBase64(binaryData) {
  return `data:image/png;base64,${Buffer.from(binaryData, "binary").toString(
    "base64"
  )}`;
}

/**
 * Validate Email
 *
 * @param {String} email 
 * @returns {Boolean || String}
 */
export function validateEmail(email) {
  if (!email) return "Required"

  const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

  if (emailRegex.test(email)) return true;

  return "Invalid Email"
}

/**
 * Get search quries
 * 
 * @returns {Object}
 */
export function getSearchQueries() {
  const splitLocation = window.location.href.split("?");
  const quries = splitLocation.length > 1 ? "?" + splitLocation[1] : "";
  return queryString.parse(quries);
}