import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import PractitionerEditor from './PractitionerEditor';
import practitionerService from '../../services/practitioner';
import { convertResponseError } from '../../helpers/util';


export default class Practitioner extends Component {

  state = {
    practitionerEditor: {
      loading: true,
      remoteError: null,
      remoteSuccess: false
    },
    loaded: false,
    practitioner: null,
    loadError: null,
    customer: null
  };

  componentDidMount() {
    this.reloadPractitioner();
  }

  reloadPractitioner() {
    this.setState({
      practitionerEditor: {
        ...this.state.practitionerEditor,
        loading: true
      },
      loaded: false,
    });

    practitionerService.getPractitionerById(this.props.match.params.practitionerId)
      .then(response => {
        if (response) {
          delete response.data.password;
          this.setState({
            practitionerEditor: {
              ...this.state.practitionerEditor,
              loading: false
            },
            loaded: true,
            practitioner: response.data,
          });
        } else {
          this.setState({
            remoteError: { message: 'No response from the server.' }
          });
        }
      })
      .catch(error => {
        this.setState({
          practitionerEditor: {
            ...this.state.practitionerEditor,
            loading: false
          },
          loadError: convertResponseError(error),
        });
      });
  }

  render() {
    const { practitioner, loaded, loadError } = this.state;

    return (
      <div>
        {loadError &&
          <Message error>
            <Message.Header>Error {loadError.status}</Message.Header>
            <p>{loadError.message}</p>
          </Message>
        }

        <PractitionerEditor
          isProfile={true}
          practitioner={practitioner}
          loading={this.state.practitionerEditor.loading}
          disabled={!loaded}
          remoteError={this.state.practitionerEditor.remoteError}
          remoteSuccess={this.state.practitionerEditor.remoteSuccess}
          isUpdate={true}
          onSubmit={practitioner => {
            practitionerService.updatePractitionerProfile(this.state.practitioner.id, practitioner, this.state.practitioner.customer.id)
              .then(response => {
                response.data.currentPassword = '';
                response.data.password = '';
                if (response) {
                  this.setState({
                    practitioner: response.data,
                    practitionerEditor: {
                      ...this.state.practitionerEditor,
                      loading: false,
                      remoteSuccess: true,
                      remoteError: null
                    },
                  });
                } else {
                  this.setState({
                    practitionerEditor: {
                      ...this.state.practitionerEditor,
                      remoteError: { message: 'No response from the server.' },
                      remoteSuccess: false
                    }
                  });
                }
              })
              .catch(error => {
                this.setState({
                  practitionerEditor: {
                    ...this.state.practitionerEditor,
                    remoteError: convertResponseError(error),
                    remoteSuccess: false,
                    loading: false
                  }
                });
              });
          }}
        />
      </div>
    );
  }
}
