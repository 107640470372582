import React, { Component } from 'react';
import queryString from 'query-string';

import { Grid, Button, Form, Message, Container, Header, Segment, Input, Label } from 'semantic-ui-react'
import { min } from 'moment';

import { convertResponseError } from '../helpers/util';

import practitionerService from '../services/practitioner';


const PractitionerResetPassword = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      password: '',
      practitioner: null,
      loading: false,
      validationErrors: []
    };
  }

  componentDidMount() {
    practitionerService.verifyPractitionerResetPasswordRequest(this.props.match.params.practitionerId, this.getQueries().code)
      .then(response => {
        if (response) {
          this.setState({
            practitioner: response.data.practitioner
          });
        } else {
          this.setState({
            error: { message: 'No response from the server.' },
            success: null
          });
        }
      })
      .catch(error => {
        this.setState({
          error: convertResponseError(error),
          success: null
        });
      });
  }

  getSearch(link) {
    const splitLocation = window.location.href.split('?');
    return splitLocation.length > 1 ? ('?' + splitLocation[1]) : '';
  }

  getQueries() {
    return queryString.parse(this.getSearch(window.location.href));
  }

  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : '';
    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + '.', root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  validateOne(field, fields) {
    const schema = practitionerService.resetPasswordSchema;
    schema.validateAt(field, fields)
      .catch(error => {
        this.setState(state => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0]
          }
        }));
      });
  }

  render() {
    const { error, success, practitioner, password, loading, 
      validationErrors } = this.state;

    return (
      <Container text>
        <Header as='h3' attached='top'>RESET PIN</Header>
        <Segment attached>
          {error && 
            <Message negative>
              <Message.Header>ERROR</Message.Header>
              <p>{error.message}</p>
            </Message>}
          {success &&
            <Message positive>
              <Message.Header>SUCCESS</Message.Header>
              <p>{success.message}</p>
            </Message>}
          {!success && practitioner && 
            <Message>
              <p>Please create a new PIN for {practitioner.email}. The PIN should be 6-15 digits.</p>
            </Message>}
          {!success && practitioner === null && error == null &&
            <Message>
              <p>Verifying your token...</p>
            </Message>}
          {!success && practitioner && 
            <Form 
            loading={loading}
              error={validationErrors || error ? true : false} 
              >
              <Form.Field>
                <Input 
                  value={password}
                  error={validationErrors['password'] ? true : false}
                  onChange={(e, { value }) => {
                    this.setState({
                      password: value
                    });
                  }} 
                  type='password'
                  placeholder='PIN' />
                  {validationErrors['password'] && 
                    <Label basic color='red' pointing>{validationErrors['password']}</Label>
                  }
              </Form.Field>
              <Button 
                type='submit'
                onClick={() => {
                  const schema = practitionerService.resetPasswordSchema;

                  const fields = {
                    password
                  };

                  this.validateAll(fields);

                  schema.isValid(fields).then(valid => {
                    if (valid) {
                      this.setState({
                        loading: true
                      });
              
                      practitionerService.resetPassword(practitioner.id, practitioner.customer.id, 
                          this.getQueries().code, password)
                        .then(response => {
                          if (response) {
                            this.setState({
                              practitioner: response.data,
                              loading: false,
                              error: null,
                              success: true
                            });
                          } else {
                            this.setState({
                              error: { message: 'No response from the server.' },
                              success: false
                            });
                          }
                        })
                        .catch(error => {
                          this.setState({
                            error: convertResponseError(error),
                            success: false,
                            loading: false
                          });
                        });
                    }
                  });
                }}>
                  SET PIN
                </Button>
            </Form>}
        </Segment>
      </Container>
    );
  }
}

export default PractitionerResetPassword;