
const local = {
  versionSuffix: 'local',
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_LOCAL,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_LOCAL,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_LOCAL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_LOCAL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_LOCAL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_LOCAL,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_LOCAL
  },
  api: {
    baseURL: process.env.REACT_APP_API_BASEURL_LOCAL
  }
};

const dev = {
  versionSuffix: 'dev',
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DEV,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_DEV,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_DEV
  },
  api: {
    baseURL: process.env.REACT_APP_API_BASEURL_DEV
  }
};

const staging = {
  versionSuffix: 'staging',
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_STAGING,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_STAGING,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_STAGING,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_STAGING,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_STAGING,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_STAGING,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_STAGING
  },
  api: {
    baseURL: process.env.REACT_APP_API_BASEURL_STAGING
  }
};

const prod = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  },
  api: {
    baseURL: process.env.REACT_APP_API_BASEURL
  }
};

const config = process.env.REACT_APP_STAGE === 'staging' ? staging : (process.env.REACT_APP_STAGE === 'dev' ? dev : local);

export default config;
