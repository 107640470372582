import React, { Component } from 'react';
import { Button, Header, Form, Input, Segment, Label, Message, Checkbox } from 'semantic-ui-react';
import practitionerService from '../../services/practitioner';
import Messages from '../../config/messages';
import { shape, bool } from "prop-types";

import 'react-phone-input-2/dist/style.css'

const PRACTITIONERS = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  contactEmail: '',
  role: Messages["role-practitioner"]
}

class PractitionerEditor extends Component {
  state = {
    isAdding: false,
    isProfile: false,
    loading: false,
    disabled: false,
    practitioner: { ...PRACTITIONERS },
    validationErrors: {},
    remoteError: null,
    remoteSuccess: null,
  }

  componentDidMount() {
    const {
      loading,
      disabled,
      practitioner,
      remoteError,
      remoteSuccess,
      isAdding,
      isProfile
    } = this.props;

    setTimeout(() => {
      this.setState({
        loading,
        disabled,
        remoteError,
        remoteSuccess,
        isAdding,
        isProfile
      })

      if (practitioner && practitioner.user) {
        const { user: { contactDetail }, user, id, initStatus } = practitioner;
        const { email, role } = user;
        this.setState(state => ({
          practitioner: {
            ...state.practitioner,
            id,
            email,
            role,
            initStatus
          }
        }));

        if (contactDetail) {
          const { firstName, lastName, phone, email } = contactDetail;
          this.setState(state => ({
            practitioner: {
              ...state.practitioner,
              firstName,
              lastName,
              phone,
              contactEmail: email
            }
          }));
        }
      }
    }, 300);

  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      disabled,
      practitioner,
      remoteError,
      remoteSuccess,
      isAdding,
      isProfile
    } = this.props;

    if (loading !== prevProps.loading) this.setState({ loading });

    if (disabled !== prevProps.disabled) this.setState({ disabled });

    if (
      practitioner &&
      JSON.stringify(practitioner) !== JSON.stringify(prevProps.practitioner)
    )
      this.setState({ practitioner });

    if (remoteError !== prevProps.remoteError) this.setState({ remoteError });

    if (remoteSuccess !== prevProps.remoteSuccess)
      this.setState({ remoteSuccess });

    if (isAdding !== prevProps.isAdding)
      this.setState({ isAdding });

    if (isProfile !== prevProps.isProfile)
      this.setState({ isProfile });

  }

  /**
   * Handle submit
   * 
   * @param {Event} e 
   */
  handleSubmit = e => {
    e.preventDefault();
    const { practitioner } = this.state;
    const schema = this.props.isUpdate ? practitionerService.updateSchema : practitionerService.addSchema;

    this.setState({
      validationErrors: {},
      remoteError: null
    });

    const fields = {
      firstName: practitioner.firstName,
      lastName: practitioner.lastName,
      email: practitioner.email,
      phone: practitioner.phone,
      role: practitioner.role ? practitioner.role : Messages["role-practitioner"]
    };

    if (this.state.isProfile) {
      fields.password = practitioner.password === null ||
        (typeof practitioner.password !== 'undefined' && practitioner.password.trim() === '') ? null : practitioner.password;
      fields.repeatPassword = practitioner.repeatPassword;
      fields.currentPassword = practitioner.setPasswordRequired ?
        'do not need current password for new practitioner' :
        (practitioner.currentPassword === null ? '' : practitioner.currentPassword);
    }

    if (this.props.isAdding) {
      delete fields.phone;
    }

    this.validateAll(fields);

    schema.isValid(fields)
      .then(valid => {
        if (valid) {
          this.props.onSubmit(fields);
        }
      });
  };

  /**
   * Send reset password email
   */
  sendResetPasswordEmail() {
    this.setState({
      sendResetPasswordEmailStatus: 'sending'
    });
    if (this.state.practitioner.initStatus === 'COMPLETE') {
      practitionerService.sendResetPasswordEmail(this.state.practitioner.email)
        .then(response => {
          if (response) {
            this.setState({
              sendResetPasswordEmailStatus: 'sent'
            });
          } else {
            this.setState({
              sendResetPasswordEmailStatus: 'error'
            });
          }
        })
        .catch(error => {
          this.setState({
            sendResetPasswordEmailStatus: 'error'
          });
        });
    } else {
      practitionerService.resendInvitationEmail(this.state.practitioner.id)
        .then(response => {
          if (response) {
            this.setState({
              sendResetPasswordEmailStatus: 'sent'
            });
          } else {
            this.setState({
              sendResetPasswordEmailStatus: 'error'
            });
          }
        })
        .catch(error => {
          this.setState({
            sendResetPasswordEmailStatus: 'error'
          });
        });
    }
  }

  /**
    * Validate All
    *
    * @param {Object} fields
    * @param {String} parentPath
    * @param {Object} root
    */
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : '';
    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + '.', root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Validate one
   * 
   * @param {Object} field 
   * @param {Object} fields 
   */
  validateOne(field, fields) {
    const schema = this.props.isUpdate ? practitionerService.updateSchema : practitionerService.addSchema;
    schema.validateAt(field, fields)
      .catch(error => {
        this.setState(state => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0]
          }
        }));
      });
  }

  /**
   * Set Practitioner State
   * 
   * @param {String} field 
   * @param {Event} event 
   */
  setPractitionerState(field, event) {
    const { value } = event.target;
    this.setPractitionerStateWithValue(field, value);
  }

  /**
   * Set Practitioner State with value
   * 
   * @param {String} field 
   * @param {Event} event 
   */
  setPractitionerStateWithValue(field, value) {
    this.setState(state => ({
      practitioner: {
        ...state.practitioner,
        [field]: value
      }
    }));
  }

  render() {
    const {
      practitioner,
      loading,
      disabled,
      validationErrors,
      remoteError,
      isProfile,
      isAdding,
      remoteSuccess,
      sendResetPasswordEmailStatus
    } = this.state;

    const { isUpdate } = this.props

    const {
      email,
      firstName,
      lastName,
      phone,
      contactEmail
    } = practitioner;

    return (
      <div>
        <Form
          loading={loading}
          error={remoteError ? true : false}
          onSubmit={event => this.handleSubmit(event)}
        >
          <Segment.Group>
            <Segment disabled={disabled}>
              <Header as='h3'>{isProfile ? 'My Profile' : 'Practitioner Info'}</Header>

              <Form.Field
                error={Boolean(validationErrors['email'])}
              >
                <label>Email Address *</label>
                <Input
                  placeholder='Email Address'
                  value={email}
                  type='email'
                  onChange={(event) => this.setPractitionerState('email', event)}
                  disabled={!isAdding || disabled}
                />
                {validationErrors['email'] &&
                  <Label basic color='red' pointing>{validationErrors['email']}</Label>
                }
              </Form.Field>

              <Form.Group widths='equal'>
                <Form.Field
                  error={Boolean(validationErrors['firtsName'])}
                >
                  <label>First Name {isUpdate && <>*</>}</label>
                  <Input
                    placeholder='First Name'
                    value={firstName}
                    onChange={(event) => this.setPractitionerState('firstName', event)}
                    disabled={disabled}
                  />
                  {validationErrors['firstName'] &&
                    <Label basic color='red' pointing>{validationErrors['firstName']}</Label>
                  }
                </Form.Field>
                <Form.Field
                  error={Boolean(validationErrors['lastName'])}
                >
                  <label>Last Name {isUpdate && <>*</>}</label>
                  <Input
                    placeholder='Last Name'
                    value={lastName}
                    onChange={(event) => this.setPractitionerState('lastName', event)}
                    disabled={disabled}
                  />
                  {validationErrors['lastName'] &&
                    <Label basic color='red' pointing>{validationErrors['lastName']}</Label>
                  }
                </Form.Field>
              </Form.Group>
              {isUpdate && (<Form.Group widths='equal'>
                <Form.Field
                  error={Boolean(validationErrors['phone'])}
                >
                  <label>Phone  *</label>
                  <Input
                    type='text'
                    placeholder='Phone'
                    value={phone}
                    onChange={(event) => this.setPractitionerState('phone', event)}
                    disabled={disabled}
                  />
                  {validationErrors['phone'] &&
                    <Label basic color='red' pointing>{validationErrors['phone']}</Label>
                  }
                </Form.Field>
                <Form.Field
                  error={Boolean(validationErrors['contactEmail'])}
                >
                  <label>Email *</label>
                  <Input
                    type='text'
                    placeholder='Email'
                    value={contactEmail}
                    onChange={(event) => this.setPractitionerState('contactEmail', event)}
                    disabled={disabled}
                  />
                  {validationErrors['contactEmail'] &&
                    <Label basic color='red' pointing>{validationErrors['contactEmail']}</Label>
                  }
                </Form.Field>
              </Form.Group>)}

              {(practitioner && !isProfile && !isAdding) &&
                <Form.Field>
                  <label>PIN</label>
                  {(typeof sendResetPasswordEmailStatus === 'undefined' || sendResetPasswordEmailStatus === 'error') &&
                    <Button
                      type="button"
                      onClick={() => this.sendResetPasswordEmail()}>
                      {sendResetPasswordEmailStatus === 'error' ? 'Error' : `${practitioner.initStatus === 'COMPLETE' ? 'Send Reset PIN' : 'Resend Invitation'} Email`}
                    </Button>}
                  {sendResetPasswordEmailStatus === 'sending' &&
                    <Button
                      type="button"
                      disabled>
                      Sending...
                      </Button>}
                  {sendResetPasswordEmailStatus === 'sent' &&
                    <Button
                      type="button"
                      disabled>
                      Reset PIN Email Email Sent
                      </Button>}
                </Form.Field>}

              {isProfile &&
                <div>
                  {practitioner && !practitioner.setPasswordRequired &&
                    <div className="ui two column grid">
                      <Form.Field
                        error={validationErrors['currentPassword'] ? true : false}
                        className="column"
                        style={{ margin: '0 -.5em 1em 0', paddingRight: '0.5em' }}
                      >
                        <label>Current PIN</label>
                        <Input
                          type='password'
                          placeholder='Current PIN'
                          value={practitioner && practitioner.currentPassword !== null ? practitioner.currentPassword : ''}
                          onChange={(event) => this.setPractitionerState('currentPassword', event)}
                          disabled={disabled}
                        />
                        {validationErrors['currentPassword'] &&
                          <Label basic color='red' pointing>{validationErrors['currentPassword']}</Label>
                        }
                      </Form.Field>
                    </div>}
                  <Form.Group widths='equal'>
                    <Form.Field
                      error={validationErrors['password'] ? true : false}
                    >
                      <label>PIN {(this.props.isResetPassword || (practitioner && practitioner.setPasswordRequired)) && <span>*</span>}</label>
                      <Input
                        type='password'
                        placeholder='PIN'
                        value={practitioner && practitioner.password !== null ? practitioner.password : ''}
                        onChange={(event) => this.setPractitionerState('password', event)}
                        disabled={disabled}
                      />
                      {validationErrors['password'] &&
                        <Label basic color='red' pointing>{validationErrors['password']}</Label>
                      }
                    </Form.Field>
                    <Form.Field
                      error={validationErrors['repeatPassword'] ? true : false}
                    >
                      <label>Confirm PIN {(this.props.isResetPassword || (practitioner && practitioner.setPasswordRequired)) && <span>*</span>}</label>
                      <Input
                        type='password'
                        placeholder='Confirm PIN'
                        value={practitioner && typeof practitioner.repeatPassword !== 'undefined' ? practitioner.repeatPassword : ''}
                        onChange={(event) => this.setPractitionerState('repeatPassword', event)}
                        disabled={disabled}
                      />
                      {validationErrors['repeatPassword'] &&
                        <Label basic color='red' pointing>{validationErrors['repeatPassword']}</Label>
                      }
                    </Form.Field>
                  </Form.Group>
                  {practitioner && !practitioner.setPasswordRequired &&
                    <div>Please leave PINs blank if you don't want to change.</div>
                  }
                </div>}

              {!isProfile &&
                <Form.Field
                  error={validationErrors['role'] ? true : false}
                >
                  <label>Administrator?</label>
                  <Checkbox
                    toggle
                    onChange={() => {
                      this.setPractitionerStateWithValue('role', practitioner.role === Messages["role-admin-practitioner"] ? Messages["role-practitioner"] : Messages["role-admin-practitioner"]);
                    }}
                    checked={practitioner ? practitioner.role === Messages["role-admin-practitioner"] : false}
                    disabled={practitioner ? practitioner.defaultPractitioner : false}
                  />
                  {validationErrors['role'] &&
                    <Label basic color='red' pointing>{validationErrors['role']}</Label>
                  }
                </Form.Field>}
            </Segment>

            <Segment>
              {remoteError &&
                <Message error>
                  <Message.Header>Error {remoteError.status}</Message.Header>
                  {remoteError.status === 400 && remoteError.validationErrors ?
                    <ul className="list">
                      {remoteError.validationErrors.map((item, key) =>
                        <li key={key} className="content">{item.defaultMessage}</li>
                      )}
                    </ul> :
                    <p>{remoteError.message}</p>
                  }
                </Message>
              }
              {remoteSuccess &&
                <Message positive>
                  <Message.Header>Success</Message.Header>
                  <p>Information updated successfully!</p>
                </Message>}

              <Button
                primary
                type="submit"
                disabled={disabled}
              >
                Submit
              </Button>
            </Segment>
          </Segment.Group>
        </Form>
      </div>
    )
  }
}

PractitionerEditor.propTypes = {
  practitioner: shape({}),
  disabled: bool,
  loading: bool,
  remoteError: shape({}),
  remoteSuccess: shape({}),
  isAdding: bool,
  isProfile: bool
};

PractitionerEditor.defaultProps = {
  practitioner: {},
  disabled: false,
  loading: false,
  remoteError: null,
  remoteSuccess: null,
  isAdding: false,
  isProfile: false
};

export default PractitionerEditor;