import messages from '../config/messages';
import firebase from '../helpers/firebase';
import request from '../helpers/request';

const authResultListeners = {};

export function logout() {
  return firebase.auth().signOut();
}

export function login(email, pw) {
  return firebase.auth().signInWithEmailAndPassword(email, pw);
}

export function resetPassword(email) {
  return firebase.auth().sendPasswordResetEmail(email);
}

export function addListenerToAuth(name, handler) {
  authResultListeners[name] = handler;
}

export function removeListenerFromAuth(name) {
  delete authResultListeners[name];
}

function loginWithToken(email, idToken) {
  request.defaults.headers.common['Email-Token'] = idToken;
  request.get('/users/email/' + email)
    .then(response => {
      if (response) {
        const user = response.data;
        if (user.role === messages["role-customer"]) {
          request.get('/users/' + user.id + '/customer')
            .then(response => {
              if (response) {
                user.customer = response.data;
                if (user.customer.initStatus !== 'COMPLETE') {
                  user.eligibleToMigrate = true;
                }
                for (const handler of Object.values(authResultListeners)) {
                  handler(null, user);
                }
              } else {
                for (const handler of Object.values(authResultListeners)) {
                  handler({
                    message: 'No response from the server.'
                  });
                }
              }
            })
            .catch(error => {
              for (const handler of Object.values(authResultListeners)) {
                handler({
                  message: error.response ? error.response.data : error.message
                });
              }
            });
        } else {
          for (const handler of Object.values(authResultListeners)) {
            handler(null, user);
          }
        }
      }
    })
    .catch(error => {
      var e = {
        message: `Unknown Error.`
      };
      if (error.response && error.response.status === 401) {
        // e = {
        //   message: 'You are not authorised to use this system.'
        // };
        for (const handler of Object.values(authResultListeners)) {
          handler(null, {
            eligibleToMigrate: true,
            email
          });
        }
        return;
      } else if (error.respnse) {
        e = {
          message: `Failed to login (${error.response.status}).`
        };
      } else if (error.message) {
        e = {
          message: `${error.message}.`
        };
      }

      for (const handler of Object.values(authResultListeners)) {
        handler(e);
      }

      logout();
    });
}

export function autoLogin(email, idToken) {
  loginWithToken(email, idToken);
};

export function registerAuthStateChangeListener() {
  firebase.auth().onAuthStateChanged(firebaseUser => {
    if (firebaseUser) {
      console.log('already login ', firebaseUser.email)
      firebaseUser.getIdToken()
        .then(idToken => {
          loginWithToken(firebaseUser.email, idToken)
        });
    } else {
      for (const handler of Object.values(authResultListeners)) {
        handler();
      }
    }
  });
}