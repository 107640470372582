import React, { Component } from 'react';

import { func, shape } from 'prop-types';

import {
  Form,
  Input,
  Button,
  Modal,
  Grid,
  Header,
  Label
} from 'semantic-ui-react';
import customerService from "../../services/customer"
import Messages from '../../config/messages';

class ResendInvitationEmail extends Component {
  state = {
    disabled: true,
    isLoading: false,
    email: '',
    validationErrors: {}
  }

  componentDidMount() {
    const { user = '', customer: {
      invitationLink: { email }
    }, } = this.props;

    this.setState({ email });

    if (user.role === Messages["role-admin"]) {
      this.setState({ disabled: false });
    }
  }
  /**
   * On input change 
   *
   * @param {Event} event 
   * @param {String} name
   * @param {String} Value 
   */
  onChangeInput = (event, { name, value }) => {
    this.setState({
      [name]: value
    });
  }

  /**
 * Validate All
 *
 * @param {Object} fields
 * @param {Sting} parentPath
 * @param {Object} root
 */
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : "";

    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + ".", root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    customerService.invitationLinkSchema.validateAt(field, fields).catch((error) => {
      this.setState((state) => ({
        validationErrors: {
          ...state.validationErrors,
          [field]: error.errors[0],
        },
      }));
    });
  }

  /**
   * On Invitatin Send
   *
   * @param {Object} customer
   */
  onInvitationSend = async () => {
    const { customer } = this.props;

    let updateCustomer = { ...customer };

    const { id } = updateCustomer;
    const { email } = this.state

    const {
      INIT_STATUS: { resend, sent, sending },
    } = customerService;
    const fields = { email }
    this.setState({ validationErrors: {} })
    const isValid = await customerService.invitationLinkSchema.isValid(fields);
    if (!isValid) {
      this.validateAll(fields);
      return;
    }
    this.setState({ isLoading: true })

    updateCustomer.sendingText = sending.value;

    customerService
      .reSendInvitation(fields, id)
      .then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          updateCustomer.sendingText = resend.key;
          return;
        }
        response.data.sendingText = sent.key
        updateCustomer = response.data

        this.props.updateCustomer(updateCustomer)

        this.props.onCancel();
      })
      .catch(() => {
        this.setState({ isLoading: false })
        updateCustomer.sendingText = resend.key;
      });
  };

  /**
   * Render View
   */
  render() {
    const {
      onCancel,
    } = this.props;

    const {
      disabled,
      email,
      validationErrors,
      isLoading
    } = this.state;

    return (
      <Modal
        open={true}
        size='small'
      >
        <Modal.Header>RESEND INVITATION EMAIL</Modal.Header>

        <Modal.Content>
          <Form>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={4}>
                  <Header as='h5' className="pt-10">Email*</Header></Grid.Column>
                <Grid.Column width={12}>
                  <Form.Field error={Boolean(validationErrors["email"])}>
                    <Input
                      fluid
                      placeholder='Email'
                      name='email'
                      value={email}
                      onChange={this.onChangeInput}
                      autoComplete='off'
                      disabled={disabled}
                    />
                    {Boolean(validationErrors["email"]) && (
                      <Label basic color="red" pointing>
                        {validationErrors["email"]}
                      </Label>
                    )}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form >
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary disabled={isLoading} loading={isLoading} onClick={this.onInvitationSend}>Send</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ResendInvitationEmail.propTypes = {
  onCancel: func,
  onInvitationSend: func,
  updateCustomer: func,
  customer: shape({}),
  user: shape({}),
};

ResendInvitationEmail.defaultProps = {
  onCancel: () => { },
  onInvitationSend: () => { },
  updateCustomer: () => { },
  customer: {},
  user: {},
};

export default ResendInvitationEmail;