import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  Segment,
  Message,
  Breadcrumb,
  Label
} from 'semantic-ui-react';
import ConfirmationDialogComponent from './ConfirmationDialogComponent';
import customerService from '../../services/customer';
import practitionerService from '../../services/practitioner';
import URLS, { URL_PREFIX } from '../../services/URLS';
import { convertResponseError } from '../../helpers/util';
import Messages from '../../config/messages';

export default class PractitionerList extends Component {
  state = {
    customer: null,
    practitioners: [],
    loading: true,
    loaded: false,
    deleteModalOpen: false,
    currentSelected: {},
    deleteModalError: null
  };

  componentDidMount() {
    const {
      match: { params: { customerId } }
    } = this.props;

    customerService.getCustomerById(customerId)
      .then(response => {
        if (response) {
          this.setState({
            customer: response.data
          });
        } else {
          this.setState({
            error: { message: 'No response from the server.' }
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          loaded: false,
          error: convertResponseError(error)
        });
      });

    this.reloadTable(customerId);

    if (window.jsInterface) {
      window.jsInterface.onPageLevelChanged(1, window.location.href);
    }
  }

  /**
   * Reload table
   * 
   * @param {String} customerId 
   */
  reloadTable = customerId => {
    this.setState({
      loading: true,
      loaded: false,
      error: null
    });

    practitionerService.getAllPractitioners(customerId)
      .then(response => {
        if (response) {
          this.setState({
            practitioners: response.data,
            loading: false,
            loaded: true,
            error: null
          });
        } else {
          this.setState({
            error: { message: 'No response from the server.' }
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          loaded: false,
          error: convertResponseError(error)
        });
      });
  }

  /**
   * Open delete modal
   * 
   * @param {Object} practitioner 
   */
  openDeleteModal = (practitioner) => {
    this.setState({
      deleteModalOpen: true,
      currentSelected: practitioner,
      deleteModalError: null
    });
  }

  /**
   * Close delete modal
   */
  closeDeleteModal = () => {
    this.setState({
      deleteModalOpen: false,
      currentSelected: {}
    });
  }

  /**
   * Delete or restore practitioner
   */
  deleteOrRestore = () => {
    const { currentSelected } = this.state;
    this.setState({ loading: true })

    practitionerService.patchPractitioner(currentSelected.id, { deleted: !currentSelected.deleted })
      .then(response => {
        if (response) {
          this.setState({ loading: false })
          const practitioners = this.state.practitioners.map(practitioner => {
            if (practitioner.id === response.data.id) {
              return response.data;
            } else {
              return practitioner;
            }
          });
          this.setState({
            practitioners: practitioners
          });
          this.closeDeleteModal();
        } else {
          this.setState({
            remoteError: { message: 'No response from the server.' },
          });
        }
      })
      .catch(error => {
        this.setState({
          deleteModalError: convertResponseError(error),
          loading: false,
        });
      });
  }

  /**
   * Resend invitation email
   * 
   * @param {Object} practitioner 
   */
  resendInvitationEmail(practitioner) {
    this.changeResendInvitationEmailState(practitioner.id, 'sending');
    practitionerService.resendInvitationEmail(practitioner.id)
      .then(response => {
        if (response) {
          this.changeResendInvitationEmailState(practitioner.id, 'sent');
        } else {
          this.changeResendInvitationEmailState(practitioner.id, 'error');
        }
      })
      .catch(error => {
        this.changeResendInvitationEmailState(practitioner.id, 'error');
      });
  }

  changeResendInvitationEmailState(practitionerId, resendInvitationEmailState) {
    this.setState(state => {
      const practitioners = state.practitioners.map(item => {
        if (item.id === practitionerId) {
          item.resendInvitationEmail = resendInvitationEmailState;
        }
        return item;
      })

      return {
        practitioners
      }
    });
  }

  render() {
    const {
      match: {
        params: {
          customerId
        }
      },
      location: {
        search
      }
    } = this.props;

    const { error, deleteModalError, practitioners, currentSelected, customer } = this.state;
    const { user: customerUser } = customer || {};
    const { contactDetail: customerContact } = customerUser || {};
    const { firstName: customerFirstName = "" } = customerContact || {}
    const { user } = currentSelected || {};
    const { contactDetail } = user || {};
    const { firstName = '' } = contactDetail || {};

    const practitionerItems = practitioners.map((item) =>
      <Table.Row key={item.id}>
        <Table.Cell>
          {item && item.user && item.user.email && <>{item.user.email}&nbsp;</>}
          {item.role === Messages["role-admin"] && <Label size='mini' color='teal' horizontal>ADMIN</Label>}
          {item.deleted ? <Label size='mini' color='red' horizontal>DELETED</Label> :
            (item.setPasswordRequired ? <Label size='mini' color='purple' horizontal>INVITED</Label> : '')}
        </Table.Cell>
        {item && item.user && item.user.contactDetail && (

          <Table.Cell>{item.user.contactDetail.firstName} {item.user.contactDetail.lastName}</Table.Cell>
        )}
        {item && item.user && !item.user.contactDetail && (
          <Table.Cell>--</Table.Cell>
        )}
        <Table.Cell>
          <Button
            as={Link} to={`${URL_PREFIX}${URLS.PRACTITIONERS}${URL_PREFIX}${item.id}${search}`}
            circular size='mini'
            icon='pencil alternate' />
          <Button
            as={Button}
            circular
            size='mini'
            onClick={() => this.openDeleteModal(item)}
            icon={!item.deleted ? 'delete' : 'redo'} />
          {!item.deleted && item.initStatus !== 'COMPLETE' &&
            (typeof item.resendInvitationEmail === 'undefined' || item.resendInvitationEmail === 'error') &&
            <Button
              size='mini'
              circular
              onClick={() => this.resendInvitationEmail(item)}>
              {item.resendInvitationEmail === 'error' ? 'Error' : 'Resend Invitation Email'}
            </Button>}
          {!item.deleted && item.initStatus !== 'COMPLETE' &&
            item.resendInvitationEmail === 'sending' &&
            <Button
              size='mini'
              circular
              disabled>
              Sending...
              </Button>}
          {!item.deleted && item.initStatus !== 'COMPLETE' &&
            item.resendInvitationEmail === 'sent' &&
            <Button
              size='mini'
              circular
              disabled>
              Invitation Email Sent
              </Button>}
        </Table.Cell>
      </Table.Row>
    );
    return (
      <div>

        {currentSelected && (
          <ConfirmationDialogComponent
            isOpen={this.state.deleteModalOpen}
            onConfirm={this.deleteOrRestore}
            onCancel={this.closeDeleteModal}
            msg={currentSelected && !currentSelected.deleted ? Messages["delete-practitioner"].replace('#FIRSTNAME#', firstName) : Messages["restore-practitioner"].replace('#FIRSTNAME#', firstName)}
            heading={currentSelected && !currentSelected.deleted ? "Delete Practitioner?" : "Restore Practitioner?"}
            remoteError={deleteModalError}
            isLoading={this.state.loading}
            restore={currentSelected.deleted}
          />
        )}

        {this.props.user.role === Messages["role-admin"] &&
          <Breadcrumb icon='right angle' sections={[
            {
              key: 'bread-1',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${search}`}>
                Customers
              </Link>
            },
            {
              key: 'bread-2',
              content: <Link to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customerId}${search}`}>
                {customerFirstName}
              </Link>
            },
            {
              key: 'bread-3',
              content: 'Practitioners',
              active: true
            }
          ]} />}

        {error &&
          <Message error>
            <Message.Header>Error {error.status}</Message.Header>
            <p>{error.message}</p>
          </Message>
        }

        <Button
          as={Link}
          to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${customerId}${URL_PREFIX}${URLS.PRACTITIONERS}${URL_PREFIX}add${search}`}
          primary
        >Add Practitioner</Button>

        <Segment loading={this.state.loading} disabled={!this.state.loaded} className="fake-segment">
          <Table sortable singleLine size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {practitionerItems}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}
