import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import {
  addListenerToAuth,
  removeListenerFromAuth,
  login,
  resetPassword,
} from "../helpers/auth";
import URLS, { URL_PREFIX } from "../services/URLS";

import { Grid, Button, Form, Message } from "semantic-ui-react";
import { Link } from 'react-router-dom';

const Login = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginMessage: null,
      loading: false,
    };
  }

  /**
   * Handle Submit
   * 
   * @param {Event} e 
   */
  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
      loginMessage: null,
    });

    login(this.state.email, this.state.password)
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loginMessage: error.message,
          loading: false,
        });
      });
  };

  /**
   * Reset Password
   */
  resetPassword = () => {
    resetPassword(this.state.email)
      .then(() => {
        this.setState({
          loginMessage: `Password reset email sent to ${this.state.email}.`,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loginMessage: `Email address not found.`,
          loading: false,
        });
      });
  };

  componentDidMount() {
    addListenerToAuth("Login", (error, user) => {
      if (error) {
        this.setState({
          loginMessage: error.message,
          loading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    removeListenerFromAuth("Login");
  }

  /**
   * Render View
   */
  render() {
    if (this.props.authed) {
      if ((window.location.search && window.location.search.includes(URLS.UPGRADE_TO_CLOUD))) {
        return (<Redirect to={`${URL_PREFIX}${URLS.UPGRADE_TO_CLOUD}`} />);
      } else {
        return (<Redirect to={`${URL_PREFIX}`} />);
      }
    } else {
      return (
        <Grid centered>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Form
              loading={this.state.loading}
              onSubmit={(event) => this.handleSubmit(event)}
            >
              <Form.Field
                control="input"
                label="Email"
                placeholder="Email"
                type="email"
                onChange={(event) => this.setState({ email: event.target.value })}
              />
              <Form.Field
                control="input"
                type="password"
                label="Password"
                placeholder="Password"
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
              />
              {this.state.loginMessage && (
                <Message
                  error
                  visible
                  header="Error"
                  content={this.state.loginMessage}
                />
              )}
            <Button primary fluid size="huge" type="submit">
              Login
            </Button>
            <Link to={`${URL_PREFIX}auth${URL_PREFIX}forget-password`} className="block-text-right">Forget password?</Link>
          </Form>
        </Grid.Column>
      </Grid>
    );
};
  }}

export default Login;
