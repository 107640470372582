import request from '../helpers/request';
import * as Yup from 'yup';

/**
 * Device Type For Add Device
 */
const DEVICE_TYPES = [
  {
    text: 'Push-IT',
    value: 'PUSH'
  },
  {
    text: 'Stomp-IT',
    value: 'PLATE'
  },
  {
    text: 'Pull-IT',
    value: 'PULL'
  },
];

/**
 * Device Type Filter
 */
const DEVICE_TYPES_FILTER = [
  {
    text: 'Push-IT',
    value: 'PUSH',
    key: 'push',
  },
  {
    text: 'Stomp-IT',
    value: 'PLATE',
    key: 'plate',
  },
  {
    text: 'Pull-IT',
    value: 'PULL',
    key: 'pull',
  },
  {
    text: 'ALL',
    value: 'ALL',
  },
];

/**
 * Config For Pagination Drop Down
 */
const DEVICES_PER_PAGE = [10, 20, 30, 50, 100].map(value => ({
  key: value,
  text: value,
  value: value
}));

/**
 * Default Pagination
 */
const DEFAULT_PAGINATION = {
  page: 1,
  size: 20,
  totalPages: 10,
  totalElements: 20,
  sortDirection: 'descending',
  sortBy: null
};

/**
 * Schema for Validation
 */
const deviceSchema = Yup.object().shape({
  type: Yup.string()
    .required('Type Required'),
  macAddress: Yup.string()
    .required('Mac Address Required'),
  comment: Yup.string()
    .nullable(),
  id: Yup.string()
    .nullable()
});

/**
 * Hanlde Device Type String
 * It Exclude ALL from String
 * 
 * @param {String} deviceTypeString
 * 
 * @return String  
 */
const handleDeviceTypeString = (deviceTypeString) => {
  if (deviceTypeString.includes('ALL')) {
    deviceTypeString = deviceTypeString.substr(0, deviceTypeString.lastIndexOf(","));
  }

  return deviceTypeString;
}

export default {
  DEVICE_TYPES,
  deviceSchema,
  DEVICES_PER_PAGE,
  DEFAULT_PAGINATION,
  DEVICE_TYPES_FILTER,
  handleDeviceTypeString,

  getDevices: function (url, params = {}) {
    return request.get(url, { params });
  },

  getStatistics: function () {
    return request.get('/statistics/devices');
  },

  addDevice: function (params = {}) {
    return request.post('/devices', params);
  },

  deleteDevice: function (id) {
    return request.delete(`/devices/${id}`);
  },

  updateDevice: function (id, params) {
    return request.put(`/devices/${id}`, params);
  },

}
