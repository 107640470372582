import React, { Component } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Message,
  TextArea,
} from "semantic-ui-react";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";
import customerService from "../../services/customer";

class BusinessLocation extends Component {
  state = {
    fields: [],
    emailErrors: {},
    validationErrors: {},
    bLocation: false,
    remoteError: null,
    isLoading: false,
    tempArray: [],
  };

  componentDidMount = () => {
    this.getLocationsById();
  };

  /**
   * Get location
   */
  getLocationsById = () => {
    const {
      customerId
    } = this.props;

    this.setState({ isLoading: true });

    customerService.getBusinessLocationById(customerId)
      .then(response => {
        this.setState({ isLoading: false });
        if ((response && !response.data.length) || !response) {
          this.handleAddMoreLocation();
          return
        }
        const locations = response.data;
        const fields = locations.map(location => {
          const { id, businessProfile } = location;
          const { name, phone, address, email } = businessProfile;
          return {
            name,
            phone,
            email,
            address,
            id,
          }
        })

        this.setState({ fields });
        this.props.moreLocations(fields);
      })
      .catch(error => {
        this.setState({ isLoading: false });
        this.handleAddMoreLocation();
      })
  }

  /**
   * Save and Continue
   *
   * @param {Event} event
   */
  saveAndContinue = async (event) => {
    event.preventDefault();

    this.setState({
      validationErrors: {},
      remoteError: null,
      isLoading: false,
      tempArray: [],
      emailErrors: {},
    });

    const {
      values: { businessLocation = [] },
    } = this.props;

    businessLocation.forEach((location) => {
      location.error = {
        name: !Boolean(location.name),
      };
    });

    const errorIndex = businessLocation.findIndex(({ error }) => error.name);

    this.setState({
      validationErrors: {
        errorIndex,
      },
    });

    if (errorIndex > -1) return;

    businessLocation.forEach((location) => {
      if (location.error) delete location.error;
    });
    this.addBusinessLocation(0);
  };

  /**
   * Add Business Location
   *
   * @param {Object} data
   */
  addBusinessLocation = (index) => {
    const {
      values: { businessLocation },
    } = this.props;

    if (!businessLocation || businessLocation.length === 0 || businessLocation.length === index) return;

    this.setState({ isLoading: true });

    const data = businessLocation[index];

    if (data && data.id) {
      this.updateLocation(data.id, data, index);
    } else {
      this.addLocation(data, index);
    }
    this.addBusinessLocation(++index);
  };

  /**
   * Back
   *
   * @param {Evemt} event
   */
  back = (event) => {
    event.preventDefault();
    this.props.prevStep();
  };

  /**
   * Handle Add More Location
   */
  handleAddMoreLocation = () => {
    const {
      businessDetails: {
        businessProfile: { name: bName },
      },
    } = this.props;
    const defaultField = {
      name: bName,
      phone: "",
      email: "",
      address: "",
    };

    const field = {
      name: '',
      phone: "",
      email: "",
      address: "",
    };
    const values = [...this.state.fields];
    if (!values.length) {

      values.push(defaultField);
    }
    else {
      values.push(field);
    }

    this.setState({ fields: values });
    this.props.moreLocations(values);

  };

  /**
   * Add location
   * 
   * @param {Object} data 
   */
  addLocation = (data, index) => {
    const {
      values: { businessLocation },
      customerId
    } = this.props;

    customerService
      .addBusinessLocation(customerId, data)
      .then((response) => {
        this.setState({ isLoading: false });
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        let index = businessLocation.findIndex(({ name }) => name === data.name);

        businessLocation[index].id = response.data.id;

        const responseData = [...this.state.tempArray];

        const tempArray = responseData.concat(response.data);

        this.setState({ fields: businessLocation, tempArray });

        this.props.moreLocations(businessLocation);

        if (tempArray.length === businessLocation.length) {
          this.props.nextStep();
        }
      })
      .catch((error) => {
        let errorResponse = convertResponseError(error)
        if (errorResponse.status === 400) {
          let emailErrors = { ...this.state.emailErrors };

          emailErrors[index] = { errorIndex: index, message: "User email is invalid" };

          this.setState({ emailErrors, isLoading: false });

          return;
        };

        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Update location
   * 
   * @param {String} locationId 
   * @param {Object} data 
   */
  updateLocation = (locationId, data, index) => {
    const { values:
      {
        businessLocation
      }
    } = this.props;

    const updateLocation = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      address: data.address
    }
    customerService
      .editBusinessLocation(locationId, updateLocation)
      .then((response) => {
        this.setState({ isLoading: false });
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
        const responseData = [...this.state.tempArray];

        const tempArray = responseData.concat(response.data);

        this.setState({ tempArray });

        if (tempArray.length === businessLocation.length) {
          this.props.nextStep();
        }
      })
      .catch((error) => {
        let errorResponse = convertResponseError(error);

        if (errorResponse.status === 400) {
          let emailErrors = { ...this.state.emailErrors };

          emailErrors[index] = { errorIndex: index, message: "User email is invalid" };
          this.setState({ emailErrors, isLoading: false });

          return;
        }

        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  }

  /**
   * Handle Remove Location
   *
   * @param {Number} i
   */
  handleRemoveLocation = (i) => () => {
    const values = [...this.state.fields];

    values.splice(i, 1);

    this.setState({ fields: values });
    this.props.moreLocations(values);
  };

  /**
   * Location Fields
   *
   * @param {Number} index
   */
  locationFields = (index, field) => {
    const { inputChangeBusinessLocation } = this.props;
    const { fields, validationErrors, emailErrors } = this.state;

    return (
      <div className="location-fields-wrap">
        <Header as="h4">Location {index + 1}</Header>
        <Form.Field widths="10" error={validationErrors.errorIndex === index}>
          <label>Business Name *</label>
          <Input
            placeholder="Business Name"
            name={`bus-name${index}`}
            value={field.name}
            onChange={inputChangeBusinessLocation("name", index, field)}
          />
          {validationErrors.errorIndex === index && (
            <Label basic color="red" pointing>
              Required
            </Label>
          )}
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Phone</label>
            <Input
              placeholder="Phone Number"
              fluid
              name={`bus-phone${index}`}
              value={field.phone}
              onChange={inputChangeBusinessLocation("phone", index, field)}
            />
          </Form.Field>
          <Form.Field error={emailErrors && emailErrors[index] && emailErrors[index].errorIndex === index}>
            <label>Email</label>
            <Input
              placeholder="Email"
              name={`bus-email${index}`}
              value={field.email}
              onChange={inputChangeBusinessLocation("email", index, field)}
            />
            {emailErrors && emailErrors[index] && emailErrors[index].errorIndex === index && (
              <Label basic color="red" pointing>
                {emailErrors[index].message}
              </Label>
            )}
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Address</label>
          <TextArea
            placeholder="Address"
            value={field.address}
            type={`bus-address${index}`}
            name="address"
            onChange={inputChangeBusinessLocation("address", index, field)}
          />
        </Form.Field>
        {fields.length > 1 && (
          <button
            className="ui circular icon button close-btn"
            onClick={this.handleRemoveLocation(index)}
          >
            <i className="close icon"></i>
          </button>
        )}
      </div>
    );
  };

  /**
   * Render View
   */
  render() {
    const { fields, remoteError, isLoading } = this.state;
    const { customer } = this.props

    return (
      <>
        <Form loading={isLoading} color="blue" className="mt-10 custom-form">
          <label className="ui centered">
            {customer && customer.user ? 3 : 4}
            <Icon name="long arrow alternate right mb-15" />
            Where is your Bussiness located?
          </label>

          {fields.map((field, index) => (
            <React.Fragment key={index}>
              {this.locationFields(index, field)}
            </React.Fragment>
          ))}

          <Button
            fluid
            onClick={this.handleAddMoreLocation}
            className="mb-10 mt-10"
          >
            Add More Locations
          </Button>

          <Button onClick={this.back}>Back</Button>
          <Button onClick={this.saveAndContinue}>Next</Button>
        </Form>
        {remoteError && (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        )}
      </>
    );
  }
}

export default BusinessLocation;
