import React, { Component } from "react";
import {
  Route,
  HashRouter,
  Redirect,
  Switch,
  Link,
  NavLink,
} from "react-router-dom";
import "../semantic/dist/semantic.min.css";
import "../index.css";
import {
  Dimmer,
  Loader,
  Menu,
  Header,
  Container,
  Icon,
  Divider,
  Dropdown,
} from "semantic-ui-react";
import { version } from "../../package.json";
import {
  autoLogin,
  addListenerToAuth,
  removeListenerFromAuth,
  logout,
  registerAuthStateChangeListener,
} from "../helpers/auth";
import config from "../config/constants";
import Login from "./Login";
import PractitionerResetPassword from "./PractitionerResetPassword";
import Home from "./protected/Home";
import PractitionerList from "./protected/PractitionerList";
import PractitionerProfile from "./protected/PractitionerProfile";
import Practitioner from "./protected/Practitioner";
import PractitionerAdd from "./protected/PractitionerAdd";
import CustomerList from "./protected/CustomerList";
import Customer from "./protected/Customer";
import TestList from "./protected/TestList";
import PlaylistList from "./protected/PlaylistList";
import DeviceList from "./protected/DeviceList";
import CustomerInfo from "./protected/CustomerInfo";
import Tablets from "./protected/Tablets";
import CustomerDevices from "./protected/CustomerDevices";
import AddCustomer from "./protected/AddCustomer";
import CustomerSetupWizard from "./protected/CustomerSetupWizard";
import CustomerWelcomePage from "./protected/CustomerWelcomePage";
import UpgradeToCloud from "./protected/UpgradeToCloud";
import Business from "./protected/Business"
import Locations from "./protected/BusinessLocationsList";
import AddEditLocation from "./protected/AddEditLocation";
import AcceptInvitation from "./protected/AcceptInvitation";
import ForgetPassword from "./ForgetPassword";
import LoginResetPassword from "./LoginResetPassword";
import URLS, { URL_PREFIX } from "../services/URLS";
import { getSearchQueries } from "../helpers/util";
import Messages from "../config/messages";

/**
 * Handle Private Route
 */
function PrivateRoute({ component: Component, authed, ...rest }) {
  if (Component.name !== UpgradeToCloud.name && rest.user && rest.user.eligibleToMigrate) {
    return (
      <Route
        {...rest}
        render={(props) =>
          <Redirect to={`${URL_PREFIX}${URLS.UPGRADE_TO_CLOUD}`} />
        }
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return authed ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: `${URL_PREFIX}${URLS.LOGIN}`, state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

/**
 * Handle Public Route
 */
function PublicRoute({ component: Component, authed, location, ...rest }) {
  // Redirect to /upgrade-to-cloud if autoLogin fails and manual login successfull
  if ((location.search && location.search.includes(URLS.UPGRADE_TO_CLOUD))) {
    return (
      <Route
        {...rest}
        render={(props) =>
          !authed ? <Component {...props} /> : <Redirect to={`${URL_PREFIX}${URLS.UPGRADE_TO_CLOUD}`} />
        }
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !authed ? <Component {...props} /> : <Redirect to={URL_PREFIX} />
      }
    />
  );
}

const App = class extends Component {
  state = {
    authed: false,
    loading: true,
    user: null,
    isAutoLoginSuccess: false
  };

  componentDidMount() {

    const quries = getSearchQueries();

    if (this.isCodeBasedAuth() && quries.code) {
      this.setState({ loading: false });
      return;
    }

    /*
    * Adding Listener to auth
    */
    addListenerToAuth("App", (error, user) => {
      if (user) {
        this.setState({
          authed: true,
          loading: false,
          user,
        });

        return;
      }
      this.setState({
        authed: false,
        loading: false,
        user: null,
      });
    });

    // Handle AutoLogin
    registerAuthStateChangeListener();

    if (window.location.href.includes(URLS.UPGRADE_TO_CLOUD)) return;

    if (quries.email && quries.token) {

      this.setState({ loading: false });
      autoLogin(quries.email, quries.token);
    }

  }

  getUri() {
    return window.location.href.split("?")[0].split("/#/")[1];
  }

  isCodeBasedAuth() {
    const uri = this.getUri();
    if (!uri) return false;

    if (uri.match(/^practitioners\/.*\/reset-pin$/)) {
      return true;
    }
    return false;
  }

  isTokenBasedAuth() {
    const uri = this.getUri();
    if (!uri) return false;
    if (uri.match(/^practitioners\/.*\/profile$/)) {
      return true;
    } else if (uri.match(/^practitioners$/)) {
      return true;
    }
    return false;
  }

  getSearch(link) {
    const splitLocation = window.location.href.split("?");
    return splitLocation.length > 1 ? "?" + splitLocation[1] : "";
  }

  treatLink(link) {
    return this.getSearch(window.location.href).includes(URLS.UPGRADE_TO_CLOUD) || this.state.isAutoLoginSuccess ?
      link :
      `${link}${this.getSearch(window.location.href)}`;
  }

  componentWillUnmount() {
    // Removing listener from auth
    removeListenerFromAuth("App");
  }

  loginWithEmailToken = (authed = false, user = {}) => {
    this.setState({
      authed: authed,
      user: user,
      isAutoLoginSuccess: authed
    });
  }

  eligibleToMigrate = () => {
    const user = { ...this.state.user }
    if (user && user.eligibleToMigrate) {
      user.eligibleToMigrate = false;
      this.setState({ user })
    }
  }

  render() {
    const { authed, user, loading } = this.state;

    const quries = getSearchQueries();

    const authButtons = authed ? (
      <Menu.Menu position="right">
        <Menu.Item>Hi {user && user.contactDetail && <>{user.contactDetail.firstName}</>}!</Menu.Item>
        <Menu.Item
          name="logout"
          onClick={() => {
            logout();
            this.setState({ authed: false })
          }}
          as={Link} to={`${URL_PREFIX}${URLS.LOGIN}`}
        />
      </Menu.Menu>
    ) : null;

    return loading === true ? (
      <Container>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Container>
    ) : (
      <HashRouter>
        <div>
          {quries.mode !== "app" && (
            <Menu style={{ marginBottom: 0 }} className="fix-navbar">
              <Menu.Item as={Link} to={this.treatLink(URL_PREFIX)}>
                <Header as="h2">AxIT</Header>
              </Menu.Item>
              {authButtons}
            </Menu>
          )}

          <div style={{ display: "flex", marginTop: 56, height: "calc(100vh - 55px)", overflow: "hidden"}}>
            {authed && (user && !user.eligibleToMigrate) && quries.mode !== "app" && (
              <div className="sidebar-menu">
                <Menu
                    vertical
                    style={{
                    flexDirection: "vertical",
                  }}
                >
                  <div>
                    {user.role === Messages["role-admin"] && (
                      <Menu.Item as={NavLink} to={this.treatLink(`${URL_PREFIX}${URLS.CUSTOMERS}`)}>
                        <Icon name="home" />
                        Customers
                      </Menu.Item>
                    )}
                    {user.role === Messages["role-admin"] && (
                      <Menu.Item as={NavLink} to={this.treatLink(`${URL_PREFIX}${URLS.TESTS}`)}>
                        <Icon name="clipboard outline" />
                        Tests
                      </Menu.Item>
                    )}
                    {user.role === Messages["role-admin"] && (
                      <Menu.Item as={NavLink} to={this.treatLink(`${URL_PREFIX}${URLS.PLAYLISTS}`)}>
                        <Icon name="clipboard outline" />
                        Playlists
                      </Menu.Item>
                    )}
                    {user.role === Messages["role-admin"] && (
                      <Menu.Item as={NavLink} to={this.treatLink(`${URL_PREFIX}${URLS.DEVICES}`)}>
                        <Icon name="hdd" />
                        Devices
                      </Menu.Item>
                    )}
                    {user.role === Messages["role-customer"] && (
                      <Menu.Item
                        as={NavLink}
                        to={this.treatLink(
                          `${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}${user.customer.id}${URL_PREFIX}${URLS.PRACTITIONERS}`
                        )}
                      >
                        <Icon name="user" />
                        Practitioners
                      </Menu.Item>
                    )}
                    {user.role === Messages["role-customer"] && (
                      <Dropdown item text="Business">
                        <Dropdown.Menu>
                          <Dropdown.Item
                            link
                            as={NavLink}
                            exact
                            to={this.treatLink(`${URL_PREFIX}${URLS.BUSINESS}`)}
                          >
                            Business Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            link
                            as={NavLink}
                            exact
                            to={this.treatLink(`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}`)}
                          >
                            Locations
                          </Dropdown.Item>
                          <Dropdown.Item>Email Template</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  <div style={{ flexGrow: 1 }}></div>
                  <Divider style={{ margin: 0 }} />
                  <div style={{ margin: "5px 10px", textAlign: "right" }}>
                    v{version}
                    {config.versionSuffix ? "-" + config.versionSuffix : ""}
                  </div>
                </Menu>
              </div>
            )}
            <div className="page-content-wrap"
            >
              <Switch>

                <PublicRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.LOGIN}`}
                  component={Login}
                />

                <PublicRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.PRACTITIONERER}/reset-pin`}
                  component={PractitionerResetPassword}
                />

                <PublicRoute
                  authed={authed}
                  path={`${URL_PREFIX}auth${URL_PREFIX}forget-password`}
                  component={ForgetPassword}
                />

                <PublicRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.USER}${URL_PREFIX}reset-password`}
                  component={LoginResetPassword}
                />

                {/* Route for new and existing customer migration */}
                <Route
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.CUSTOMER_MIGRATION}`}
                  component={(props) =>
                    <CustomerSetupWizard
                      user={user}
                      props={props}
                      eligibleToMigrate={this.eligibleToMigrate}
                    />}
                />

                {/* Route for existing customer migration */}
                <Route
                  authed={user && user.eligibleToMigrate}
                  path={`${URL_PREFIX}${URLS.UPGRADE_TO_CLOUD}`}
                  component={(props) =>
                    <UpgradeToCloud
                      user={user}
                      authed={user && user.eligibleToMigrate}
                      props={props}
                      loginWithEmailToken={this.loginWithEmailToken}
                    />}
                />

                {/* Route for new customer setup */}
                <Route
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.CUSTOMER_SETUP}`}
                  component={CustomerWelcomePage}
                  user={user}
                />

                {/* Route for accept invitation */}
                <Route
                  // authed={authed}
                  path={`${URL_PREFIX}${URLS.USER}${URL_PREFIX}accept-invitation`}
                  component={AcceptInvitation}
                // user={user}
                />


                <PrivateRoute
                  exact
                  authed={authed && user.role === Messages["role-customer"]}
                  path={`${URL_PREFIX}${URLS.BUSINESS}`}
                  component={Business}
                  user={user}
                />
                <PrivateRoute
                  exact
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}`}
                  component={Locations}
                  user={user}
                />
                <PrivateRoute
                  authed={authed && user.role === Messages["role-admin"]}
                  path={`${URL_PREFIX}${URLS.CUSTOMERS}`}
                  exact
                  component={CustomerList}
                  user={user}
                />
                <PrivateRoute
                  authed={authed && user.role === Messages["role-admin"]}
                  path={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}add`}
                  exact
                  component={AddCustomer}
                  user={user}
                />
                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}${URL_PREFIX}add`}
                  exact
                  component={AddEditLocation}
                  user={user}
                />
                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.BUSINESS}${URL_PREFIX}${URLS.LOCATIONS}${URL_PREFIX}:locationId${URL_PREFIX}edit`}
                  exact
                  component={AddEditLocation}
                  user={user}
                />
                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.CUSTOMER}${URL_PREFIX}${URLS.PRACTITIONERS}${URL_PREFIX}add`}
                  component={PractitionerAdd}
                  user={user}
                />
                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.CUSTOMER}${URL_PREFIX}${URLS.PRACTITIONERS}`}
                  component={PractitionerList}
                  user={user}
                />
                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.CUSTOMER}${URL_PREFIX}${URLS.TABLETS}`}
                  component={Tablets}
                  user={user}
                />
                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.CUSTOMER}${URL_PREFIX}${URLS.DEVICES}`}
                  component={CustomerDevices}
                  user={user}
                />

                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.CUSTOMER}${URL_PREFIX}edit`}
                  component={Customer}
                  user={user}
                />

                <PrivateRoute
                  authed={authed && user.role === Messages["role-customer"]}
                  path={`${URL_PREFIX}${URLS.PRACTITIONERER}${URL_PREFIX}profile`}
                  component={PractitionerProfile}
                  user={user}
                />
                <PrivateRoute
                  authed={authed}
                  path={`${URL_PREFIX}${URLS.PRACTITIONERER}`}
                  component={Practitioner}
                  user={user}
                />

                <PrivateRoute
                  authed={authed && user.role === Messages["role-admin"]}
                  path={`${URL_PREFIX}${URLS.CUSTOMER}`}
                  component={CustomerInfo}
                  user={user}
                />
                <PrivateRoute
                  authed={authed && user.role === Messages["role-admin"]}
                  path={`${URL_PREFIX}${URLS.TESTS}`}
                  exact
                  component={TestList}
                  user={user}
                />

                <PrivateRoute
                  authed={authed && user.role === Messages["role-admin"]}
                  path={`${URL_PREFIX}${URLS.PLAYLISTS}`}
                  exact
                  component={PlaylistList}
                  user={user}
                />

                <PrivateRoute
                  authed={authed && user.role === Messages["role-admin"]}
                  path={`${URL_PREFIX}${URLS.DEVICES}`}
                  exact
                  component={DeviceList}
                  user={user}
                />

                <PrivateRoute
                  authed={authed}
                  path={URL_PREFIX}
                  component={Home}
                  user={user}
                />

                <Route render={() => <h3>No Match</h3>} />

              </Switch>
            </div>
          </div>
        </div>
      </HashRouter>
    );
  }
};

export default App;
