import * as Yup from "yup";

import request from "../helpers/request";

/**
 * Add User Schema
 */
const userAddSchema = Yup.object().shape({
  email: Yup.string().trim().required("Required").email("Invalid email"),
  authPhone: Yup.string().trim().min(8, "Minimum 8").nullable(),
  password: Yup.string()
    .nullable()
    .required("Required")
    .min(8, "Too short")
    .max(20, "Too long")
    .matches(
      /[0-9A-Za-z#?!@$%^&*-]*/,
      "Password can only contain letters, numbers and special symbols (#?!@$%^&*-)"
    )
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).*/,
      "Password must contain at least one upper case letter, one lower case letter, and one digit or special character"
    ),
  repeatPassword: Yup.mixed().when("password", {
    is: (value) => {
      return typeof value !== "undefined" && value !== null;
    },
    then: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    otherwise: Yup.string().nullable(),
  }),
  suspended: Yup.bool().required("Required"),
  twoFactorAuthEnabled: Yup.bool().required("Required"),
});

/**
 * Update Customer Schema
 */
const updateCustomerSchema = Yup.object({
  email: Yup.string().required("Required").email(),
  businessProfile: Yup.object({
    name: Yup.string()
      .required("Required")
      .min(2, "Too short")
      .max(50, "Too long"),
  }),
  licenceType: Yup.string().required("Required"),
  licences: Yup.number()
    .required("Required")
    .min(0, "Must not be a negative number")
    .integer(),
  user: Yup.object({
    contactDetail: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .min(2, "Too short")
        .max(50, "Too long"),
      lastName: Yup.string()
        .required("Required")
        .min(2, "Too short")
        .max(50, "Too long"),
      phone: Yup.string()
        .trim()
        .required("Required")
        .min(8, "Minimum 8")
        .max(12, "Maximum 12"),
      email: Yup.string().required("Required").email(),
    }),
  }),
});

/**
 * Invitation Email Schema
 */
const invitationLinkSchema = Yup.object({
  email: Yup.string().required("Required").email(),
});

/**
 * Licence Schema
 */
const licenceSchema = Yup.object({
  licenceType: Yup.string().required("Required"),
  licences: Yup.number()
    .required("Required")
    .min(0, "Must not be a negative number")
    .integer(),
});

/**
 * Login Schema
 */
const loginSchema = Yup.object().shape({
  email: Yup.string().trim().required("Required").email("Invalid email"),
  password: Yup.string()
    .required("Required")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).*/,
      "Password must contain at least one upper case letter, one lower case letter, and one digit or special character"
    ),
});

/**
 * Business Profile Schema
 */
const businessSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .min(2, "Too short")
    .max(50, "Too long"),
});

/**
 * Business Contact Schema
 */
const businessContactSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .min(2, "Too short")
    .max(50, "Too long"),
  lastName: Yup.string()
    .required("Required")
    .min(2, "Too short")
    .max(50, "Too long"),
  phone: Yup.string()
    .trim()
    .required("Required")
    .min(8, "Minimum 8")
    .max(12, "Maximum 12"),
  email: Yup.string().required("Required").email(),
});

/**
 * Add Schema
 */
const addSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Required")
    .min(2, "Too short")
    .max(50, "Too long"),
  licenceType: Yup.string().required("Required"),
  licences: Yup.number()
    .required("Required")
    .min(0, "Must not be a negative number")
    .integer(),
  user: userAddSchema,
  emailTemplate: Yup.object().shape({
    cc: Yup.array().of(Yup.string().email()),
    bcc: Yup.array().of(Yup.string().email()),
    content: Yup.string().required("Required"),
  }),
});

/**
 * Customer Licence Schema
 */
const customerLicenceSchema = Yup.object().shape({
  licenceType: Yup.string().required("Required"),
  licences: Yup.number()
    .required("Required")
    .min(0, "Must not be a negative number")
    .integer(),
  email: Yup.string().trim().required("Required").email("Invalid email"),
});

/**
 * Schema for Validation
 */
const customerDeviceSchema = Yup.object().shape({
  type: Yup.string().required("Type Required"),
  deviceName: Yup.string().required("Name Required"),
  deviceMAC: Yup.string().required("Device Required"),
});

/**
 * Reset Password Schema for Practitioners
 */
const resetPasswordSchema = Yup.object().shape({
  pin: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'PIN can only contain numbers')
    .min(4, 'Minimum 4')
    .max(8, 'Maximum 8'),
});

/**
 * Reset Password schema for customer 
 */
const resetPasswordCustomerSchema = Yup.object().shape({
  password: Yup.string()
    .nullable()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).*/,
      "Password must contain at least one upper case letter, one lower case letter, and one digit or special character"
    )
    .min(8, 'Minimum 8'),
  pin: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'PIN can only contain numbers')
    .min(4, 'Minimum 4')
    .max(8, 'Maximum 8'),
})

/**
 * Config to Delete Tablet Modal
 */
const DELETE_TABLET_MODAL_CONFIG = {
  header: "Do want to delete tablet #ID?",
  list: [
    `Customer's data won't be deleted`,
    `The customer will be logged out from the tablet`,
    `This deleted tablet won't occupy customer licence's quota`,
    `You can restore this tablet at any time`,
  ],
};

/**
 * Config to Restore Tablet Modal
 */
const RESTORE_TABLET_MODAL_CONFIG = {
  header: "Do want to restore tablet #ID?",
  list: [
    `The customer will be able to login on this tablet`,
    `Recovering will success even if this may exceed customer licence's quota`,
  ],
};

/**
 * Device Type For Assign
 */
const DEVICE_TYPES = [
  {
    text: "-",
    value: "",
  },
  {
    text: "Push-IT",
    value: "PUSH",
    key: "PUSH",
    displaykey: "Push",
  },
  {
    text: "Stomp-ITs Pair",
    value: "PAIR",
    displaykey: "Plate",
  },
  {
    text: "Pull-IT Left",
    value: "PULL_LEFT",
    key: "PULL_LEFT",
    displaykey: "Pull Left",
  },
  {
    text: "Pull-IT Right",
    value: "PULL_RIGHT",
    key: "PULL_RIGHT",
    displaykey: "Pull Right",
  },
];

/**
 * Type Options for Paired Device
 */
const PAIRED_DEVICE_OPTION = [
  {
    text: "-",
    value: "",
  },
  {
    text: "Left",
    value: "LEFT",
    key: "Left",
    displaykey: "Left",
  },
  {
    text: "Right",
    value: "RIGHT",
    key: "Right",
    displaykey: "Right",
  },
];

/**
 * Default Device Row
 */
const DEFAULT_ROW = {
  "@type": "",
  customer: {
    id: "",
  },
  id: "",
  name: "",
  tablets: [],
  inUse: false,
  device: {
    id: "",
    type: "",
    macAddress: "",
    loan: false,
    comment: "",
  },
  assignType: "",
  isForLastRow: true,
};

/**
 * Default Left|Right Stomp IT Pair child Row
 */
const DEFAULT_CHILD_ROW = {
  "@type": "",
  customer: {
    id: "",
  },
  id: "",
  name: "",
  tablets: [],
  inUse: false,
  device: {
    id: "",
    type: "",
    macAddress: "",
    loan: false,
    comment: "",
  },
  assignType: "",
  isForLastRow: true,
  isChildPairedRow: true,
};

/**
 * Basic API Keys ragarding Device
 */
const DEVICE_KEYS = {
  push: "PUSH",
  pull: "PULL",
  plate: "PLATE",
  left: "LEFT",
  right: "RIGHT",
  plateLeft: "PLATE_LEFT",
  plateRight: "PLATE_RIGHT",
  pullLeft: "PULL_LEFT",
  pullRight: "PULL_RIGHT",
  deviceGroup: "DeviceGroup",
  device: "Device",
  pair: "PAIR",
  platePair: "PLATE_PAIR",
  stompIt: "Stomp-IT",
};

/**
 * LICENCE TYPE OPTIONS
 */
const LICENCE_TYPE_OPTIONS = [
  {
    key: "BASIC",
    text: "Basic",
    value: "BASIC",
  },
  {
    key: "CLOUD",
    text: "Cloud",
    value: "CLOUD",
  },
];

/**
 * INIT STATUS
 */
const INIT_STATUS = {
  resend: {
    key: "RESEND",
    value: "RESEND",
  },
  complete: {
    key: "COMPLETE",
    value: "Complete",
  },
  sent: { key: "SENT", value: "SENT" },
  sending: { key: "SENDING", value: "SENDING..." },
  customer_added: { key: "CUSTOMER_ADDED", value: "CUSTOMER_ADDED" },
  user_added: { key: "USER_ADDED", value: "USER_ADDED" },
  customer_set: { key: "CUSTOMER_SET", value: "CUSTOMER_SET" },
  contact_detail_set: {
    key: "CONTACT_DETAIL_SET",
    value: "CONTACT_DETAIL_SET",
  },
  user_set: { key: "USER_SET", value: "USER_SET" },
  business_profile_set: {
    key: "BUSINESS_PROFILE_SET",
    value: "BUSINESS_PROFILE_SET",
  },
  location_set: {
    key: "LOCATION_ADDED",
    value: "LOCATION_ADDED",
  },
};

const DEVICE_TYPES_CHILD_WITH_KEYS = {};
const DEVICE_TYPES_WITH_KEYS = {};

/**
 * Get Key Value
 */
DEVICE_TYPES.forEach((type) => {
  DEVICE_TYPES_WITH_KEYS[type.value] = type;
});

/**
 * Paired Device Key
 */
PAIRED_DEVICE_OPTION.forEach(
  (type) => (DEVICE_TYPES_CHILD_WITH_KEYS[type.value] = type)
);

export default {
  addSchema,
  updateCustomerSchema,
  DELETE_TABLET_MODAL_CONFIG,
  RESTORE_TABLET_MODAL_CONFIG,
  DEVICE_TYPES_WITH_KEYS,
  DEVICE_TYPES_CHILD_WITH_KEYS,
  DEFAULT_CHILD_ROW,
  DEVICE_TYPES,
  PAIRED_DEVICE_OPTION,
  LICENCE_TYPE_OPTIONS,
  DEFAULT_ROW,
  DEVICE_KEYS,
  customerDeviceSchema,
  customerLicenceSchema,
  INIT_STATUS,
  loginSchema,
  businessSchema,
  businessContactSchema,
  licenceSchema,
  invitationLinkSchema,
  resetPasswordSchema,
  resetPasswordCustomerSchema,

  getAllCustomers: function (page, size, sortBy, sortDirection) {
    return request.get("/customers", {
      params: {
        page,
        size,
        sortBy,
        sortDirection,
      },
    });
  },

  getCustomerById: function (id) {
    return request.get(`/customers/${id}`);
  },

  getCustomerDevicesById: function (id) {
    return request.get(`/customers/${id}/devices`);
  },

  getCustomerByUserId: function (userId) {
    return request.get(`/users/${userId}/customer`);
  },

  getCustomerLogo: function (customerId) {
    return request.get(`customers/${customerId}/business-profile/logo`, {
      responseType: "arraybuffer",
    });
  },

  addCustomer: function (customer) {
    return request.post("/customers", customer);
  },

  addCustomerDevice: function (id, data) {
    const params = {
      item: data,
      tabletId: "__not_a_tablet__",
    };
    return request.post(`/batch/customers/${id}/devices`, params);
  },

  updateCustomer: function (id, customer) {
    return request.put(`/customers/${id}`, customer);
  },

  uploadLogo: function (customerId, file) {
    return request.put(`customers/${customerId}/business-profile/logo`, file);
  },

  deleteLogo: function (customerId) {
    return request.delete(`customers/${customerId}/business-profile/logo`);
  },

  addUser: function (id, credentials, code) {
    return request.put(`/customers/${id}/user${code}`, credentials);
  },

  addBusinessProfile: function (id, data) {
    return request.put(`/customers/${id}/business-profile`, data);
  },

  addBusinessLocation: function (id, data) {
    const locations = {
      businessProfile: data,
    };
    return request.post(`/customers/${id}/locations`, locations);
  },

  editBusinessLocation: function (id, data) {
    const locations = {
      id: id,
      businessProfile: data,
    };
    return request.put(`/locations/${id}`, locations);
  },

  getBusinessLocationById: function (customerId) {
    return request.get(`/customers/${customerId}/locations`)
  },

  deleteLocationById: function (locationId) {
    return request.delete(`/locations/${locationId}`)
  },

  addContactDetail: function (userId, data) {
    return request.put(`users/${userId}/contact-detail`, data);
  },

  addPractitioners: function (customerId, user) {
    const practitioner = { user }
    return request.post(`customers/${customerId}/practitioners`, practitioner);
  },

  addDefaultPractitioners: function (customerId) {
    return request.post(`customers/${customerId}/practitioners/default-practitioner`);
  },

  migrateCustomer: function () {
    return request.post(`/migration`, {});
  },

  verifyLink: function (customerId, code) {
    return request.get(`/customers/${customerId}/verify-invitation-link${code}`);
  },

  verifyUserLink: function (userId, code) {
    return request.get(`/users/${userId}/verify-action-link${code}`);
  },

  patchTablet: function (tabletId, patch) {
    return request.patch(`/tablets/${tabletId}`, patch);
  },

  saveDataToLocalStorage: function (data, customerId) {
    localStorage.setItem(
      `axit_customer_devices_${customerId}`,
      JSON.stringify(data)
    );
  },

  reSendInvitation: function (params, customerId) {
    return request.post(`/customers/${customerId}/invitation-link`, params);
  },

  clearDataFromLocalStorage: function (customerId) {
    localStorage.removeItem(`axit_customer_devices_${customerId}`);
  },

  getDataFromLocalStorage: function (customerId) {
    return JSON.parse(
      localStorage.getItem(`axit_customer_devices_${customerId}`)
    );
  },

  saveEmailTokenToLocalStorage: function (data) {
    localStorage.setItem(
      `axit_customer`,
      JSON.stringify(data)
    )
  },

  getEmailTokenToLocalStorage: function () {
    return JSON.parse(
      localStorage.getItem(`axit_customer`)
    );
  },

  clearEmailTokenFromLocalStorage: function () {
    localStorage.removeItem(`axit_customer`);
  },

  isSbnCustomer: function (email) {
    return (email && email.split("@")[1] === "strengthbynumbers.com") || false;
  },

  resetLoginPassword: function (email) {
    return request.post('send-reset-email/users/email/' + email);
  },

  newPassword: function (userId, newPassword) {
    return request.put(`/users/${userId}/`, newPassword);
  }
};
