import React, { Component } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Label,
  Message,
} from "semantic-ui-react";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";

import customerService from "../../services/customer";

class BusinessContactPerson extends Component {
  state = {
    validationErrors: {},
    remoteError: null,
    isLoading: false,
  };

  /**
   * Save and Continue
   *
   * @param {Event} event
   */
  saveAndContinue = async (event) => {
    event.preventDefault();

    this.setState({
      validationErrors: {},
      remoteError: null,
    });

    const {
      values: {
        businessContact: { firstName, lastName, phone, email },
      },
      user,
      userData,
    } = this.props;

    const fields = {
      firstName,
      lastName,
      phone,
      email,
    };

    const isValid = await customerService.businessContactSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);

      return;
    }

    /**
     * Get user Id
     */
    const getUserId = () => {
      if (userData) return userData.id;

      if (user) return user.id;
    };

    this.setState({ isLoading: true });

    customerService
      .addContactDetail(getUserId(), fields)
      .then((response) => {
        this.setState({ isLoading: false });

        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        this.props.nextStep();
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
      });
  };

  /**
   * Remove Contact Validation
   */
  removeContactValidation() {
    this.setState({ validationErrors: {} })
  }

  /**
   * Validate All
   *
   * @param {Object} fields
   * @param {Sting} parentPath
   * @param {Object} root
   */
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : "";

    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + ".", root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    customerService.businessContactSchema
      .validateAt(field, fields)
      .catch((error) => {
        this.setState((state) => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0],
          },
        }));
      });
  }

  /**
   * Back
   *
   * @param {Evemt} event
   */
  back = (event) => {
    event.preventDefault();
    this.props.prevStep();
  };

  /**
   * Render View
   */
  render() {
    const {
      values: {
        businessContact: { firstName, lastName, phone, email },
      },
      handleChange,
      remoteError,
      isWizard,
      customer
    } = this.props;
    const { validationErrors, isLoading } = this.state;
    return (
      <>
        <Form loading={isLoading} color="blue" className="mt-10 custom-form">
          {isWizard && (
            <label className="ui centered">
              {customer && customer.user ? 2 : 3}
              <Icon name="long arrow alternate right" />
              Please let me know the best person to connect with
            </label>
          )}

          {isWizard ? (
            <Header as="h3">Business Contact Person</Header>
          ) : (
            <Header as="h3">Contact Person</Header>
          )}

          <Form.Group widths="equal">
            <Form.Field error={Boolean(validationErrors["firstName"])}>
              <label>First Name *</label>
              <Input
                placeholder="First Name"
                fluid
                name="firstName"
                value={firstName}
                onChange={handleChange("firstName", "businessContact")}
              />
              {Boolean(validationErrors["firstName"]) && (
                <Label basic color="red" pointing>
                  {validationErrors["firstName"]}
                </Label>
              )}
            </Form.Field>
            <Form.Field error={Boolean(validationErrors["lastName"])}>
              <label>Last Name *</label>
              <Input
                placeholder="Last Name"
                fluid
                name="lastName"
                value={lastName}
                onChange={handleChange("lastName", "businessContact")}
              />
              {Boolean(validationErrors["lastName"]) && (
                <Label basic color="red" pointing>
                  {validationErrors["lastName"]}
                </Label>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={Boolean(validationErrors["phone"])}>
              <label>Phone *</label>
              <Input
                placeholder="Phone Number"
                fluid
                name="phone"
                value={phone}
                onChange={handleChange("phone", "businessContact")}
              />
              {Boolean(validationErrors["phone"]) && (
                <Label basic color="red" pointing>
                  {validationErrors["phone"]}
                </Label>
              )}
            </Form.Field>
            <Form.Field error={Boolean(validationErrors["email"])}>
              <label>Email *</label>
              <Input
                placeholder="Email"
                name="email"
                value={email}
                onChange={handleChange("email", "businessContact")}
              />
              {Boolean(validationErrors["email"]) && (
                <Label basic color="red" pointing>
                  {validationErrors["email"]}
                </Label>
              )}
            </Form.Field>
          </Form.Group>

          {isWizard && (
            <>
              <Button onClick={this.back}>Back</Button>
              <Button onClick={this.saveAndContinue}>Next </Button>
            </>
          )}
        </Form>
        {remoteError && (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        )}
      </>
    );
  }
}

BusinessContactPerson.defaultProps = {
  values: {
    businessContact: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
  },
  handleChange: () => { },
  isWizard: false,
};

export default BusinessContactPerson;