import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Grid,
  Header,
  Input,
  Label,
  Message,
  Pagination,
  Segment,
  Table,
  Image,
  Popup,
} from "semantic-ui-react";

import DeviceAdd from "./DeviceAdd";

import deviceService from "../../services/device";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";
import { Link } from "react-router-dom";
import ConfirmationDialogComponent from "./ConfirmationDialogComponent";

class DeviceList extends Component {
  state = {
    isFetchingDevices: false,
    isFetchingStats: false,

    devices: [],
    pagination: { ...deviceService.DEFAULT_PAGINATION },

    isVisibleAddDeviceModal: false,
    isVisibleDeleteDeviceConfirmationModal: false,

    selectedDevice: null,
    searchDeviceByMacAddress: "",
    isSearch: false,
    remoteError: null,

    filters: [...deviceService.DEVICE_TYPES_FILTER],

    statistics: {
      push: 0,
      pull: 0,
      plate: 0,
    },
  };

  componentDidMount() {
    const {
      location: { search: macAddress },
    } = this.props;

    if (macAddress) {
      this.setState(
        {
          searchDeviceByMacAddress: macAddress ? macAddress.split("=")[1] : "",
          isSearch: true,
        },
        this.fetchDevicesAndStaTsOnPageLoad
      );
      return;
    }

    this.fetchDevicesAndStaTsOnPageLoad();
  }

  /**
   * Fetch devices and stats on page laod
   */
  fetchDevicesAndStaTsOnPageLoad = () => {
    this.fetchDevices();
    this.getStatistics();
  };

  /**
   * Get Query Params for Device's List
   *
   * @return {Object}
   */
  getQueryParams = () => {
    if (!this.state.pagination) return {};

    const {
      pagination,
      searchDeviceByMacAddress,
      filters,
      isSearch,
    } = this.state;
    const { size, page, sortDirection, sortBy } = pagination;

    const params = {};

    // Pagination
    if (size) params.size = size;
    if (page) params.page = page;

    // Sorting
    if (sortDirection) params.sortDirection = sortDirection;
    if (sortBy) params.sortBy = sortBy;

    // Search
    if (searchDeviceByMacAddress || isSearch) {
      params.q = searchDeviceByMacAddress;
    }

    // Device Types
    if (isSearch && filters) {
      const types = filters
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => value)
        .join();

      if (types) params.types = deviceService.handleDeviceTypeString(types);
    }

    return params;
  };

  /**
   * Fetch List of Devices
   */
  fetchDevices = () => {
    const { pagination, isSearch } = this.state;

    this.setState({ isFetchingDevices: true });

    const url = `${isSearch ? "/search" : ""}/devices`;
    const params = this.getQueryParams();

    deviceService
      .getDevices(url, params)
      .then((response) => {
        if (!response) {
          this.setState({
            isFetchingDevices: false,
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        const { content: devices, size, totalElements } = response.data;

        const newPagination = {
          ...pagination,
          totalPages: Math.ceil(totalElements / size),
          page: pagination.page,
          totalElements,
        };

        this.setState({
          isFetchingDevices: false,
          pagination: newPagination,
          devices,
        });
      })
      .catch((error) => {
        this.setState({
          isFetchingDevices: false,
          remoteError: convertResponseError(error),
        });
      });
  };

  /**
   * Get Selected Device Type
   *
   * @return String
   */
  getSelectedDeviceType = () => {
    const { filters } = this.state;

    const types = filters
      .filter(({ isChecked }) => isChecked)
      .map(({ text }) => text)
      .join(", ");

    if (types) {
      return deviceService.handleDeviceTypeString(types);
    }

    return "";
  };

  /**
   * Get Statistics of Device Types
   */
  getStatistics = () => {
    this.setState({ isFetchingStats: true });

    deviceService
      .getStatistics()
      .then((response) => {
        if (!response) {
          this.setState({
            isFetchingStats: false,
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        this.setState({ isFetchingStats: false, statistics: response.data });
      })
      .catch((error) => {
        this.setState({
          isFetchingStats: false,
          remoteError: convertResponseError(error),
        });
      });
  };

  /**
   * Open Modal for Add device
   *
   * @param {Object} selectedDevice
   */
  openAddNewDeviceModal = (selectedDevice) => () => {
    this.setState({ isVisibleAddDeviceModal: true, selectedDevice });
  };

  /**
   * Refetch Device
   */
  reFetchDevices = (action = "") => {
    const { pagination } = this.state;

    const { totalElements, size, page } = pagination;

    /**
     * Handle Pagination After Actions
     */
    const handlePaginationAfterAction = () => {
      if (!action) return deviceService.DEFAULT_PAGINATION;

      const newPagination = {
        ...pagination,
        totalPages: Math.ceil(totalElements / size),
        page: action === "update" ? page : Math.ceil(totalElements / size),
        totalElements,
      };

      return newPagination;
    };

    this.setState(
      {
        isVisibleAddDeviceModal: false,
        pagination: { ...handlePaginationAfterAction() },
        selectedDevice: null,
        isVisibleDeleteDeviceConfirmationModal: false,
      },
      () => {
        this.fetchDevices();
        this.getStatistics();
      }
    );
  };

  /**
   * Handle Pagination changes
   *
   * @param {Event} evt
   * @param {Object} param
   */
  handlePaginationChange = (evt, { name, value, activePage }) => {
    const { pagination } = this.state;

    pagination[name] = activePage || value;

    this.setState({ pagination }, this.fetchDevices);
  };

  /**
   * Handle Sort
   *
   * @param {String} sortBy
   */
  handleSort = (sortBy = "") => {
    const { pagination } = this.state;

    const getSortDirection = () => {
      if (pagination.sortDirection === "descending") return "ascending";

      return "descending";
    };

    const newPagination = {
      ...pagination,
      sortBy,
      sortDirection: getSortDirection(),
    };

    this.setState({ pagination: newPagination }, this.fetchDevices);
  };

  /**
   * Handle Device Type Selection
   *
   * @param {Object} option
   */
  handleDeviceTypeSelecltion = (option) => {
    const { filters } = this.state;

    if (option.value === "ALL") {
      this.handleAllSelection(option);
      return;
    }

    if (option.isChecked) filters[filters.length - 1].isChecked = false;

    const index = filters.findIndex(({ value }) => value === option.value);

    filters[index].isChecked = !filters[index].isChecked;

    this.setState({ filters });
  };

  /**
   * Handle All Selection
   *
   * @param {Object} option
   */
  handleAllSelection = (option) => {
    const filters = this.state.filters.map((filter) => {
      return {
        ...filter,
        isChecked: !option.isChecked,
      };
    });

    this.setState({ filters });
  };

  /**
   * Set Initial Page for Pagination > Page to 1
   */
  setInitialPageForPagination = () => {
    const { pagination } = this.state;

    const newPagination = {
      ...pagination,
      page: 1,
    };

    this.setState({ pagination: newPagination });
  };

  /**
   * Clear Filters
   */
  clearFilters = () => {
    if (!this.isDataAvailableForSearch() && !this.state.isSearch) return;

    this.setInitialPageForPagination();

    const filters = this.state.filters.map((filter) => {
      return {
        ...filter,
        isChecked: false,
      };
    });

    this.setState(
      {
        isSearch: false,
        searchDeviceByMacAddress: "",
        remoteError: null,
        filters,
      },
      this.fetchDevices
    );
  };

  /**
   * Open Delete Device Confirmation Modal for Delete Device
   *
   * @param {Object} selectedDevice
   */
  openConfimationModal = (selectedDevice) => () => {
    this.setState({
      isVisibleDeleteDeviceConfirmationModal: true,
      selectedDevice,
    });
  };

  /**
   * Handle Close Confimation Modal
   * Reset Confimation Data
   */
  onDeleteDeviceCancel = () => {
    this.setState({
      isVisibleDeleteDeviceConfirmationModal: false,
      selectedDevice: null,
      remoteError: null,
    });
  };

  /**
   * Handle Search Device
   */
  handleSearchDevice = () => {
    if (!this.isDataAvailableForSearch()) return;

    this.handlePaginationOnSearch();
  };

  /**
   * Is filters data available for search
   */
  isDataAvailableForSearch = () => {
    const { searchDeviceByMacAddress, filters } = this.state;

    const index = filters.findIndex(({ isChecked }) => isChecked);

    if (searchDeviceByMacAddress || index > -1) return true;

    return false;
  };

  /**
   * Handle Pagination on Search
   * And fetch devices
   */
  handlePaginationOnSearch = () => {
    this.setInitialPageForPagination();

    this.setState(
      {
        isSearch: true,
        remoteError: null,
      },
      this.fetchDevices
    );
  };

  /**
   * Search devices on eneter press
   *
   * @param {Event} e
   */
  searchDevicesOnEnter = (e) => {
    if (!this.isDataAvailableForSearch()) return;

    //it triggers by pressing the enter key
    if (e.keyCode === 13) this.handlePaginationOnSearch();
  };

  /**
   * Handle Loan Check
   *
   * @param {Object} device
   */
  handleLoanCheck = (device) => () => {
    const { devices } = this.state;

    device.loan = !device.loan;

    this.setState({ devices }, this.updateDeviceForLoan(device));
  };

  /**
   * Handle > Update Device For Loan
   *
   * @param {Object} device
   */
  updateDeviceForLoan = (device) => () => {
    deviceService
      .updateDevice(device.id, device)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
        });
      });
  };

  /**
   * Handel Device Name
   *
   * @param {Array} usages
   *
   * @return {String}
   */
  handleDeviceName = (usages) => {
    if (!usages || usages.length === 0) return "--";

    const { devices, name = "--" } = usages[0];

    if (!devices) return name;

    const index = devices.findIndex(({ currentDevice }) => currentDevice);

    return devices[index].name || "--";
  };

  /**
   * Get Status
   *
   * @param {Object} device
   */
  getStatus = (device) => {
    const { usages } = device;

    const { customer = {}, assignType, name: pairName, devices = [] } =
      (usages.length > 0 && usages[0]) || {};

    const { id, businessProfile = {} } = customer || {};

    const { name = "", email = "" } = businessProfile || {};

    let pairNameText = "";

    if (devices.length > 0) {
      const index = devices.findIndex(({ currentDevice }) => currentDevice);

      const { assignType: pairAssignType } = devices[index];

      if (pairAssignType === "PLATE_LEFT")
        pairNameText = `as Left in ${pairName}`;

      if (pairAssignType === "PLATE_RIGHT")
        pairNameText = `as Right in ${pairName}`;
    }

    const handleStatusText = () => {
      if (!assignType) return "";

      if (assignType === "PULL_LEFT") return "as Left";

      if (assignType === "PULL_RIGHT") return "as Right";

      return "";
    };

    switch (device.status) {
      case "ADDED":
        return "Added";

      case "ASSIGNED":
        return (
          <>
            <span>Assigned to</span>&nbsp;
            <Popup
              content={email}
              trigger={
                <Link target="_blank" to={`/customers/${id}`}>
                  {name}&nbsp;
                </Link>
              }
            />
            <span>
              {handleStatusText()}
              {pairNameText}
            </span>
          </>
        );

      case "NORMAL":
        return (
          <>
            <span>Using by</span>
            <Popup
              content={email}
              trigger={
                <Link target="_blank" to={`/customers/${id}`}>
                  {name}&nbsp;
                </Link>
              }
            />
            <span>
              {handleStatusText()}
              {pairNameText}
            </span>
          </>
        );

      default:
        return "--";
    }
  };

  /**
  * Handle Confirmation
  * Delete the Device by Id
  */
  handleConfirm = () => {
    const { selectedDevice, devices } = this.state;
    this.setState({ isFetchingDevices: true })
    deviceService.deleteDevice(selectedDevice.id)
      .then(response => {
        this.setState({ isFetchingDevices: false })
        if (!response) {
          this.setState({
            remoteError: { message: Messages['no-responce-from-server'] }
          });
          return;
        }
        let index = devices.findIndex(val => val.id === selectedDevice.id);
        devices.splice(index, 1)
        this.setState({ devices })
        this.onDeleteDeviceCancel();
      })
      .catch(error => {
        this.setState({
          remoteError: convertResponseError(error),
          isFetchingDevices: false
        });
      });
  }

  /**
   * Render View
   */
  render() {
    const {
      isFetchingDevices,
      isFetchingStats,
      isVisibleAddDeviceModal,
      isVisibleDeleteDeviceConfirmationModal,
      devices,
      selectedDevice,
      searchDeviceByMacAddress,
      remoteError,
      pagination,
      filters,
      statistics,
    } = this.state;

    const {
      page,
      size,
      totalElements,
      totalPages,
      sortBy,
      sortDirection,
    } = pagination;

    return (
      <>
        <Grid>
          <Grid.Row columns={2} className="alignItemCenter">
            <Grid.Column>
              <Header as="h3">Device Management</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button primary onClick={this.openAddNewDeviceModal(null)}>
                Add Device
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        {remoteError && !isVisibleDeleteDeviceConfirmationModal && (
          <Message error>
            <p>{remoteError.message || "Something Went Wrong"}</p>
          </Message>
        )}

        <Segment
          loading={isFetchingDevices || isFetchingStats}
          className="fake-segment"
        >
          <Grid stackable columns={3} className="alignItemCenter">
            <Grid.Column>
              <Input
                fluid
                placeholder="Search Device By Mac Address"
                value={searchDeviceByMacAddress}
                name="searchDeviceByMacAddress"
                onChange={(e, { value }) =>
                  this.setState({
                    searchDeviceByMacAddress: value,
                  })
                }
                onKeyDown={this.searchDevicesOnEnter}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                item
                simple
                text={this.getSelectedDeviceType() || "Device Types"}
                className={
                  Boolean(this.getSelectedDeviceType())
                    ? "deviceTypeFilter"
                    : "deviceTypeFilter default"
                }
              >
                <Dropdown.Menu>
                  {filters.map((val, index) => (
                    <Dropdown.Item
                      key={`device-type${index}`}
                      onClick={() => this.handleDeviceTypeSelecltion(val)}
                    >
                      <Checkbox
                        label={val.text}
                        checked={Boolean(val.isChecked)}
                      />
                      {val.key && (
                        <Label className="deviceBadge" circular>
                          {statistics[val.key] || 0}
                        </Label>
                      )}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                className="mb-5"
                content="Clear"
                onClick={this.clearFilters}
              />
              <Button
                color="grey"
                content="Search"
                onClick={this.handleSearchDevice}
              />
            </Grid.Column>
          </Grid>

          <Table size="small" sortable fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  width={2}
                  sorted={sortBy === "type" ? sortDirection : null}
                  onClick={() => this.handleSort("type")}
                >
                  Types
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={4}
                  sorted={sortBy === "macAddress" ? sortDirection : null}
                  onClick={() => this.handleSort("macAddress")}
                >
                  MAC Address
                </Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortBy === "status" ? sortDirection : null}
                  onClick={() => this.handleSort("status")}
                >
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell>Comments</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  Operations
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {devices.length === 0 && (
                <Table.Row>
                  <Table.Cell textAlign="center" colSpan="6">
                    No Record Found!
                  </Table.Cell>{" "}
                </Table.Row>
              )}

              {devices.length > 0 &&
                devices.map((device) => (
                  <Table.Row key={device.id}>
                    <Table.Cell>
                      <Image
                        src={`images/${String(device.type).toLowerCase()}.png`}
                        size="mini"
                      />
                    </Table.Cell>
                    <Table.Cell>{device.macAddress}</Table.Cell>
                    <Table.Cell>
                      {this.handleDeviceName(device.usages)}
                    </Table.Cell>
                    <Table.Cell>{this.getStatus(device)}</Table.Cell>
                    <Table.Cell title={device.comment} singleLine>
                      {device.comment}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Checkbox
                        label="Loan"
                        checked={Boolean(device.loan)}
                        className="mr-5"
                        onChange={this.handleLoanCheck(device)}
                      />
                      <Button
                        onClick={this.openAddNewDeviceModal(device)}
                        circular
                        size="mini"
                        icon="pencil alternate"
                      />
                      <Button
                        onClick={this.openConfimationModal(device)}
                        circular
                        size="mini"
                        icon="trash alternate outline alternate"
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>

            <Table.Footer>
              {devices.length > 0 && (
                <Table.Row>
                  <Table.HeaderCell
                    colSpan="6"
                    textAlign="right"
                    className="overflowVisible"
                  >
                    <Pagination
                      activePage={page}
                      boundaryRange={1}
                      onPageChange={this.handlePaginationChange}
                      name="page"
                      size="mini"
                      siblingRange={1}
                      totalPages={totalPages}
                      firstItem={null}
                      lastItem={null}
                    />
                    <span className="ml-5 mr-5">{totalElements} devices</span>
                    <Dropdown
                      placeholder="Devices/page"
                      search
                      compact
                      selection
                      name="size"
                      size="mini"
                      value={size}
                      options={deviceService.DEVICES_PER_PAGE}
                      onChange={this.handlePaginationChange}
                    />
                    <span className="ml-5">/page</span>
                  </Table.HeaderCell>
                </Table.Row>
              )}
            </Table.Footer>
          </Table>
        </Segment>

        {/* Confimation Modal */}
        {isVisibleDeleteDeviceConfirmationModal && (
          <ConfirmationDialogComponent
            isOpen={isVisibleDeleteDeviceConfirmationModal}
            msg={Messages["delete-device"]}
            heading={"Delete device?"}
            onConfirm={this.handleConfirm}
            onCancel={this.onDeleteDeviceCancel}
            remoteError={remoteError}
            isLoading={isFetchingDevices}
          />
        )}

        {/* Add/Edit Device Modal */}
        {isVisibleAddDeviceModal && (
          <DeviceAdd
            isOpen={isVisibleAddDeviceModal}
            onDeviceAddModalClose={() =>
              this.setState({ isVisibleAddDeviceModal: false })
            }
            onDeviceAddOrUpdate={this.reFetchDevices}
            deviceData={selectedDevice || {}}
          />
        )}
      </>
    );
  }
}

export default DeviceList;
