import React, { Component } from 'react';
import { shape, func } from 'prop-types';

import { Form, Input, TextArea, Button, Select, Modal, Label, Message, Grid, Header } from 'semantic-ui-react';

import deviceService from '../../services/device';
import Messages from '../../config/messages';
import { convertResponseError } from '../../helpers/util';

class DeviceAdd extends Component {

  state = {
    // Device Details
    id: null,
    type: '',
    macAddress: '',
    comment: '',

    isLoading: false,
    validationErrors: {},
    isSubmit: false,

    remoteError: null,
  };

  componentDidMount() {
    const { deviceData: device } = this.props;

    if (device) this.setState({ ...device });

  }

  /**
   * Handle > Submit Form
   *
   * @param {Event} evt
   */
  handleSubmit = async evt => {
    if (evt) evt.preventDefault();

    const { id, type, macAddress, comment } = this.state;

    const fields = { type, macAddress, comment };

    this.setState({ validationErrors: {}, remoteError: null });

    const isValid = await deviceService.deviceSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }

    // Update existing device
    if (id) {
      fields.id = id;
      this.updateDevice(fields);
      return;
    }

    // Add new device
    this.addDevice(fields);

  };

  /**
   * Validate All Fields
   *
   * @param {Object} fields 
   */
  validateAll(fields) {
    for (const field of Object.keys(fields)) {
      this.validateOne(field, fields);
    }
  }

  /**
   * Validate Single Field  
   *
   * @param {*} field 
   * @param {*} fields 
   */
  validateOne(field, fields) {
    deviceService.deviceSchema.validateAt(field, fields)
      .catch(error => {
        this.setState(state => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0]
          }
        }));
      });
  }

  /**
   * Handle > On change input value
   *
   * @param {Event} evt 
   * @param {Object}
   */
  onChangeInput = (evt, { name, value }) => {
    if(name === "macAddress" ) value = String(value).toLocaleUpperCase();
    
    this.setState({ [name]: value });
  }

  /**
   * Handle > Add Device
   * 
   * @param {Object} device 
   */
  addDevice = device => {
    this.setState({ isLoading: true });

    deviceService.addDevice(device)
      .then(response => {
        if (!response) {
          this.setState({
            isLoading: false,
            remoteError: { message: Messages['no-responce-from-server'] }
          });
          return;
        }

        this.props.onDeviceAddOrUpdate('add');
      })
      .catch(error => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false
        });
      });
  }

  /**
   * Handle > Update Device
   * 
   * @param {Object} device 
   */
  updateDevice = device => {
    deviceService.updateDevice(device.id, device)
      .then(response => {
        if (!response) {
          this.setState({
            isLoading: false,
            remoteError: { message: Messages['no-responce-from-server'] }
          });
          return;
        }

        this.props.onDeviceAddOrUpdate('update');
      })
      .catch(error => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false
        });
      });
  }

  /**
   * Handle Error Message 
   *
   * @param {Object} remoteError 
   */
  handleErrorMessage = (remoteError) => {
    if (remoteError && remoteError.status !== 400) {
      return (
        <Message error>
          <p>{remoteError.message}</p>
        </Message>
      )
    }

    const { validationErrors } = this.state;

    if (remoteError.validationErrors) {
      remoteError.validationErrors.forEach(val => validationErrors[val.field] = val.defaultMessage)
    }
  }

  /**
   * Render View
   */
  render() {
    const { type, macAddress, comment, validationErrors, remoteError } = this.state;
    const { isOpen, deviceData, onDeviceAddModalClose } = this.props;

    return (
      <Modal
        onClose={onDeviceAddModalClose}
        open={isOpen}
        size='small'
      >
        <Modal.Header>{deviceData.id ? 'Device Comment' : 'Add Device'}</Modal.Header>

        <Modal.Content>
          {remoteError && this.handleErrorMessage(remoteError)}

          <Form className='device-wrapper'>
            <>
              {!deviceData.id && (
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column width={4}><Header as='h5' className='pt-10'>Device Type*</Header></Grid.Column>
                    <Grid.Column width={12}>
                      <Form.Field
                        error={Boolean(validationErrors.type)}
                      >
                        <Select
                          fluid
                          placeholder='Device Type'
                          options={deviceService.DEVICE_TYPES}
                          onChange={this.onChangeInput}
                          name='type'
                          value={type}
                        />
                        {Boolean(validationErrors.type) &&
                          <Label basic color='red' pointing>{validationErrors.type}</Label>
                        }
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {deviceData.id && (
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column width={4}><Header as='h5'>Name</Header></Grid.Column>
                    <Grid.Column width={12}>
                      {(deviceData.usages && deviceData.usages[0] && deviceData.usages[0].name) || '--'}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={4}><Header as='h5' className={!deviceData.id ? 'pt-10' : ''}>{`MAC Address${!deviceData.id ? '*' : ''}`}</Header></Grid.Column>
                  <Grid.Column width={12}>
                    <>
                      {!deviceData.id && (
                        <Form.Field
                          error={Boolean(validationErrors.macAddress)}
                        >
                          <Input
                            fluid
                            placeholder='MAC Address'
                            name='macAddress'
                            value={macAddress}
                            onChange={this.onChangeInput}
                            autoComplete='off'
                          />
                          {Boolean(validationErrors.macAddress) &&
                            <Label basic color='red' pointing>{validationErrors.macAddress}</Label>
                          }
                        </Form.Field>
                      )}

                      {deviceData.id && macAddress}
                    </>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={4}><Header as='h5' className='pt-10'>Comments</Header></Grid.Column>
                  <Grid.Column width={12}>
                    <TextArea
                      fluid='true'
                      placeholder='Comments'
                      name='comment'
                      value={comment}
                      onChange={this.onChangeInput}
                      autoFocus={Boolean(deviceData.id)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          </Form >
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={onDeviceAddModalClose}>Cancel</Button>
          <Button primary onClick={this.handleSubmit}>{deviceData.id ? 'Save' : 'Add'}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeviceAdd.propTypes = {
  deviceData: shape({}),
  fields: shape({}),
  onDeviceAddModalClose: func,
  onDeviceAddOrUpdate: func,
}

DeviceAdd.defaultProps = {
  deviceData: {},
  fields: {},
  onDeviceAddModalClose: () => { },
  onDeviceAddOrUpdate: () => { }
}

export default DeviceAdd;