import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { URL_PREFIX } from "../../services/URLS"

class Success extends Component {
  render() {
    return (
      <div className="flex-column justify-content-center alignItemCenter d-fx">
        <div className="mb-15 mt-15 text-center">Congratulation!</div>
        <Button className="mt-15" as={Link} to={URL_PREFIX}>
          GO TO DASHBOARD
        </Button>
      </div>
    );
  }
}

export default Success;
