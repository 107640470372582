/**
 * Import the Dependencies
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Grid, Button, Message, Segment } from "semantic-ui-react";
import customerService from "../../services/customer";
import Messages from "../../config/messages";
import { convertResponseError } from "../../helpers/util";
import { logout } from "../../helpers/auth";

class CustomerWelcomePage extends Component {
  state = {
    isLoading: false,
    isChecked: false,

    customerId: null,
    code: '',

    remoteError: null,
  };

  componentDidMount = () => {

    const {
      match: {
        params: { customerId },
      },
      location: { search }
    } = this.props;

    this.setState({ customerId, code: search });
    this.verifyEmail(customerId, search);
  };

  /**
   * Verify Email
   */
  verifyEmail = (customerId, code) => {

    this.setState({ remoteError: null, isLoading: true });

    customerService
      .verifyLink(customerId, code)
      .then((response) => {
        this.setState({ isLoading: false });

        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });

          return;
        }
        logout();
      })
      .catch((error) => {
        this.setState({
          remoteError: convertResponseError(error),
          isLoading: false,
        });
        logout();
      });
  };

  /**
   * Render View
   */
  render() {
    const { remoteError, isChecked, isLoading, customerId, code } = this.state;

    return (
      <>
        {remoteError ? (
          <Message error>
            <Message.Header>Error {remoteError.status}</Message.Header>
            {remoteError.status === 400 && remoteError.validationErrors ? (
              <ul className="list">
                {remoteError.validationErrors.map((item, key) => (
                  <li key={key} className="content">
                    {item.defaultMessage}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{remoteError.message}</p>
            )}
          </Message>
        ) : (
          <Segment loading={isLoading} className="fake-segment">
            <Grid
              textAlign="center"
              verticalAlign="middle"
              className="full-height-container"
            >
              <Grid.Column>
                <Grid celled textAlign="center">
                  <Grid>
                    <Grid.Row />
                    <Grid.Row>
                      <Grid.Column>
                        You are eligible to upgrade your account to use AxIT
                        Cloud
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          label="I have read and accepted the  Terms of Use and Privacy Policy."
                          checked={isChecked}
                          onChange={() => {
                            this.setState({ isChecked: !isChecked });
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          disabled={!isChecked}
                          as={Link}
                          to={`/customers/${customerId}/migrate${code}`}
                        >
                          Set Your Account
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row />
                  </Grid>
                </Grid>
              </Grid.Column>
            </Grid>
          </Segment>
        )}
      </>
    );
  }
}

/**
 * Export the Component
 */
export default CustomerWelcomePage;
