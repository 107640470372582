import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Pagination,
  Dropdown,
  Segment,
  Message,
  Icon,
  Popup,
} from "semantic-ui-react";

import customerService from "../../services/customer";
import URLS, { URL_PREFIX } from "../../services/URLS";

import {
  convertBinaryToBase64,
  convertResponseError,
} from "../../helpers/util";

import Messages from "../../config/messages";

import ResendInvitationEmail from "./ResendInvitationEmailModal";

export default class CustomerList extends Component {
  itemPerPageOptions = [10, 20, 30, 50, 100].map((value) => ({
    key: value,
    text: value,
    value: value,
  }));

  state = {
    customers: [],
    activePage: 1,
    totalPages: 1,
    totalItems: 0,
    pageSize: null,
    loading: true,
    sortBy: null,
    sortDirection: null,
    loaded: false,

    isOpenInvaitationModal: false,
    selectedCustomer: {},
  };

  componentDidMount() {
    const { activePage, pageSize } = this.state;

    this.reloadTable(activePage, pageSize);
  }

  /**
   * Handle Pagination Change
   *
   * @param {Event} e
   * @param {String} param
   */
  handlePaginationChange = (e, { activePage }) => {
    const { pageSize, sortBy, sortDirection } = this.state;

    this.reloadTable(activePage, pageSize, sortBy, sortDirection);
  };

  /**
   * Handle Page Size Change
   *
   * @param {Event} e
   * @param {String} param1
   */
  handlePageSizeChange = (e, { value }) => {
    const { sortBy, sortDirection } = this.state;

    this.reloadTable(1, value, sortBy, sortDirection);
  };

  /**
   * Handle Sort
   *
   * @param {String} clickedColumn
   * @returns
   */
  handleSort = (clickedColumn) => () => {
    const { pageSize, sortBy, sortDirection } = this.state;

    if (sortBy !== clickedColumn) {
      this.reloadTable(1, pageSize, clickedColumn, "ascending");

      return;
    }

    this.reloadTable(
      1,
      pageSize,
      clickedColumn,
      sortDirection === "ascending" ? "descending" : "ascending"
    );
  };

  /**
   * Reload Table
   *
   * @param {*} page
   * @param {*} size
   * @param {*} sortBy
   * @param {*} sortDirection
   */
  reloadTable = (page, size, sortBy, sortDirection) => {
    this.setState({
      loading: true,
      loaded: false,
      error: null,
    });

    customerService
      .getAllCustomers(page, size, sortBy, sortDirection)
      .then((response) => {
        if (!response) {
          this.setState({
            remoteError: { message: Messages["no-responce-from-server"] },
          });
          return;
        }

        const {
          content,
          size,
          totalPages,
          totalElements,
          number,
        } = response.data;

        this.setState({
          customers: content,
          pageSize: size,
          totalPages: totalPages,
          totalItems: totalElements,
          activePage: number + 1,
          loading: false,
          sortBy,
          sortDirection,
          loaded: true,
          error: null,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          loaded: false,
          error: convertResponseError(error),
        });
      });
  };

  /**
   * Get Customer Logo
   *
   * @param {Object} customer
   */
  getCustomerLogo = (customer) => async () => {
    const { customers } = this.state;

    await customerService
      .getCustomerLogo(customer.id)
      .then((response) => {
        customer.imgUrl = convertBinaryToBase64(response.data);
        this.setState({ customers });
      })
      .catch(() => {
        customer.imgUrl = "images/defaultLogo.png";
        this.setState({ customers });
      });
  };

  /**
   * Update customer
   * @param {Object} customer 
   */
  updateCustomer = (customer) => {
    const { customers } = this.state;
    let index = customers.findIndex(({ id }) => id === customer.id);
    customers[index] = customer;
    this.setState({ customers });
  }

  /**
   * Open Invitation Modal
   *
   * @param {Object} selectedCustomer
   */
  openInviationModal = (selectedCustomer) => () => {
    this.setState({ isOpenInvaitationModal: true, selectedCustomer });
  };

  /**
   * Close Invitation Modal
   */
  closeInvitationModal = () => {
    this.setState({ isOpenInvaitationModal: false, selectedCustomer: {} });
  };

  /**
   * handle Status Content
   *
   * @param {Object} customer
   *
   * @return {String || HTML}
   */
  handleStatusContent = (customer) => {
    const { initStatus = "" } = customer;

    const {
      INIT_STATUS: {
        resend,
        complete,
        customer_added,
        user_added,
        customer_set,
        business_profile_set,
        location_set,
        user_set,
        contact_detail_set,
      },
    } = customerService;

    // Handle Link Status
    const handleButtonStatus = (status) => {
      if (!status || status === resend.key) {
        return (
          <Button
            className="resend-btn"
            size="mini"
            onClick={this.openInviationModal(customer)}
          >
            {customer.sendingText || resend.key}
          </Button>
        );
      }
      return (
        <Button
          className="resend-btn"
          size="mini"
          disabled
        >
          {status}
        </Button>
      );
    };

    const getLinkTag = () => (
      <Icon name="check circle" color="teal" size="large" />
    );

    switch (initStatus) {
      case complete.key:
        return complete.value;

      case customer_added.key:
      case user_added.key:
        return (
          <>Invitation Email Sent {handleButtonStatus(customer.sendingText)}</>
        );

      case customer_set.key:
        return <>Customer {getLinkTag()}</>;

      case contact_detail_set.key:
        return <>Contact Details {getLinkTag()}</>;

      case user_set.key:
        return <>User {getLinkTag()}</>;

      case business_profile_set.key:
        return <>Business Profile {getLinkTag()}</>;

      case location_set.key:
        return <>Location {getLinkTag()}</>;

      default:
        return "-";
    }
  };

  /**
   * handle Popup Content
   *
   * @param {Object} contactDetail
   *
   * @return {HTML}
   */
  handlePopupContent = (contactDetail) => {
    if (!contactDetail || Object.keys(contactDetail).length === 0) return "-";

    const { firstName = "-", lastName = "", phone, email } =
      contactDetail || {};

    /**
     * Get Content for Popup
     */
    const getContent = () => {
      return (
        <>
          {firstName} {lastName} <br />
          <Icon name="mail" /> {email} <br />
          <Icon name="tablet" /> {phone} <br />
        </>
      );
    };

    return (
      <Popup
        content={getContent(contactDetail)}
        trigger={
          <div>
            {firstName} {lastName}
          </div>
        }
      />
    );
  };


  /**
   * Render View
   */
  render() {
    const {
      sortBy,
      sortDirection,
      error,
      isOpenInvaitationModal,
      customers,
      selectedCustomer,
      loading,
      loaded,
    } = this.state;
    const { user: userData } = this.props;

    return (
      <>
        {error && (
          <Message error>
            <Message.Header>Error {error.status}</Message.Header>
            <p>{error.message}</p>
          </Message>
        )}

        <Button as={Link} to={`${URL_PREFIX}${URLS.CUSTOMERS}${URL_PREFIX}add`} primary>
          Add Customer
        </Button>

        <Segment
          loading={loading}
          disabled={!loaded}
          className="fake-segment"
        >
          <Table sortable singleLine size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sortBy === "name" ? sortDirection : null}
                  onClick={this.handleSort("name")}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortBy === "email" ? sortDirection : null}
                  onClick={this.handleSort("email")}
                >
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell>Contact Person</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortBy === "licences" ? sortDirection : null}
                  onClick={this.handleSort("licences")}
                >
                  Licences
                </Table.HeaderCell>
                <Table.HeaderCell>Operations</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {customers && customers.map((customer) => {
                const {
                  id,
                  businessProfile,
                  user,
                  licenceType,
                  licences,
                  imgUrl = "",
                  invitationLink
                } = customer;

                const { email: invitationEmail = "-" } = invitationLink || {}
                const { name = "-" } = businessProfile || {};
                const { email, contactDetail = {} } = user || {};
                return (
                  <Table.Row key={id}>
                    <Table.Cell width={4}>
                      <img
                        src={imgUrl}
                        alt=""
                        onError={this.getCustomerLogo(customer)}
                        className="ui mini middle aligned image mr-5"
                      />
                      <Link target="_blank" to={`/customers/${id}`}>
                        {name}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{email || invitationEmail}</Table.Cell>
                    <Table.Cell>
                      {this.handlePopupContent(contactDetail)}
                    </Table.Cell>
                    <Table.Cell>
                      {this.handleStatusContent(customer)}
                    </Table.Cell>
                    <Table.Cell>
                      {licenceType}({licences})
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        as={Link}
                        to={`/customers/${id}/edit`}
                        circular
                        size="mini"
                        icon="pencil alternate"
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="6" textAlign="right">
                  <Pagination
                    activePage={this.state.activePage}
                    boundaryRange={1}
                    onPageChange={this.handlePaginationChange}
                    size="mini"
                    siblingRange={1}
                    totalPages={this.state.totalPages}
                    firstItem={null}
                    lastItem={null}
                  />
                  <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                    {this.state.totalItems} customers
                  </span>
                  <Dropdown
                    placeholder="Customers/page"
                    search
                    compact
                    selection
                    size="mini"
                    value={this.state.pageSize}
                    options={this.itemPerPageOptions}
                    onChange={this.handlePageSizeChange}
                  />
                  /page
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
        {isOpenInvaitationModal && (
          <ResendInvitationEmail
            isOpen={isOpenInvaitationModal}
            onCancel={this.closeInvitationModal}
            isLoading={loaded}
            onInvitationSend={this.onInvitationSend}
            customer={selectedCustomer}
            user={userData}
            updateCustomer={this.updateCustomer}
          />
        )}
      </>
    );
  }
}
