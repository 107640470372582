import React, { Component } from "react";
import ReactPhoneInput from "react-phone-input-2";

import {
  Button,
  Header,
  Form,
  Input,
  Segment,
  Label,
  Message,
  Dropdown,
  TextArea,
  Icon,
} from "semantic-ui-react";

import customerService from "../../services/customer";
import Preview from "./Preview";

import "react-phone-input-2/dist/style.css";
import ResendInvitationEmail from "./ResendInvitationEmailModal";

/**
 * Licence types options
 */
const LICENCE_TYPES = [
  {
    key: "BASIC",
    text: "Basic",
    value: "BASIC",
  },
  {
    key: "CLOUD",
    text: "Cloud",
    value: "CLOUD",
  },
];

export default class CustomerEditor extends Component {
  state = {
    loading: false,
    disabled: false,

    customer: {
      licences: "",
      licenceType: "",

      businessProfile: {
        name: "",
        phone: "",
        websiteUrl: "",
        email: "",
        address: "",
      },

      user: {
        email: "",
        contactDetail: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
        authPhone: "",
        twoFactorAuthEnabled: false,
      },
    },

    validationErrors: {},
    remoteError: null,
    remoteSuccess: null,
    isOpenInvaitationModal: false,
  };

  componentDidUpdate(prevProps) {
    const {
      loading,
      disabled,
      customer,
      remoteError,
      remoteSuccess,
    } = this.props;

    if (loading !== prevProps.loading) this.setState({ loading });

    if (disabled !== prevProps.disabled) this.setState({ disabled });

    if (
      customer &&
      JSON.stringify(customer) !== JSON.stringify(prevProps.customer)
    )
      this.setState({ customer });

    if (remoteError !== prevProps.remoteError) this.setState({ remoteError });

    if (remoteSuccess !== prevProps.remoteSuccess)
      this.setState({ remoteSuccess });
  }


  /**
   * Handle Submit
   *
   * @param {Event} evt
   */
  handleSubmit = async (evt) => {
    evt.preventDefault();
    const { customer } = this.state;

    const { user, licenceType, licences, businessProfile } = customer;

    this.setState({
      validationErrors: {},
      remoteError: null,
    });

    const { contactDetail, email: userEmail = "", authPhone } = user || {};
    const { firstName = "", lastName = "", email = "", phone = "" } =
      contactDetail || {};
    const { name = "" } = businessProfile || {};

    const fields = {
      email: userEmail,
      businessProfile: { name },
      licenceType,
      licences,
      user: {
        contactDetail: {
          firstName,
          email,
          lastName,
          phone,
        },
      },
    };
    if (customer.user) {
      customer.user.twoFactorAuthEnabled = Boolean(authPhone);
    }

    const isValid = await customerService.updateCustomerSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }

    this.props.onSubmit(customer);
  };

  /**
   * Validate All
   *
   * @param {Object} fields
   * @param {Sting} parentPath
   * @param {Object} root
   */
  validateAll(fields, parentPath, root) {
    root = root ? root : fields;
    parentPath = parentPath ? parentPath : "";

    for (const field of Object.keys(fields)) {
      if (typeof fields[field] === "object" && fields[field] != null) {
        this.validateAll(fields[field], parentPath + field + ".", root);
      } else {
        this.validateOne(parentPath + field, root);
      }
    }
  }

  /**
   * Handle Licence Submit
   *
   * @param {Event} evt
   */
  handleLicenceSubmit = async (evt) => {
    evt.preventDefault();
    const { customer } = this.state;

    const { licenceType, licences, id } = customer;

    const updateCustomer = {
      id,
      licenceType,
      licences,
    };

    this.setState({
      validationErrors: {},
      remoteError: null,
    });

    const fields = {
      licenceType,
      licences,
    };

    const isValid = await customerService.licenceSchema.isValid(fields);

    if (!isValid) {
      this.validateAll(fields);
      return;
    }

    this.props.onSubmit(updateCustomer);
  };

  /**
   * Validate One
   *
   * @param {Object} field
   * @param {Object} fields
   */
  validateOne(field, fields) {
    customerService.updateCustomerSchema
      .validateAt(field, fields)
      .catch((error) => {
        this.setState((state) => ({
          validationErrors: {
            ...state.validationErrors,
            [field]: error.errors[0],
          },
        }));
      });
  }

  /**
   * Handle Status Content
   *
   * @param {Object} customer
   * @returns
   */
  handleStatusContent = (customer) => {
    const { initStatus = "" } = customer;

    const {
      INIT_STATUS: {
        resend,
        complete,
        customer_added,
        user_added,
        customer_set,
        business_profile_set,
        user_set,
        contact_detail_set,
      },
    } = customerService;

    /**
     * 
     * @param {String} status 
     * @returns 
     */
    const handleButtonStatus = (status) => {
      const { customer } = this.state;
      const {
        invitationLink: { email }
      } = customer;
      if (!status || status === resend.key) {

        return (
          <Button className="resend-btn" size='mini' onClick={this.openInviationModal(customer)} >
            {customer.sendingText || resend.key}
          </Button>
        );
      }

      return (<>{`To ${email}`}</>)
    };

    const getLinkTag = () => (
      <Icon name="check circle" color="teal" size="large" />
    );

    switch (initStatus) {
      case complete.key:
        return complete.value;

      case customer_added.key:
      case user_added.key:
        return (
          <>Invitation Email Sent {handleButtonStatus(customer.sendingText)}</>
        );

      case customer_set.key:
        return <>Customer {getLinkTag()}</>;

      case contact_detail_set.key:
        return <>Contact Details {getLinkTag()}</>;

      case user_set.key:
        return <>User {getLinkTag()}</>;

      case business_profile_set.key:
        return <>Business Profile {getLinkTag()}</>;

      default:
        return "";
    }
  };

  /**
   * Set Customer State Data
   *
   * @param {Object || String} field
   */
  setCustomerStateData = (field, objName) => (event, { name, value }) => {
    const { customer } = this.state;

    let cData = { ...customer };

    /**
     * Handle Customer Data
     */
    const handleCustomerData = () => {
      if (objName === "contactDetail") {
        cData.user = { ...cData.user };
        cData.user.contactDetail = { ...cData.user.contactDetail };
        cData.user.contactDetail[name] = value;

        return cData;
      }

      if (typeof field === "object") {
        cData[objName] = { ...cData[objName] };
        cData[objName][name] = value;

        return cData;
      }

      if (typeof field === "string") {
        cData = {
          ...cData,
          [field]: value,
        };

        return cData;
      }
    };

    this.setState({ customer: handleCustomerData() });
  };

  /**
   * Update customer
   *
   * @param {Object} customer 
   */
  updateCustomer = (customer) => {
    this.setState({ customer });
  }

  /**
   * Open Invitation Modal
   *
   * @param {Event} event
   */
  openInviationModal = () => (event) => {
    event.preventDefault();
    this.setState({ isOpenInvaitationModal: true });
  };

  /**
   * Close Invitation Modal
   */
  closeInvitationModal = () => {
    this.setState({ isOpenInvaitationModal: false });
  };

  /**
   * Handle Auth Phone
   *
   * @param {Sting} value
   * @param {Object} data
   */
  handleAuthPhone = (value, data) => {
    const { customer } = this.state;

    const rawPhone = value.replace(/[^0-9]+/g, "").slice(data.dialCode.length);
    const authPhone = rawPhone ? value.trim() : "";

    customer["user"] = {
      ...customer.user,
      authPhone: String(authPhone).replace(/ /g, ""),
    };

    this.setState({ customer });
  };

  /**
   * Licence Info
   */
  licenceInfo = () => {
    const {
      customer,
      disabled,
      validationErrors,
      remoteError,
      remoteSuccess,
      loading,
      isOpenInvaitationModal
    } = this.state;
    const { user } = this.props
    const { licences, licenceType } = customer;

    return (
      <>
        <Form
          loading={loading}
          error={validationErrors || remoteError}
          onSubmit={this.handleLicenceSubmit}
        >
          <Segment>
            <Header as="h3">Licence Info</Header>
            <Form.Group widths="equal">
              <Form.Field error={Boolean(validationErrors.licenceType)}>
                <label>Licence Type *</label>
                <Dropdown
                  placeholder="Licence Type"
                  fluid
                  selection
                  name="licenceType"
                  onChange={this.setCustomerStateData("licenceType")}
                  options={LICENCE_TYPES}
                  value={licenceType}
                />
                {Boolean(validationErrors.licenceType) && (
                  <Label basic color="red" pointing>
                    {validationErrors.licenceType}
                  </Label>
                )}
              </Form.Field>
              <Form.Field error={Boolean(validationErrors.licences)}>
                <label>Number of Licences *</label>
                <Input
                  type="number"
                  min={0}
                  name="licences"
                  placeholder="Number of Licences"
                  value={licences}
                  onChange={this.setCustomerStateData("licences")}
                  disabled={disabled}
                />
                {Boolean(validationErrors.licences) && (
                  <Label basic color="red" pointing>
                    {validationErrors.licences}
                  </Label>
                )}
              </Form.Field>
            </Form.Group>
            {this.handleStatusContent(customer)}
          </Segment>

          {remoteSuccess && (
            <Message positive>
              <Message.Header>Success</Message.Header>
              <p>Information updated successfully!</p>
            </Message>
          )}

          <Button primary type="submit" disabled={disabled}>
            Submit
        </Button>
        </Form>
        {isOpenInvaitationModal && (
          <ResendInvitationEmail
            isOpen={isOpenInvaitationModal}
            onCancel={this.closeInvitationModal}
            onInvitationSend={this.onInvitationSend}
            customer={customer}
            user={user}
            updateCustomer={this.updateCustomer}
          />
        )}
      </>
    );
  };

  /**
   * Render View
   */
  render() {
    const {
      customer,
      loading,
      disabled,
      validationErrors,
      remoteError,
      remoteSuccess,
    } = this.state;

    const {
      businessProfile,
      user = {},
      id,
      licenceType,
      licences,
      initStatus,
    } = customer || {};

    const {
      name = "",
      email: businessEmail = "",
      websiteUrl = "",
      phone: businessPhone = "",
      address = "",
    } = businessProfile || {};

    const { email: userEmail = "", authPhone, contactDetail } = user || {};

    const {
      firstName = "",
      lastName = "",
      email: contactEmail = "",
      phone: contactPhone = "",
    } = contactDetail || {};

    return (
      <div>
        {!initStatus || initStatus === "CUSTOMER_ADDED" ? (
          <>{this.licenceInfo()}</>
        ) : (
          <Form
            loading={loading}
            error={validationErrors || remoteError}
            onSubmit={this.handleSubmit}
          >
            <Segment.Group>
              <Segment disabled={disabled}>
                <Header as="h3">Login Details</Header>
                <Form.Field error={Boolean(validationErrors["email"])}>
                  <label>Email Address *</label>
                  <Input
                    placeholder="Email Address"
                    value={userEmail}
                    type="email"
                    name="email"
                    onChange={this.setCustomerStateData(user, "user")}
                    disabled={disabled}
                  />
                  {Boolean(validationErrors["email"]) && (
                    <Label basic color="red" pointing>
                      {validationErrors["email"]}
                    </Label>
                  )}
                </Form.Field>

                <Form.Field>
                  <label>Phone for Authentication</label>
                  <ReactPhoneInput
                    defaultCountry="au"
                    inputStyle={{ paddingLeft: "50px", height: "38px" }}
                    buttonStyle={{ height: "38px" }}
                    value={authPhone || ""}
                    name="authPhone"
                    onChange={this.handleAuthPhone}
                    disabled={disabled}
                  />
                </Form.Field>
              </Segment>

              <Segment disabled={disabled}>
                <Header as="h3">Business Info</Header>

                <Form.Field
                  error={Boolean(validationErrors["businessProfile.name"])}
                >
                  <label>Business Name *</label>
                  <Input
                    placeholder="Customer Name"
                    value={name || ""}
                    name="name"
                    onChange={this.setCustomerStateData(
                      businessProfile,
                      "businessProfile"
                    )}
                    disabled={disabled}
                  />
                  {Boolean(validationErrors["businessProfile.name"]) && (
                    <Label basic color="red" pointing>
                      {validationErrors["businessProfile.name"]}
                    </Label>
                  )}
                </Form.Field>

                {id && <Preview id={customer.id} />}

                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Email</label>
                    <Input
                      placeholder="Email"
                      value={businessEmail}
                      type="email"
                      name="email"
                      onChange={this.setCustomerStateData(
                        businessProfile,
                        "businessProfile"
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Phone</label>
                    <Input
                      placeholder="Phone Number"
                      value={businessPhone}
                      name="phone"
                      type="text"
                      onChange={this.setCustomerStateData(
                        businessProfile,
                        "businessProfile"
                      )}
                      disabled={disabled}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Field>
                  <label>Address</label>
                  <TextArea
                    placeholder="Address"
                    value={address}
                    type="text"
                    name="address"
                    onChange={this.setCustomerStateData(
                      businessProfile,
                      "businessProfile"
                    )}
                    disabled={disabled}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Website Url</label>
                  <Input
                    placeholder="Website Url"
                    value={websiteUrl}
                    type="text"
                    name="websiteUrl"
                    onChange={this.setCustomerStateData(
                      businessProfile,
                      "businessProfile"
                    )}
                    disabled={disabled}
                  />
                </Form.Field>
              </Segment>

              <Segment>
                <Header as="h3">Contact Person</Header>
                <Form.Group widths="equal">
                  <Form.Field
                    error={Boolean(
                      validationErrors["user.contactDetail.firstName"]
                    )}
                  >
                    <label>First Name *</label>
                    <Input
                      placeholder="First Name"
                      value={firstName}
                      name="firstName"
                      onChange={this.setCustomerStateData(
                        contactDetail,
                        "contactDetail"
                      )}
                      disabled={disabled}
                    />
                    {Boolean(
                      validationErrors["user.contactDetail.firstName"]
                    ) && (
                        <Label basic color="red" pointing>
                          {validationErrors["user.contactDetail.firstName"]}
                        </Label>
                      )}
                  </Form.Field>
                  <Form.Field
                    error={Boolean(
                      validationErrors["user.contactDetail.lastName"]
                    )}
                  >
                    <label>Last Name *</label>
                    <Input
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={this.setCustomerStateData(
                        contactDetail,
                        "contactDetail"
                      )}
                      disabled={disabled}
                    />
                    {Boolean(
                      validationErrors["user.contactDetail.lastName"]
                    ) && (
                        <Label basic color="red" pointing>
                          {validationErrors["user.contactDetail.lastName"]}
                        </Label>
                      )}
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field
                    error={Boolean(
                      validationErrors["user.contactDetail.phone"]
                    )}
                  >
                    <label>Phone *</label>
                    <Input
                      placeholder="Phone Number"
                      fluid
                      name="phone"
                      value={contactPhone}
                      onChange={this.setCustomerStateData(
                        contactDetail,
                        "contactDetail"
                      )}
                      disabled={disabled}
                    />
                    {Boolean(validationErrors["user.contactDetail.phone"]) && (
                      <Label basic color="red" pointing>
                        {validationErrors["user.contactDetail.phone"]}
                      </Label>
                    )}
                  </Form.Field>
                  <Form.Field
                    error={Boolean(
                      validationErrors["user.contactDetail.email"]
                    )}
                  >
                    <label>Email *</label>
                    <Input
                      placeholder="Email"
                      name="email"
                      value={contactEmail}
                      onChange={this.setCustomerStateData(
                        contactDetail,
                        "contactDetail"
                      )}
                      disabled={disabled}
                    />
                    {Boolean(validationErrors["user.contactDetail.email"]) && (
                      <Label basic color="red" pointing>
                        {validationErrors["user.contactDetail.email"]}
                      </Label>
                    )}
                  </Form.Field>
                </Form.Group>
              </Segment>

              <Segment>
                <Header as="h3">Licence Info</Header>
                <Form.Group widths="equal">
                  <Form.Field error={Boolean(validationErrors.licenceType)}>
                    <label>Licence Type *</label>
                    <Dropdown
                      placeholder="Licence Type"
                      fluid
                      selection
                      name="licenceType"
                      onChange={this.setCustomerStateData("licenceType")}
                      options={LICENCE_TYPES}
                      value={licenceType || ""}
                    />
                    {Boolean(validationErrors.licenceType) && (
                      <Label basic color="red" pointing>
                        {validationErrors.licenceType}
                      </Label>
                    )}
                  </Form.Field>
                  <Form.Field error={Boolean(validationErrors.licences)}>
                    <label>Number of Licences *</label>
                    <Input
                      type="number"
                      min={0}
                      placeholder="Number of Licences"
                      value={licences}
                      onChange={this.setCustomerStateData("licences")}
                      disabled={disabled}
                    />
                    {Boolean(validationErrors.licences) && (
                      <Label basic color="red" pointing>
                        {validationErrors.licences}
                      </Label>
                    )}
                  </Form.Field>
                </Form.Group>
              </Segment>

              <Segment>
                {remoteError && (
                  <Message error>
                    <Message.Header>Error {remoteError.status}</Message.Header>
                    {remoteError.status === 400 &&
                      remoteError.validationErrors ? (
                      <ul className="list">
                        {remoteError.validationErrors.map((item, key) => (
                          <li key={key} className="content">
                            {item.defaultMessage}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>{remoteError.message}</p>
                    )}
                  </Message>
                )}

                {remoteSuccess && (
                  <Message positive>
                    <Message.Header>Success</Message.Header>
                    <p>Information updated successfully!</p>
                  </Message>
                )}

                <Button primary type="submit" disabled={disabled}>
                  Submit
                </Button>
              </Segment>
            </Segment.Group>
          </Form>
        )}
      </div>
    );
  }
}
